<template>
  <div>
    <div class="px-4">
      <div class="body p-4">
        <span class="text-primary h2 d-flex align-items-center">
          <span class="text-gray"> Editar empresa</span>
        </span>
        <div class="d-flex justify-content-center mt-4" v-if="loaderActive">
          <div class="spinner-border text-primary spinner-border" role="status">
            <span class="visually-hidden">Aguarde...</span>
          </div>
        </div>
        <!-- Step 1 -->
        <div class="row">
          <div class="col-lg-8 col-md-8 col-sm-12 text-start">
            <div class="card">
              <h4 class="text-gray font-weight-light">
                <span
                  v-if="
                    !company.select_area ||
                    (company.select_area === 7 && !company.area)
                  "
                  v-tooltip="'Preenchimento obrigatório'"
                >
                  <i
                    class="fa-solid fa-triangle-exclamation text-warning warning me-2"
                  ></i></span
                >Área
              </h4>
              <div class="row my-4">
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <base-select
                    :options="areas"
                    title="área*"
                    :show_title="true"
                    @update-selected="company.select_area = $event"
                    :selected_id="company.select_area"
                  />
                </div>
                <div
                  class="col-lg-6 col-sm-12 text-primary mb-4"
                  v-show="company.select_area === 7"
                >
                  <label for="input-area" class="form-label mb-0">Outro*</label>
                  <input
                    type="text"
                    id="input-area"
                    class="form-control"
                    aria-describedby="input-area"
                    placeholder="Informe aqui a área da sua empresa"
                    v-model="company.area"
                  />
                </div>
              </div>
            </div>
            <!-- Step 2 -->
            <div class="card">
              <h4 class="text-gray font-weight-light">
                Um pouco da sua empresa
              </h4>
              <div class="row my-4">
                <div class="col-12 text-primary mb-4">
                  <label for="activity_branch-textarea" class="form-label bold"
                    ><span
                      v-if="!company.description"
                      v-tooltip="'Preenchimento obrigatório'"
                    >
                      <i
                        class="fa-solid fa-triangle-exclamation text-warning warning me-2"
                      ></i> </span
                    >O que sua empresa faz/fará</label
                  >
                  <div class="form-floating">
                    <textarea
                      class="form-control"
                      placeholder="Conte um pouco o que a sua empresa irá fazer/faz"
                      id="activity_branch-textarea"
                      style="height: 100px"
                      v-model="company.description"
                    ></textarea>
                    <label for="activity_branch-textarea"
                      >Conte um pouco o que a sua empresa irá fazer/faz</label
                    >
                  </div>
                </div>
                <div class="col-12 text-primary mb-4">
                  <label class="form-label bold">Tipo de atividade</label>
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                      <label class="form-label"
                        >Comércio: Você produz o que vende?</label
                      >
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="comercio-switch"
                          v-model="company.commerce"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                      <label class="form-label"
                        >Serviço: Você produz o que vende?</label
                      >
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="comercio-switch"
                          v-model="company.service"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                      <label class="form-label">Indústria</label>
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="comercio-switch"
                          v-model="company.industry"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Step 3 -->
            <div class="card">
              <h4 class="text-gray text-disabled font-weight-light mb-3">
                <span
                  v-if="!company.legal_nature"
                  v-tooltip="'Preenchimento obrigatório'"
                >
                  <i
                    class="fa-solid fa-triangle-exclamation text-warning warning me-2"
                  ></i>
                </span>
                Um pouco da sua empresa, para descobrirmos a Natureza Jurídica
              </h4>
              <div v-if="company.select_area === 1">
                <div class="row text-center text-primary">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div
                      class="card p-4 card-select"
                      :class="
                        parseInt(company.legal_nature) === 1 ? ' selected' : ''
                      "
                      @click="handleSelectLegalNature(1)"
                      v-tooltip="
                        'Modelo destinado a/ao advogado(a) que deseja constituir CNPJ de forma individual'
                      "
                    >
                      <h3>Sociedade Unipessoal de Advocacia</h3>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div
                      class="card p-4 card-select"
                      :class="
                        parseInt(company.legal_nature) === 2 ? ' selected' : ''
                      "
                      @click="handleSelectLegalNature(2)"
                      v-tooltip="
                        'Modelo destinado as/aos advogados(as) que desejam constituir CNPJ com mais de um advogado'
                      "
                    >
                      <h3>Sociedade Empresária Limitada (Pluripessoal)</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="row text-center text-primary">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div
                      class="card p-4 card-select"
                      :class="
                        parseInt(company.legal_nature) === 3 ? ' selected' : ''
                      "
                      @click="handleSelectLegalNature(3)"
                      v-tooltip="
                        'Modelo destinado ao empresário que deseja constituir CNPJ de forma individual. Nesse formato, os bens do sócio se misturam com o patrimônio da empresa.'
                      "
                    >
                      <h3>Empresa Individual</h3>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div
                      class="card p-4 card-select"
                      :class="
                        parseInt(company.legal_nature) === 5 ? ' selected' : ''
                      "
                      @click="handleSelectLegalNature(5)"
                      v-tooltip="
                        'Modelo destinado ao empresário que deseja consituir CNPJ com mais de um sócio. A responsabilidade do sócio perante a empresa é limitada à sua parte do capital social.'
                      "
                    >
                      <h3>Sociedade Empresária Limitada Unipessoal</h3>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div
                      class="card p-4 card-select"
                      :class="
                        parseInt(company.legal_nature) === 4 ? ' selected' : ''
                      "
                      @click="handleSelectLegalNature(4)"
                      v-tooltip="
                        'Modelo destinado ao empresário que deseja contituir CNPJ de forma individual. A responsabilidade do sócio perante a empresa é limitada à sua parte do capital social.'
                      "
                    >
                      <h3>Sociedade Simples Pura (Pluripessoal)</h3>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <h4 class="text-gray text-disabled font-weight-light">
                Dados da empresa
              </h4>
              <div class="row mt-4">
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-razao-social" class="form-label">
                    <span
                      v-if="!company.name"
                      v-tooltip="'Preenchimento obrigatório'"
                    >
                      <i
                        class="fa-solid fa-triangle-exclamation text-warning warning me-2"
                      ></i> </span
                    >Razão social
                    <span
                      v-tooltip="
                        'Razão social é o nome ou termo registrado sob o qual uma pessoa jurídica se individualiza e oficialmente exerce suas atividades'
                      "
                      ><i class="fas fa-info-circle"></i>
                    </span>
                  </label>
                  <input
                    type="text"
                    id="input-razao-social"
                    class="form-control"
                    aria-describedby="input-razao-social"
                    placeholder="Informe aqui a razão social"
                    v-model="company.name"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-nome-fantasia" class="form-label">
                    <span
                      v-if="!company.secondary_name"
                      v-tooltip="'Preenchimento obrigatório'"
                    >
                      <i
                        class="fa-solid fa-triangle-exclamation text-warning warning me-2"
                      ></i> </span
                    >Nome fantasia
                    <span
                      v-tooltip="
                        'É opcional e caracteriza-se pelo nome comercial (marca) ou nome de fachada, pode ser usado em vários lugares, como panfletos, cartazes, sites, entre outros, e não há regras ou características claras a serem seguidas, desde que seja inédito, ou seja, nenhuma outra empresa ter o mesmo nome fantasia.'
                      "
                      ><i class="fas fa-info-circle"></i> </span
                  ></label>
                  <input
                    type="text"
                    id="input-nome-fantasia"
                    class="form-control"
                    aria-describedby="input-nome-fantasia"
                    placeholder="Informe aqui o nome fantasia"
                    v-model="company.secondary_name"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-cnaes-social" class="form-label">
                    <span
                      v-if="!company.cnae"
                      v-tooltip="'Preenchimento obrigatório'"
                    >
                      <i
                        class="fa-solid fa-triangle-exclamation text-warning warning me-2"
                      ></i> </span
                    >Códigos CNAE
                    <span
                      v-tooltip="
                        'É a Classificação Nacional das Atividades Econômicas. É o instrumento de padronização nacional por meio dos códigos de atividade econômica e dos critérios de enquadramento utilizados pelos diversos órgãos da Administração Tributária do país.'
                      "
                      ><i class="fas fa-info-circle"></i> </span
                  ></label>
                  <input
                    type="text"
                    id="input-cnaes"
                    class="form-control"
                    aria-describedby="input-cnaes"
                    placeholder="Selecionar opções"
                    v-model="company.cnae"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-capital-social" class="form-label">
                    <span
                      v-if="!company.social_capital"
                      v-tooltip="'Preenchimento obrigatório'"
                    >
                      <i
                        class="fa-solid fa-triangle-exclamation text-warning warning me-2"
                      ></i> </span
                    >Capital social
                    <span
                      v-tooltip="
                        'Pode ser definido como o valor inicial que os sócios empregam para a empresa no momento de abertura. Este valor representa a quantia bruta necessária para iniciar as atividades da empresa, já considerando o tempo em que ela pode ainda não gerar o lucro suficiente para se manter por conta própria.'
                      "
                      ><i class="fas fa-info-circle"></i> </span
                  ></label>
                  <input
                    type="text"
                    id="input-capital-social"
                    class="form-control"
                    aria-describedby="input-capital-social"
                    placeholder="Informe aqui o capital social"
                    v-model="company.social_capital"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label
                    for="input-capital-social-integralizado"
                    class="form-label"
                  >
                    <span
                      v-if="!company.social_capital_integrate"
                      v-tooltip="'Preenchimento obrigatório'"
                    >
                      <i
                        class="fa-solid fa-triangle-exclamation text-warning warning me-2"
                      ></i> </span
                    >Capital social integralizado
                    <span
                      v-tooltip="
                        'Compreende o valor já disponibilizado pelos sócios no ato de constituição. Pode ser 100% (mais indicado) ou não do capital social. Caso NÃO seja 100% do valor, deverá ser informado na constituição o prazo e condições de integralização do restante.'
                      "
                      ><i class="fas fa-info-circle"></i> </span
                  ></label>
                  <input
                    type="text"
                    id="input-capital-social-integralizado"
                    class="form-control"
                    aria-describedby="input-capital-social-integralizado"
                    placeholder="Informe aqui o capital social integralizado"
                    v-model="company.social_capital_integrate"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-faturamento-mensal" class="form-label">
                    <span
                      v-if="!company.monthly_billing"
                      v-tooltip="'Preenchimento obrigatório'"
                    >
                      <i
                        class="fa-solid fa-triangle-exclamation text-warning warning me-2"
                      ></i> </span
                    >Faturamento mensal
                    <span
                      v-tooltip="
                        'Qual a sua expectativa de faturamento mensal?'
                      "
                      ><i class="fas fa-info-circle"></i> </span
                  ></label>

                  <base-select
                    :options="[
                      'Até R$ 25.000,00/mês',
                      'De R$ 25.000,00 a R$ 99.999,99/mês',
                      'Acima de R$ 100.000,00/mês',
                    ]"
                    title="faturamento mensal*"
                    :show_title="false"
                    id="input-faturamento-mensal"
                    @update-selected="company.monthly_billing = $event"
                    aria-describedby="input-faturamento-mensal"
                    :selected_id="company.monthly_billing"
                  />
                </div>
              </div>
            </div>
            <!-- Step 4 -->
            <div class="card">
              <h4 class="text-gray text-disabled font-weight-light">
                Endereço da empresa
              </h4>
              <div class="d-flex flex-row mb-3 align-items-center">
                <div class="row mt-4">
                  <label for="input-cep p-0" class="form-label">
                    <span
                      v-if="!company.postal_code"
                      v-tooltip="'Preenchimento obrigatório'"
                    >
                      <i
                        class="fa-solid fa-triangle-exclamation text-warning warning me-2"
                      ></i> </span
                    >Qual o CEP?</label
                  >
                  <input
                    type="text"
                    id="input-cep"
                    class="form-control m-3"
                    aria-describedby="input-cep"
                    placeholder="_ _ _ _ _ - _ _ _"
                    v-model="company.postal_code"
                  />
                </div>
                <div class="mt-4 ms-4">
                  <button
                    @click="getCep('company')"
                    class="btn font-weight-bold btn-primary text-white btn-shadow mt-4"
                  >
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </button>
                </div>
              </div>
              <div class="row">
                <hr class="my-4 text-gray" />
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-rua" class="form-label">
                    <span
                      v-if="!company.street"
                      v-tooltip="'Preenchimento obrigatório'"
                    >
                      <i
                        class="fa-solid fa-triangle-exclamation text-warning warning me-2"
                      ></i> </span
                    >Qual a rua?</label
                  >
                  <input
                    type="text"
                    id="input-rua"
                    class="form-control"
                    aria-describedby="input-rua"
                    placeholder="Informe aqui a rua"
                    v-model="company.street"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary">
                  <label for="input-bairro" class="form-label">
                    <span
                      v-if="!company.neighborhood"
                      v-tooltip="'Preenchimento obrigatório'"
                    >
                      <i
                        class="fa-solid fa-triangle-exclamation text-warning warning me-2"
                      ></i> </span
                    >Qual o bairro?</label
                  >
                  <input
                    type="text"
                    id="input-bairro"
                    class="form-control"
                    aria-describedby="input-bairro"
                    placeholder="Informe aqui o bairro"
                    v-model="company.neighborhood"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-cidade" class="form-label">
                    <span
                      v-if="!company.city"
                      v-tooltip="'Preenchimento obrigatório'"
                    >
                      <i
                        class="fa-solid fa-triangle-exclamation text-warning warning me-2"
                      ></i> </span
                    >Qual a cidade?</label
                  >
                  <input
                    type="text"
                    id="input-cidade"
                    class="form-control"
                    aria-describedby="input-cidade"
                    placeholder="Informe aqui a cidade"
                    v-model="company.city"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-estado" class="form-label">
                    <span
                      v-if="!company.uf"
                      v-tooltip="'Preenchimento obrigatório'"
                    >
                      <i
                        class="fa-solid fa-triangle-exclamation text-warning warning me-2"
                      ></i> </span
                    >Qual o estado?</label
                  >
                  <input
                    type="text"
                    id="input-estado"
                    class="form-control"
                    aria-describedby="input-estado"
                    placeholder="Informe aqui o estado"
                    v-model="company.uf"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-nro" class="form-label">
                    <span
                      v-if="!company.number"
                      v-tooltip="'Preenchimento obrigatório'"
                    >
                      <i
                        class="fa-solid fa-triangle-exclamation text-warning warning me-2"
                      ></i> </span
                    >Qual o número?<span
                      v-tooltip="'Informe apenas o número, sem letras'"
                      ><i class="fas fa-info-circle ms-2"></i> </span
                  ></label>
                  <input
                    type="text"
                    id="input-nro"
                    class="form-control"
                    aria-describedby="input-nro"
                    placeholder="Informe aqui a nro"
                    v-model="company.number"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-complemento" class="form-label"
                    >Complemento</label
                  >
                  <input
                    type="text"
                    id="input-complemento"
                    class="form-control"
                    aria-describedby="input-complemento"
                    placeholder="Se houver, informe o complemento"
                    v-model="company.complement"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-complemento" class="form-label"
                    >Complemento</label
                  >
                  <input
                    type="text"
                    id="input-tipo"
                    class="form-control"
                    aria-describedby="input-typo"
                    placeholder="Informe aqui o tipo de endereço (ponto de referência ou fixo)"
                    v-model="company.type_address"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-cadastro-imobiliario" class="form-label">
                    <span
                      v-if="!company.iptu"
                      v-tooltip="'Preenchimento obrigatório'"
                    >
                      <i
                        class="fa-solid fa-triangle-exclamation text-warning warning me-2"
                      ></i> </span
                    >Código do IPTU</label
                  >
                  <input
                    type="text"
                    id="input-cadastro-imobiliario"
                    class="form-control"
                    aria-describedby="input-cadastro-imobiliario"
                    placeholder="Informe aqui o cadastro imobiliário"
                    v-model="company.iptu"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-metragem" class="form-label">
                    <span
                      v-if="!company.area_building"
                      v-tooltip="'Preenchimento obrigatório'"
                    >
                      <i
                        class="fa-solid fa-triangle-exclamation text-warning warning me-2"
                      ></i> </span
                    >Metragem</label
                  >
                  <input
                    type="text"
                    id="input-metragem"
                    class="form-control"
                    aria-describedby="input-metragem"
                    placeholder="Informe aqui a metragem"
                    v-model="company.area_building"
                  />
                </div>
              </div>
            </div>
            <!-- Step 6 (uni) ou Step 7 (sociedade) -->
            <div class="card">
              <h4 class="text-gray text-disabled font-weight-light">
                Informações de contato
              </h4>
              <h5 class="text-gray text-disabled font-weight-light mb-4">
                Essas informações ficarão contidas no cartão CNPJ, que é de
                domínio público
              </h5>
              <div class="row">
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-nome" class="form-label">
                    <span
                      v-if="!company.name"
                      v-tooltip="'Preenchimento obrigatório'"
                    >
                      <i
                        class="fa-solid fa-triangle-exclamation text-warning warning me-2"
                      ></i> </span
                    >Razão social</label
                  >
                  <input
                    type="text"
                    id="input-nome"
                    class="form-control"
                    aria-describedby="input-nome"
                    placeholder="Informe aqui a razão social"
                    :disabled="true"
                    v-model="company.name"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-fantasia" class="form-label">
                    <span
                      v-if="!company.secondary_name"
                      v-tooltip="'Preenchimento obrigatório'"
                    >
                      <i
                        class="fa-solid fa-triangle-exclamation text-warning warning me-2"
                      ></i> </span
                    >Nome fantasia</label
                  >
                  <input
                    type="text"
                    id="input-fantasia"
                    class="form-control"
                    aria-describedby="input-fantasia"
                    placeholder="Informe aqui o nome fantasia"
                    :disabled="true"
                    v-model="company.secondary_name"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-tel" class="form-label">
                    <span
                      v-if="!company.phone"
                      v-tooltip="'Preenchimento obrigatório'"
                    >
                      <i
                        class="fa-solid fa-triangle-exclamation text-warning warning me-2"
                      ></i> </span
                    >Telefone</label
                  >
                  <input
                    type="tel"
                    id="input-tel"
                    class="form-control"
                    aria-describedby="input-tel"
                    placeholder="Informe aqui o DDD + telefone (sem formatação)"
                    v-model="company.phone"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-cidade" class="form-label">
                    <span
                      v-if="!company.email"
                      v-tooltip="'Preenchimento obrigatório'"
                    >
                      <i
                        class="fa-solid fa-triangle-exclamation text-warning warning me-2"
                      ></i> </span
                    >Email</label
                  >
                  <input
                    type="email"
                    id="input-email"
                    class="form-control"
                    aria-describedby="input-email"
                    placeholder="Informe aqui o email de contato"
                    v-model="company.email"
                  />
                </div>
              </div>
            </div>
            <!-- Step 6 (sociedade) -->
            <div
              class="card"
              v-if="
                !(
                  company.legal_nature === 1 ||
                  company.legal_nature === 3 ||
                  company.legal_nature === 5
                )
              "
            >
              <h4 class="text-gray text-disabled font-weight-light">
                Responsabilidades
              </h4>
              <div class="row">
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-adm" class="form-label"
                    >Sócio administrador</label
                  >
                  <div
                    class="form-check"
                    v-for="(partner, index) in company.partners"
                    :key="`partner_${index}`"
                  >
                    <input
                      class="form-check-input mt-2"
                      type="checkbox"
                      role="switch"
                      :id="index"
                      v-model="partner.is_admin"
                      @change="updateAdmins(index, partner.id)"
                    />
                    <label class="form-check-label" for="check_policy">
                      {{ partner.fullname || "Nome não informado" }}
                    </label>
                  </div>
                </div>
                <hr class="my-4" />
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-rf" class="form-label"
                    >Representante da Receita Federal</label
                  >
                  <base-select
                    :options="admins"
                    title="representante da receita federal*"
                    :show_title="false"
                    @update-selected="company.representative_id = $event"
                    :selected_id="company.representative_id || ''"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-irpf" class="form-label"
                    >Recibo do IRPF</label
                  >
                  <input
                    type="text"
                    id="input-irpf"
                    class="form-control"
                    aria-describedby="input-irpf"
                    :disabled="
                      company.switch_irpf || !company.representative_id
                    "
                    v-model="company.irpf"
                    :placeholder="
                      !company.switch_irpf
                        ? 'Informe aqui o número do recibo da última entrega de IRPF'
                        : 'N/A'
                    "
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="switch-irpf" class="form-label"
                    >Nunca declarei</label
                  >
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="switch-irpf"
                      v-model="company.switch_irpf"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-4 col-md-4 col-sm-12 order-lg-2 order-md-2 order-sm-1 text-start"
          >
            <div class="card mt--4 cursor-auto">
              <div class="d-flex justify-content-center">
                <span
                  class="mb-4 rounded-circle shadow mt-n5"
                  style="width: min-content; padding: 2rem"
                >
                  <i class="fas fa-building fa-5x text-secondary px-2"></i>
                </span>
              </div>
              <div class="row text-center">
                <div class="col-12 h4">
                  <b>{{ company.name }}</b>
                </div>
                <div class="col-12">
                  <b>Email:</b>
                  {{ company.email }}
                </div>
                <span class="d-flex justify-content-center">
                  <hr class="my-4" style="width: 90%" />
                </span>
                <div class="text-center">
                  <button
                    @click="handleSubmmit()"
                    class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
                  >
                    <span class="mx-4">Salvar</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import { useToast } from "vue-toastification";
import BaseSelect from "@/components/BaseSelect.vue";
const toast = useToast();
export default {
  name: "company-edit",
  components: { BaseSelect },
  data() {
    return {
      company: {
        select_area: "",
        other: "",
        activity_branch: "",
        commerce: false,
        service: false,
        industry: false,
        description: "",
        name: "",
        secondary_name: "",
        cnae: "",
        social_capital: "",
        social_capital_integrate: "",
        legal_nature: "",
        monthly_billing: "",
        partners_count: 1,
        postal_code: "",
        street: "",
        neighborhood: "",
        city: "",
        uf: "",
        number: null,
        complement: "",
        type_address: "",
        iptu: "", // on table companies,
        area_building: "",
        city_code: null,
        partners: [],
        voter_number: "",
        voter_section: "",
        switch_irpf: false,
        irpf: "",
        email: "",
        phone: "",
        agreement: "",
        name_responsible: "",
        receita_federal: "",
        expedidor: "",
        representative_id: "",
        employees_count: 0,
        plan_total: 0,
      },
      loaderActive: false,
      actual_step: 0,
      user: {
        email: "",
        phone: "",
      },
      etnias: [
        "Pardo(a)",
        "Branco(a)",
        "Negro(a)",
        "Indígena(a)",
        "Amarelo(a)",
      ],
      genders: ["Feminino", "Masculino", "Outro"],
      marital_statuses: [
        "Casado(a)",
        "Solteiro(a)",
        "Viúvo(a)",
        "Separado(a)",
        "Divorciado(a)",
      ],
      property_regimes: [
        "Comunhão Parcial de Bens",
        "Comunhão Universal de Bens",
        "Separação Legal de Bens",
        "Participação Final nos Aquestos",
      ],
      activities: [],
      selectbox_options: [],
      areas: [
        {
          name: "Advogado",
          value: 1,
        },
        {
          name: "Desenvolvedor de Software",
          value: 2,
        },
        {
          name: "Profissional da saúde",
          value: 3,
        },
        {
          name: "Arquiteto",
          value: 4,
        },
        {
          name: "Vendedor/Representante Comercial",
          value: 5,
        },
        {
          name: "Corretor de imóveis",
          value: 6,
        },
        {
          name: "Outro",
          value: 7,
        },
      ],
      legal_natures: [
        "Sociedade Unipessoal de Advocacia",
        "Sociedade Empresária Limitada (Pluripessoal)",
        "Empresa Individual",
        "Sociedade Simples Pura (Pluripessoal)",
        "Sociedade Empresária Limitada Unipessoal",
      ],
    };
  },
  async mounted() {
    this.month = new Date().getMonth();
    this.year = new Date().getFullYear();
    await this.loadCompany();
  },
  methods: {
    async updateAdmins(index, id) {
      try {
        this.showLoader(true);
        await api.put(`/company/partners/${id}`, {
          is_admin: this.company.partners[index].is_admin ? 1 : 0,
        });
        this.getAdmins();
        this.showLoader(false);
      } catch (e) {
        let message =
          "Ocorreu um erro ao salvar o sócio administrador. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async loadUser() {
      try {
        this.showLoader(true);
        const { data } = await api.get(`/company/user`);
        this.user = data.user;
        this.showLoader(false);
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações do usuário. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    showLoader(status) {
      this.loaderActive = status;
    },
    formatCompany(data) {
      this.getSelectedArea(this.company.area);
      this.getSelectedLegalNature(this.company.legal_nature);
      this.getSelectedMonthlyBuilding(this.company.monthly_billing);
      this.company.other = this.company.other || "";
      this.company.partners_count = 1;
      if (data.andress) {
        this.company = {
          ...this.company,
          postal_code: data.andress.postal_code,
          street: data.andress.street,
          neighborhood: data.andress.neighborhood,
          city: data.andress.city,
          uf: data.andress.uf,
          number: data.andress.number,
          complement: data.andress.complement,
          iptu: this.company.iptu, // on table companies,
          area_building: this.company.area_building,
        };
      }
    },
    getAdmins() {
      this.admins = [];
      for (let partner of this.company.partners) {
        if (partner.is_admin) {
          this.admins.push(partner);
          partner.is_admin = true;
        }
      }
    },
    async loadCompany() {
      try {
        this.showLoader(true);
        const { data } = await api.get(`/company/default_company`);
        this.company = data;
        if (data) {
          this.company = {
            ...this.company,
            select_area: "",
          };
          this.formatCompany(data);
          this.getAdmins();
        }
        this.showLoader(false);
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações da empresa. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    getSelectedLegalNature(data) {
      this.legal_natures.forEach((legal_nature, index) => {
        if (data === legal_nature || legal_nature.includes(data)) {
          this.company.legal_nature = index + 1;
        }
      });
    },
    getSelectedMonthlyBuilding(data) {
      const monthly_bills = [
        "Até R$ 25.000,00/mês",
        "De R$ 25.000,00 a R$ 99.999,99/mês",
        "Acima de R$ 100.000,00/mês",
      ];
      monthly_bills.forEach((mb, index) => {
        if (data === mb) {
          this.company.monthly_billing = index;
        }
      });
      if (!this.company.monthly_billing) this.company.monthly_billing = "";
    },
    getSelectedArea(data) {
      this.areas.forEach((area) => {
        if (data === area.name) {
          this.company.select_area = area.value || "";
        }
      });
      if (!this.company.select_area && this.company.area)
        this.company.select_area = 7;
    },
    async handleSubmmit() {
      this.showLoader(true);
      try {
        const monthly_bills = [
          "Até R$ 25.000,00/mês",
          "De R$ 25.000,00 a R$ 99.999,99/mês",
          "Acima de R$ 100.000,00/mês",
        ];
        let area = this.company.area;
        if (this.company.select_area !== 7) {
          area =
            this.areas[
              this.areas.findIndex((area) => {
                return area.value === this.company.select_area;
              })
            ].name;
        }
        const formatedData = {
          simple_national: this.company.simples_nacional ? "Sim" : "Não",
          other: this.company.other,
          ...this.company,
          area: area,
        };
        formatedData.monthly_billing =
          monthly_bills[this.company.monthly_billing];
        formatedData.legal_nature =
          this.legal_natures[this.company.legal_nature];
        formatedData.social_capital = formatedData.social_capital
          ? formatedData.social_capital.toString()
          : "";
        formatedData.social_capital_integrate =
          formatedData.social_capital_integrate
            ? formatedData.social_capital_integrate.toString()
            : "";
        await api.put(
          `/company/company/update/${this.company.id}`,
          formatedData
        );
        toast.success("Informações salvas com sucesso!");
      } catch (e) {
        let message = "Ocorreu um erro ao salvar informações";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
      this.showLoader(false);
    },
    handleSelectLegalNature(option) {
      if (this.company.legal_nature === option) this.company.legal_nature = "";
      else this.company.legal_nature = option;
    },
    async getCep(step) {
      const { data } = await api.get(
        `https://viacep.com.br/ws/${this.company.postal_code}/json/`
      );
      this[step].street = data.logradouro;
      this[step].neighborhood = data.bairro;
      this[step].city = data.localidade;
      this[step].uf = data.uf;
      this[step].city_code = data.ibge;
    },
  },
};
</script>
<style scoped>
.card-select {
  transition: 0.3s;
}

.card-select:hover {
  transition: 0.3s;
}
.card-select:hover {
  transition: 0.3s;
  transform: translateY(-7px);
  cursor: pointer;
}
.card-select.selected {
  transition-property: border;
  transition-duration: 0.3s;
  border: 2px #342f86 solid;
}
.form-check-input[data-v-90d5440a] {
  width: 4em;
  height: 2em;
}
.badge-plan-font-start {
  font-size: 20px;
  font-weight: 500;
  display: inline-block;
  vertical-align: top;
}
.badge-plan-font-end {
  font-size: 20px;
  font-weight: 500;
}
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
.rounded-circle {
  background-color: rgb(2, 0, 125);
}
</style>
