<template>
  <div>
    <div class="px-4">
      <div class="body p-4">
        <span class="text-primary h2 d-flex align-items-center">
          <button
            @click="$router.push('/empresas')"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
          >
            <i class="fa fa-arrow-left"></i>
          </button>
          <span class="text-gray"> Sócios</span>
        </span>

        <div class="card p-3 mt-4 text-start" v-if="!partners">
          Nenhum sócio cadastrado até o momento.
        </div>
        <div class="text-start p-2" v-else>
          <div class="text-start m-0 row">
            <div
              class="item-title col-lg-4 col-md-6 col-sm-12"
              v-for="(item, d_index) in partners"
              :key="d_index"
            >
              <div class="card mt-4 cursor-auto">
                <div class="d-flex justify-content-center">
                  <span
                    class="mb-4 rounded-circle shadow mt-n5"
                    style="width: min-content; padding: 2rem"
                  >
                    <i class="fa fa-id-card-clip fa-5x text-secondary"></i>
                  </span>
                </div>
                <div class="row text-center">
                  <div class="col-12 h4">
                    <b>{{ item.fullname }}</b>
                  </div>
                  <div class="col-12">
                    <b>CPF:</b> {{ item.cpf || "Não informado" }}
                  </div>
                  <div class="col-12">
                    <b>Email:</b>
                    {{ item.email || "Não informado" }}
                  </div>
                  <div class="col-12">
                    <b>Telefone:</b> {{ item.phone || "Não informado" }}
                  </div>
                  <div class="col-12">
                    <b>Cargo:</b>
                    {{ item.company_function || "Não informado" }}
                  </div>
                  <span class="d-flex justify-content-center">
                    <hr class="my-4" style="width: 90%" />
                  </span>
                  <div class="row justify-content-center">
                    <span class="col-3" v-tooltip="'Ver sócio'">
                      <button
                        class="btn btn-light shadow-sm"
                        @click="$router.push(`/socios/ver/${item.id}`)"
                      >
                        <i class="fas fa-eye ml-2 text-primary"></i>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  name: "partners-view",
  data() {
    return {
      company: {},
      partners: [],
    };
  },
  async mounted() {
    await this.loadEmployees();
  },
  methods: {
    async loadEmployees() {
      try {
        const { data } = await api.get(
          `/user-accountant/companies/${this.$route.params.id}/`
        );
        this.company = data;
        if (this.company && this.company.partners) {
          this.partners = this.company.partners;
        }
      } catch (e) {
        toast.error(
          "Ocorreu um erro ao carregar as informações da empresa. Recarregue a página e tente novamente."
        );
      }
    },
  },
};
</script>
<style scoped>
.rounded-circle {
  background-color: rgb(2, 0, 125);
}
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
</style>
