<template>
  <div class="p-4">
    <div class="card p-4 m-4 text-start">
      <h4>Chamados</h4>
      <br />
      <div>
        <button
          @click="tab = 'opened'"
          :class="
            tab === 'opened'
              ? 'btn-secondary text-white btn-shadow'
              : 'btn-muted'
          "
          class="btn text-uppercase font-weight-light"
        >
          Em aberto
        </button>
        <button
          @click="tab = 'going'"
          :class="
            tab === 'going'
              ? 'btn-secondary text-white btn-shadow'
              : 'btn-muted'
          "
          class="btn text-uppercase font-weight-light"
        >
          Em atendimento
        </button>
        <button
          @click="tab = 'closed'"
          :class="
            tab === 'closed'
              ? 'btn-secondary text-white btn-shadow'
              : 'btn-muted'
          "
          class="btn text-uppercase font-weight-light"
        >
          Encerrados
        </button>
        <button
          @click="tab = 'all'"
          :class="
            tab === 'all' ? 'btn-secondary text-white btn-shadow' : 'btn-muted'
          "
          class="btn text-uppercase font-weight-light"
        >
          Todos
        </button>
      </div>
    </div>

    <div class="text-end">
      <button
        @click="$router.push(`/cliente/chamados/novo`)"
        class="me-4 px-3 btn text-uppercase font-weight-light btn-secondary text-white btn-shadow mb-2 btn-sm"
      >
        <i class="fas fa-plus me-2"></i>Abrir novo chamado
      </button>
    </div>
    <div class="d-flex justify-content-center mt-4" v-if="loaderActive">
      <div class="spinner-border text-primary spinner-border" role="status">
        <span class="visually-hidden">Aguarde...</span>
      </div>
    </div>
    <div class="card m-4 p-3 mt-2 text-start" v-else-if="!calleds.length">
      Sem chamados encontrados
    </div>
    <div class="card p-0 mt-2 mx-4" v-else>
      <div class="collection text-start m-0">
        <div
          class="collection-item relative"
          :class="called.show ? 'opened' : ''"
          v-for="(called, index) in calleds"
          :key="index"
        >
          <span class="collection-title d-flex align-items-center">
            <span class="me-auto">
              <span
                class="badge rounded-pill bg-primary px-3 me-2"
                v-if="called.status === 0"
              >
                <i class="fa-solid fa-lock-open pe-1"></i> Em aberto
              </span>
              <span
                class="badge rounded-pill bg-warning px-3 me-2"
                v-else-if="called.status === 1"
              >
                <i class="fa-solid fa-unlock pe-1"></i> Em atendimento
              </span>
              <span
                class="badge rounded-pill bg-danger px-3 me-2"
                v-else-if="called.status === 2"
              >
                <i class="fa-solid fa-lock pe-1"></i> Encerrado
              </span>
              <span class="">
                <b>#{{ called.id }}</b> - {{ called.title }}
              </span>
            </span>
            <div class="">
              <span class="col-3" v-tooltip="'Acompanhar chamado'">
                <button
                  class="btn btn-light shadow-sm"
                  @click="$router.push(`/cliente/chamados/${called.id}/acompanhar`)"
                >
                  <i class="fas fa-eye ml-2 text-info"></i>
                </button>
              </span>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "calleds-page",
  data() {
    return {
      calleds: [],
      tab: "opened",
      menu_fields: [],
      mounths: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      loaderActive: false
    };
  },
  async mounted() {
    this.handleTabData("opened");
    this.handleMenuFields(); 
    this.initlist();
  },
  methods: {
    showLoader() {
      this.loaderActive = !this.loaderActive;
    },
    async handleTabData(tab) {
      if (tab === "opened") {
        await this.loadCalleds(0);
      } else if (tab === "going") {
        await this.loadCalleds(1);
      } else if (tab === "closed") {
        await this.loadCalleds(2);
      } else {
        await this.loadCalleds(3);
      }
    },
    async loadCalleds(status) {
      this.showLoader();
      try {
        const { data } = await api.get(`/company/calleds?status=${status}`);
        this.calleds = data;
        this.showLoader();
      } catch (e) {
        let message = "Ocorreu um erro ao carregar as informações dos chamados. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    handleMenuFields() {
      const menu_fields = [
        {
          name: "Ver comentários",
          route: "/cliente/chamados",
          icon: "fas fa-comments",
          add_hr: false,
          type: "link",
        },
        {
          name: "Em atendimento",
          icon: "fas fa-handshake",
          add_hr: true,
          type: "link",
        },
        {
          name: "Fechar",
          icon: "fas fa-square-xmark",
          add_hr: false,
          type: "link",
        },
      ];
      this.menu_fields = menu_fields;
    },
    handleMenu(index) {
      const show = this.calleds[index].show_menu;
      this.calleds[index].show_menu = !show;
    },
    initlist() {
      const comp = [];
      this.calleds.forEach((called) => {
        comp.push({
          ...called,
          show_menu: false,
        });
      });
      this.calleds = comp;
    },
  },
  watch: {
    tab: function () {
      this.handleTabData(this.tab);
    },
  },
};
</script>
<style scoped>
.collection-item {
  background-color: transparent;
}
.collection {
  border: none;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.opened {
  border-left: 2px solid #342f86;
}
.pointer {
  cursor: pointer;
}
.collection-title {
  margin: 0;
}
</style>
