<template>
  <div>
    <div class="px-4">
      <div class="body p-4">
        <span class="text-primary h2 d-flex align-items-center">
          <button
            @click="$router.push('/cliente/dashboard')"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
          >
            <i class="fa fa-arrow-left"></i>
          </button>
          <span class="text-gray"> Sócios</span>
        </span>
        <div class="row">
          <!-- <div class="col-lg-5 col-sm-12 order-first mb-4 mt-5">
            <div class="card p-3 mt-4 text-start mb-2">
              <label for="input-adm" class="form-label"
                >Representante da Receita Federal</label
              >
              <select-partner
                :options="admins"
                title="representante da Receita Federal*"
                :show_title="false"
                @update-selected="company_model.representative_id = $event"
              />
              <span class="text-center">
                <button
                  class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
                >
                  <span class="mx-4">Salvar</span>
                </button>
              </span>
            </div>
          </div> -->
          <div class="col-lg-12 col-sm-12 mb-4">
            <div class="text-end">
              <button
                @click="$router.push('/cliente/socios/novo')"
                class="px-3 btn text-uppercase font-weight-light btn-secondary text-white btn-shadow mb-2 btn-sm"
              >
                <i class="fas fa-plus me-2"></i>Cadastrar sócio
              </button>
            </div>
            <div class="card p-3 mt-4 text-start" v-if="!partners.length">
              Nenhum sócio cadastrado até o momento.
            </div>
            <div class="text-start p-2" v-else>
              <div class="text-start m-0 row">
                <div
                  class="item-title col-lg-4 col-md-6 col-sm-12"
                  v-for="(item, d_index) in partners"
                  :key="d_index"
                >
                  <div class="card mt-4 cursor-auto">
                    <div class="d-flex justify-content-center">
                      <span
                        class="mb-4 rounded-circle shadow mt-n5"
                        style="width: min-content; padding: 2rem"
                      >
                        <i class="fa fa-id-card-clip fa-5x text-secondary"></i>
                      </span>
                    </div>
                    <div class="row text-center">
                      <div class="col-12 h4">
                        <b>{{ item.fullname }}</b>
                      </div>
                      <div class="col-12">
                        <b>CPF:</b> {{ item.cpf || "Não informado" }}
                      </div>
                      <div class="col-12">
                        <b>Email:</b>
                        {{ item.email || "Não informado" }}
                      </div>
                      <div class="col-12">
                        <b>Telefone:</b> {{ item.phone || "Não informado" }}
                      </div>
                      <div class="col-12">
                        <b>Cargo:</b>
                        {{ item.company_function || "Não informado" }}
                      </div>
                      <span class="d-flex justify-content-center">
                        <hr class="my-4" style="width: 90%" />
                      </span>
                      <div class="row text-center">
                        <!-- updateAdmins -->
                        <span :for="`switch-irpf-${d_index}`" class="form-label"
                          ><b>É administrador?</b></span
                        >
                        <div class="form-check form-switch d-flex justify-content-center">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            :id="`switch-irpf-${d_index}`"
                            v-model="item.is_admin"
                            @change="updateAdmins(d_index, item.id)"
                          />
                        </div>
                      </div>
                      <span class="d-flex justify-content-center">
                        <hr class="my-4" style="width: 90%" />
                      </span>
                      <div class="row justify-content-center">
                        <span class="col-3" v-tooltip="'Editar'">
                          <button
                            class="btn btn-light shadow-sm"
                            @click="
                              $router.push(`/cliente/socios/editar/${item.id}`)
                            "
                          >
                            <i class="fas fa-edit ml-2 text-primary"></i>
                          </button>
                        </span>
                        <span class="col-3" v-tooltip="'Remover'">
                          <button
                            class="btn btn-light shadow-sm"
                            :disabled="true"
                            @click="
                              $router.push(`/cliente/socios/editar/${item.id}`)
                            "
                          >
                            <i class="fas fa-trash ml-2 text-danger"></i>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* import SelectPartner from "@/components/SelectPartner.vue";
 */import api from "@/services/api";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  name: "partner-modal",
  /* components: { SelectPartner }, */
  data() {
    return {
      partners: [],
      admins: [],
    };
  },
  async mounted() {
    await this.getAdmins();
  },
  methods: {
    async getAdmins() {
      this.admins = [];
      await this.loadEmployees();
      for (let partner of this.partners) {
        if (partner.is_admin) {
          this.admins.push(partner);
          partner.is_admin = true;
        }
      }
    },
    async loadEmployees() {
      try {
        const { data } = await api.get(`/company/partners`);
        this.partners = data;
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações dos sócios. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async updateAdmins(index, id) {
      try {
        await api.put(`/company/partners/${id}`, { is_admin: this.partners[index].is_admin ? 1 : 0 });
        await this.getAdmins();
      } catch (e) {
        let message =
          "Ocorreu um erro ao salvar o sócio administrador. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
  },
};
</script>
<style scoped>
.rounded-circle {
  background-color: rgb(2, 0, 125);
}
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
</style>
