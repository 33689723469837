<template>
  <div>
    <div>
      <div class="body p-4">
        <!-- Guias e Impostos -->
        <div class="card p-4 mt-4 text-start">
          <h3>Guias e Impostos</h3>
          <hr class="mt-1 mb-4" />
          <div class="row">
            <div class="col-lg-2 col-sm-4">
              <select-input
                :options="mounths"
                title="mês"
                :pre-selected-id="new Date().getMonth()"
                v-on:update-selected="(e) => updateMonth(e)"
              />
            </div>
            <div class="col-lg-2 col-sm-4">
              <select-input
                title="ano"
                :year="true"
                v-on:update-selected="(e) => updateYear(e)"
              />
            </div>
          </div>
        </div>
        <br />
        <div class="row text-start bg-gray mb-0 mx-0">
          <button
            v-for="(button, index) in buttons"
            :key="`btn-${index}`"
            :class="`${btn_group_class} ${
              selected_button === index ? ' active' : ''
            }`"
            @click="selected_button = index"
            v-tooltip.top="button.title"
          >
            <span>
              {{ button.title }}
            </span>
          </button>
        </div>
        <div class="card text-start p-4 mt-0 mx-0 card-taxes">
          <div class="d-flex justify-content-center mt-4" v-if="loaderActive">
            <div
              class="spinner-border text-primary spinner-border"
              role="status"
            >
              <span class="visually-hidden">Aguarde...</span>
            </div>
          </div>
          <div
            class="card p-3 mt-4 text-start warning-message"
            v-else-if="!buttons[selected_button].data.length"
          >
            <div class="d-flex justify-content-center align-items-center">
              <i
                class="fa-solid fa-triangle-exclamation text-white fa-1x fa-fade px-3"
                style="--fa-animation-duration: 2s"
              ></i>
              <span class="text-center">
                Não encontramos Guias e Impostos cadastradas para esse mês</span
              >
              <i
                :id="`beat_icon_${selected_button}`"
                class="fa-solid fa-triangle-exclamation text-white fa-1x fa-fade px-3"
                style="--fa-animation-duration: 2s"
              ></i>
            </div>
          </div>
          <div>
            <div
              v-for="(item, index) in buttons[selected_button].data"
              :key="'item-' + index"
              class="card text-start m-0 mb-4"
            >
              <span class="d-flex justify-content-between align-items-center">
                <h4>{{ item.name }}</h4>
                <span
                  class="badge rounded-pill bg-success px-3 py-2 fw-bold fs-6 me-2"
                  v-if="selected_button === 1"
                >
                  PAGA
                </span>
                <span v-else-if="selected_button === 2">
                  <span
                    class="badge rounded-pill bg-danger px-4 py-2 fw-bold fs-6 me-2"
                  >
                    VENCIDA
                  </span>
                  <button
                    class="btn font-weight-light btn-primary text-white btn-shadow"
                    @click="requestNewTax(item.id)"
                  >
                    <i class="fa-solid fa-folder-plus mx-2"></i> Solicitar nova
                    guia
                  </button>
                </span>
              </span>
              <hr class="mb-4" />
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <span class="fw-bold">Vencimento</span>
                  <ul class="task-ul mt-2">
                    <li>
                      <i class="fas fa-calendar me-2"></i
                      >{{ formatDate(item.date_due) }}
                    </li>
                    <li>
                      <i class="fas fa-credit-card me-2"></i>{{ item.price }}
                    </li>
                    <li class="underline pointer">
                      <span
                        class="mx-2"
                        v-tooltip="'Ver guia de pagamento'"
                        @click="handleViewFile(item.id, 'taxes')"
                      >
                        <button class="btn btn-light shadow-sm">
                          <i class="fas fa-eye text-info"></i> Visualizar guia
                        </button>
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <span class="fw-bold">Pagamento</span>
                  <ul class="task-ul mt-2" v-if="selected_button === 1">
                    <li>
                      <i class="fas fa-calendar me-2"></i
                      >{{ formatDate(item.payday) }}
                    </li>
                    <li>
                      <i class="fas fa-credit-card me-2"></i
                      >{{ item.price_paid }}
                    </li>
                    <li class="">
                      <span
                        class="mx-2"
                        v-tooltip="'Ver comprovante de pagamento'"
                        @click="handleViewFile(item.id, 'taxes-payment')"
                        v-if="item.is_paid && item.document_payment_proof"
                      >
                        <button class="btn btn-light shadow-sm">
                          <i class="fas fa-eye text-success"></i> Comprovante
                        </button>
                      </span>
                      <span
                        class="mx-2"
                        v-tooltip="'Anexar comprovante de pagamento'"
                        @click="handleUploadFile(item)"
                        v-else
                      >
                        <button class="btn btn-light shadow-sm">
                          <i class="fas fa-upload text-success"></i> Anexar
                          comprovante
                        </button>
                      </span>
                    </li>
                  </ul>
                  <ul class="task-ul mt-2" v-else>
                    <li class="">
                      <span
                        class="mx-2"
                        v-tooltip="'Anexar comprovante de pagamento'"
                        @click="handleUploadFile(item)"
                      >
                        <button class="btn btn-light shadow-sm">
                          <i class="fas fa-upload text-success"></i> Anexar
                          comprovante
                        </button>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <modal-add-payment-proof
        :modal="modalPayment"
        v-on:updateData="(e) => (modalPayment.data = e)"
        v-on:updateShow="(e) => (modalPayment.show = e)"
      />
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import SelectInput from "@/components/SelectInput.vue";
import { useToast } from "vue-toastification";
import ModalAddPaymentProof from "@/components/Modals/ModalAddPaymentProof";
const toast = useToast();
export default {
  name: "payments-modal",
  props: {
    modal: Object,
  },
  components: {
    SelectInput,
    ModalAddPaymentProof,
  },
  data() {
    return {
      month: "",
      year: "",
      payments: [],
      mounths: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      selected_button: 0,
      btn_group_class: "btn btn-gray col p-4 fa-lg mx-0",
      buttons: [
        {
          title: "Guias a vencer/pagar",
          nick: "A vencer/pagar",
          subtitle: "Anexar comprovante de pagamento",
          route: "/guias-e-impostos",
          icon: "fa-solid fa-file-lines",
          conj: "a",
          data: [],
          // name, month, year, price_paid, price, date_due, payday, is_paid, document_payment_proof
        },
        {
          title: "Guias Pagas",
          nick: "Pagas",
          subtitle: "Anexar comprovante de pagamento",
          route: "/pagamentos-e-despesas",
          icon: "fa-solid fa-wallet",
          conj: "a",
          data: [],
        },
        {
          title: "Guias Vencidas",
          nick: "Vencidas",
          subtitle: "Anexar comprovante de pagamento",
          route: "/folha-de-pagamento",
          icon: "fa-solid fa-file-invoice-dollar",
          conj: "a",
          data: [],
        },
      ],
      loaderActive: false,
      modalPayment: {
        show: false,
        title: "",
        subtitle: "",
        description: "",
        data: "",
        add_document: true,
        url: "taxes",
      },
    };
  },
  mounted() {
    this.month = new Date().getMonth();
    this.year = new Date().getFullYear();
  },
  methods: {
    handleUploadFile(data) {
      this.modalPayment.data = data;
      this.modalPayment.title = "Guias e Impostos";
      this.modalPayment.subtitle = "Anexar comprovante de pagamento da guia";
      this.modalPayment.description =
        "Insira o arquivo comprobatório e preencha as informações solicitadas:";
      this.modalPayment.show = true;
    },
    async requestNewTax(tax_id) {
      try {
        await api.post(`/company/taxes/new-request/${tax_id}`);
        toast.success(
          "Ótimo! Sua solicitação foi enviada ao suporte. O mais breve possível, sua guia será atualizada. Volte mais tarde para conferir."
        );
      } catch (e) {
        let message =
          "Ocorreu um erro ao solicitar nova guia. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async handleViewFile(id, functionality) {
      try {
        const { data } = await api.get(
          `/company/document/${id}?functionality=${functionality}&functionality_row_id=${id}`
        );
        window.open(`${data.public_url}`, "_blank");
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar o documento. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    showLoader() {
      this.loaderActive = !this.loaderActive;
    },
    formatDate(date) {
      const newDate = new Date(date);
      const year = newDate.getUTCFullYear();
      let day = newDate.getUTCDate();
      let month = newDate.getUTCMonth() + 1;
      if (month < "10") {
        month = "0" + month;
      }
      if (day < "10") {
        day = "0" + day;
      }
      return `${day}/${month}/${year}`;
    },
    async loadTaxes() {
      try {
        this.showLoader();
        const { data } = await api.get(`/company/taxes${this.getUrl()}`);
        this.payments = data;
        this.buttons[0].data = data.pending;
        this.buttons[1].data = data.paid;
        this.buttons[2].data = data.overdue;
        this.showLoader();
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações das Guias e Impostos. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    updateMonth(e) {
      this.month = e;
    },
    updateYear(e) {
      this.year = e;
    },
    getUrl() {
      let url = "";
      const month = this.month + 1;
      if (month && this.year) url = `?month=${month}&year=${this.year}`;
      else if (month) url = `/?month=${month}`;
      else if (this.year) url = `/?year=${this.year}`;
      return url;
    },
  },
  watch: {
    "modalPayment.show": async function () {
      if (!this.modalPayment.show) {
        await this.loadTaxes();
      }
    },
    month: async function () {
      await this.loadTaxes();
    },
    year: async function () {
      await this.loadTaxes();
    },
  },
};
</script>
<style scoped>
.task-ul {
  padding-left: 0.5rem;
  list-style-type: none;
}

.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}

.warning-message {
  background-color: #fbb040;
}
.award-message {
  background-color: #1fb866;
  width: 90%;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.award-message,
.warning-message {
  border-radius: 30px;
  color: white;
  border: none;
}

.task {
  transition: 0.3s;
}
.task:hover {
  transition: 0.3s;
  background-color: #f0f0f0;
  cursor: pointer;
}
.rounded-circle {
  border: 3px solid;
  border-color: rgb(2, 0, 125);
}
.collection,
.collection-title {
  border-radius: 20px;
  border: none;
}
.collection-title {
  min-height: 70px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.025),
    0 3px 1px -2px rgba(0, 0, 0, 0.025), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.warning {
  padding-right: 10px !important;
}
.btn.btn-gray.active {
  border-radius: 20px 20px 0px 0px !important;
  background-color: #342f86 !important;
  color: white !important;
  border: none !important;
}
.btn.btn-gray.active:hover {
  background-color: #fbb040 !important;
  color: black !important;
  border: none !important;
}

.btn.btn-gray {
  border-radius: 20px 20px 0px 0px !important;
  border: none !important;
}
.underline,
.task-title {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}
.task-title {
  font-weight: bold;
}
.btn-gray {
  margin-inline: 3px;
  border-radius: 0px;
  background-color: rgb(244, 244, 244);
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.25);
  border: none;
}

.btn-gray:hover {
  background-color: #fbb040;
  color: black;
  border: none;
}
.btn {
  border-radius: 40px;
}
.card-taxes {
  border-radius: 0px 0px 25px 25px;
}
</style>
