<template>
  <div class="container">
    <div class="row mt-2">
      <!-- Login -->
      <div>
        <div class="d-flex justify-content-center">
          <!-- Register -->
          <div class="card w-50 col-12">
            <div>
              <h4
                class="text-gray font-weight-light text-center"
                for="input-reason"
              >
                Redefinir senha
              </h4>
              <div class="row text-start">
                <div class="col-lg-12 text-primary mb-2">
                  <label for="input-pass" class="form-label">Senha</label>
                  <div class="input-group mb-3">
                    <input
                      :type="showPassword ? 'text' : 'password'"
                      id="input-pass"
                      class="form-control"
                      aria-describedby="input-pass"
                      placeholder="Informe aqui a senha"
                      v-model="model.password"
                    />
                    <span
                      v-show="
                        !showPassword ? 'Visualizar senha' : 'Ocultar senha'
                      "
                    >
                      <button
                        class="btn"
                        :class="{
                          'btn-outline-primary': !showPassword,
                          'btn-primary': showPassword,
                        }"
                        type="button"
                        id="button-addon"
                        style="
                          border-radius: 0px 10px 10px 0px;
                          border-color: #ced4da;
                        "
                        @click="showPassword = !showPassword"
                      >
                        <i class="fas fa-eye"></i></button
                    ></span>
                  </div>
                </div>
                <div class="col-12 text-primary mb-2">
                  <label for="input-pass-c" class="form-label"
                    >Confirmação de senha</label
                  >
                  <div class="input-group mb-3">
                    <input
                      :type="showConfirmationPassword ? 'text' : 'password'"
                      id="input-pass-c"
                      class="form-control"
                      aria-describedby="input-pass-c"
                      placeholder="Confirme aqui a sua senha"
                      v-model="model.password_confirm"
                    />
                    <span
                      v-show="
                        !showConfirmationPassword
                          ? 'Visualizar senha'
                          : 'Ocultar senha'
                      "
                    >
                      <button
                        class="btn"
                        :class="{
                          'btn-outline-primary': !showConfirmationPassword,
                          'btn-primary': showConfirmationPassword,
                        }"
                        type="button"
                        id="button-addon"
                        style="
                          border-radius: 0px 10px 10px 0px;
                          border-color: #ced4da;
                        "
                        @click="
                          showConfirmationPassword = !showConfirmationPassword
                        "
                      >
                        <i class="fas fa-eye"></i></button
                    ></span>
                  </div>
                  <p v-show="!isValidPassword" class="text-danger pt-1">
                    <i class="fas fa-warning text-danger"></i>
                    *As senhas precisam coincidir
                  </p>
                  <p v-show="!isValidCharPassword" class="text-danger pt-1">
                    <i class="fas fa-warning text-danger"></i>
                    *As senhas precisam conter ao menos 1 letra maiúscula, 1
                    caractere especial e 1 número
                  </p>
                  <p v-show="!isValidCharPassword" class="text-danger pt-1">
                    <i class="fas fa-warning text-danger"></i>
                    *As senhas precisam conter ao menos 8 caracteres
                  </p>
                </div>
              </div>
            </div>
            <span class="text-center mt-1">
              <span
                v-tooltip="
                  isValidModel
                    ? 'Clique para redefinir senha'
                    : 'Preencha todas as informações obrigatórias (marcadas com *) antes de continuar'
                "
              >
                <button
                  :disabled="!isValidModel"
                  @click="handleSubmmit()"
                  type="submit"
                  class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
                >
                  <span class="mx-4">Salvar</span>
                </button>
              </span>
              <p class="mt-3">
                Voltar para o
                <a href="/entrar" @click="view_register = 0" class="link-button"
                  ><b>login.</b></a
                >
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import { useToast } from "vue-toastification";
import { getRole } from "@/services/auth";
const toast = useToast();

export default {
  name: "user-register",
  data() {
    return {
      showPassword: false,
      showConfirmationPassword: false,
      model: {
        password: "",
        password_confirm: "",
        agreeded_privacy_policy: false,
        agreeded_terms_of_use: false,
      },
      requiredFields: ["password"],
      roles: {
        1: "suporte",
        2: "contador",
        3: "cliente",
      },
      user: {},
      userId: null,
      faToken: "",
    };
  },
  async mounted() {
    const role = getRole();
    if (parseInt(role) === 3) {
      // getUser
      await this.loadUser();
      if (!this.user.companies.length) {
        this.$router.push(`/cliente`);
      } else this.$router.push(`/cliente/dashboard`);
    } else if (parseInt(role) === 2) {
      this.$router.push(`/dashboard`);
    }
  },
  methods: {
    async loadUser() {
      try {
        const { data } = await api.get(`/company/user`);
        this.user = data.user;
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações do usuário. Faça login novamente para continuar.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
        this.$router.push("/entrar");
      }
    },
    async handleSubmmit() {
      try {
        const userData = {
          password: this.model.password,
          token: this.$route.params.token,
        };
        await api.post("reset-password", userData);
        toast.success("Senha alterada com sucesso! Faça o login novamente.");
        this.$router.push(`/entrar`);
      } catch (e) {
        let message = "Ocorreu um erro ao salvar a nova senha";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
  },
  computed: {
    isValidCharPassword() {
      const pass = this.model.password;
      const hasNum = /\d/.test(pass);
      const hasChar = /[!@#$%^&*(),.?":{}|<>]/.test(pass);
      const hasCap = /[A-Z]/.test(pass);
      const hasMinLength = pass.length >= 8;
      return hasNum && hasChar && hasCap && hasMinLength;
    },
    isValidPassword() {
      return this.model.password === this.model.password_confirm;
    },
    isValidModel() {
      const data = this.model;
      for (const key of this.requiredFields) {
        if (!data[key]) return false;
      }
      return true;
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}
.card {
  border-radius: 20px;
  padding: 40px;
}
.btn {
  border-radius: 40px;
}
.text-gray {
  color: #545454;
}
.sign-button {
  width: 35%;
}
.bold {
  font-weight: bold;
}
.rounded {
  border-radius: 20px;
}
.desc {
  font-size: 22px;
}
.link-button {
  cursor: pointer;
}
</style>
