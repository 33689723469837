<template>
  <div>
    <div>
      <div class="body p-4">
        <div class="text-start">
          <h4 class="row text-capitalize">
            <b>Extratos bancários</b>
          </h4>
        </div>
        <!-- Extratos Bancários -->
        <div class="d-flex justify-content-center mt-4" v-if="loaderActive">
          <div class="spinner-border text-primary spinner-border" role="status">
            <span class="visually-hidden">Aguarde...</span>
          </div>
        </div>
        <div class="card p-4 mt-4 text-start">
          <h4>Buscar</h4>
          <div class="row">
            <div class="col-lg-2 col-sm-5">
              <select-input
                :options="months"
                title="mês"
                :pre-selected-id="new Date().getMonth()"
                v-on:update-selected="(e) => updateMonth(e)"
              />
            </div>
            <div class="col-lg-2 col-sm-5">
              <select-input
                title="ano"
                :year="true"
                v-on:update-selected="(e) => updateYear(e)"
              />
            </div>
          </div>
        </div>
        <div class="text-end">
          <button
            @click="$router.push('/cliente/extratos-bancarios/novo')"
            class="px-3 btn text-uppercase font-weight-light btn-secondary text-white btn-shadow mb-2 btn-sm"
          >
            <i class="fas fa-plus me-2"></i>Cadastrar novo
          </button>
        </div>
        <div class="card p-3 mt-4 text-start" v-if="!extratos.length">
          Não encontramos Extratos Bancários cadastrados para esse mês ({{
            months[month]
          }})
        </div>
        <div class="card text-start p-2" v-else>
          <div class="collection text-start m-0">
            <div
              class="collection-item relative item-title"
              v-for="(item, d_index) in extratos"
              :key="d_index"
            >
              <p class="collection-title d-flex">
                <span class="me-auto">
                  <b>{{ item.bank.name }}</b> - {{ item.document }}
                </span>
                <span
                  class="mx-2"
                  v-tooltip="'Remover extrato'"
                  @click="deleteExtrato(item.id)"
                >
                  <button class="btn btn-light shadow-sm">
                    <i class="fas fa-trash text-danger"></i>
                  </button>
                </span>
                <span
                  class="mx-2"
                  v-tooltip="'Ver extrato'"
                  @click="handleViewFile(item.id, 'BankReports')"
                >
                  <button class="btn btn-light shadow-sm">
                    <i class="fas fa-eye text-info"></i>
                  </button>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import SelectInput from "@/components/SelectInput.vue";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "extratos-modal",
  props: {
    modal: Object,
  },
  components: {
    SelectInput,
  },
  data() {
    return {
      month: "",
      year: "",
      extratos: [],
      months: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      loaderActive: false,
    };
  },
  mounted() {
    this.month = new Date().getMonth();
    this.year = new Date().getFullYear();
  },
  methods: {
    async deleteExtrato(id) {
      this.showLoader();
      try {
        await api.delete(`/company/extratos/${id}`);
        this.$router.push("/cliente/extratos-bancarios");
        toast.success("Extrato removido com sucesso!");
        await this.loadExtratos()
      } catch (e) {
        let message = "Ocorreu um erro ao remover o extrato bancário. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
      this.showLoader();
    },
    showLoader() {
      this.loaderActive = !this.loaderActive;
    },
    formatDate(date) {
      if (date) {
        const newDate = new Date(date);
        const year = newDate.getUTCFullYear();
        let day = newDate.getUTCDate();
        let month = newDate.getUTCMonth() + 1;
        if (month < "10") {
          month = "0" + month;
        }
        if (day < "10") {
          day = "0" + day;
        }
        //dd-MM-yyyy
        return `${day}/${month}/${year}`;
      } else {
        return "Data não informada";
      }
    },
    async handleViewFile(id, functionality) {
      try {
        const { data } = await api.get(
          `/company/document/${id}?functionality=${functionality}&functionality_row_id=${id}`
        );
        window.open(`${data.public_url}`, "_blank");
      } catch (e) {
        let message = "Ocorreu um erro ao carregar o documento. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async loadExtratos() {
      try {
        this.showLoader();
        const { data } = await api.get(`/company/extratos${this.getUrl()}`);
        this.extratos = data;
        this.showLoader();
      } catch (e) {
        let message = "Ocorreu um erro ao carregar as informações dos extratos bancários. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    updateMonth(e) {
      this.month = e;
    },
    updateYear(e) {
      this.year = e;
    },
    getUrl() {
      let url = "";
      const month = this.month + 1;
      if (month && this.year) url = `?month=${month}&year=${this.year}`;
      else if (month) url = `/?month=${month}`;
      else if (this.year) url = `/?year=${this.year}`;
      return url;
    },
  },
  watch: {
    month: async function () {
      await this.loadExtratos();
    },
    year: async function () {
      await this.loadExtratos();
    },
  },
};
</script>
<style scoped>
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
</style>
