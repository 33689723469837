<template>
  <div>
    <div class="px-4">
      <div class="body p-4">
        <span class="text-primary h2 d-flex align-items-center">
          <button
            @click="handleGoBack()"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
          >
            <i class="fa fa-arrow-left"></i>
          </button>
          <span class="text-gray"> Cadastrar extrato bancário</span>
        </span>
        <!-- Extratos -->
        <div class="card p-4 mt-4">
          <div class="p-2">
            <!-- <h4 class="text-gray font-weight-light">Novo extrato</h4> -->
            <div class="row text-start">
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-0">Banco*</label>
                <input
                  type="text"
                  id="input-cod"
                  class="form-control"
                  aria-describedby="input-cod"
                  placeholder="Informe aqui o código do extrato"
                  v-model="model.bank_code"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-type" class="form-label mb-2"
                  >Mês e ano de referência*</label
                >
                <input
                  type="month"
                  id="input-type"
                  class="form-control"
                  aria-describedby="input-type"
                  v-model="model.date"
                />
              </div>
              <div
                class="col-lg-6 col-sm-12 text-primary mb-4"
                v-for="(document, index) in documents"
                :key="index"
              >
                <label for="input-type" class="form-label mb-2">Arquivo*</label>
                <br />
                <base-file
                  :value="document[index]"
                  :index="index"
                  title="Selecione o documento"
                  accept=""
                  :mbSize="50"
                  type="secondary text-white"
                  @input-upload="document[index] = $event"
                  @add-file="addFile()"
                  @remove-field="handleRemoveField($event, index)"
                />
              </div>
            </div>
            <span class="text-center mt-4">
              <button
                @click="submmitForm()"
                class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
              >
                <span class="mx-4">Cadastrar</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import BaseFile from "@/components/BaseFile.vue";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "extratos-form",
  components: {
    BaseFile,
  },
  data() {
    return {
      month: "",
      year: "",
      extratos: [],
      mounths: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      model: {
        date: "",
        bank_code: "",
      },
      documents: [{}],
    };
  },
  methods: {
    handleGoBack() {
      const previousUrl = localStorage.getItem("previousUrl");
      if (previousUrl) {
        this.$router.push(previousUrl);
      } else {
        this.$router.push("/");
      }
    },
    addFile() {
      this.documents.push({});
    },
    handleRemoveField(event, index) {
      if (event && this.documents.length > 0) {
        this.documents.splice(index, 1);
      }
    },
    async submmitForm() {
      try {
        const { data } = await api.post(`/company/extratos`, this.model);
        this.extratos = data;
        toast.success("Extrato cadastrado com sucesso");
        this.$router.push("/extratos");
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações dos extratos. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async loadExtratos() {
      try {
        /* const { data } = await api.get(
          `/company/extratos/${this.modal.data.id}${this.getUrl()}`
        ); */

        const { data } = await api.get(`/company/extratos${this.getUrl()}`);
        this.extratos = data;
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações dos extratos. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    updateMonth(e) {
      this.month = e;
    },
    updateYear(e) {
      this.year = e;
    },
    getUrl() {
      let url = "";
      const month = this.month + 1;
      if (month && this.year) url = `?month=${month}&year=${this.year}`;
      else if (month) url = `/?month=${month}`;
      else if (this.year) url = `/?year=${this.year}`;
      return url;
    },
  },
  watch: {
    month: async function () {
      await this.loadExtratos();
    },
    year: async function () {
      await this.loadExtratos();
    },
  },
};
</script>
<style scoped>
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
</style>
