<template>
  <modal :show="modal.show" :modalClass="modal.class" 
    ><slot name="header" class="modal-title p-2" id="modal-title-notification">
      <slot name="close-button"
        ><div class="text-end">
          <button
            @click="emitEvent(false);"
            class="px-3 close-button text-primary"
          >
            <i class="fas fa-xmark text-white"></i>
          </button></div
      ></slot>
    </slot>
    <div class="body p-4" v-if="modal.title">
      <div class="text-start">
        <h4 class="row text-center">
          <i
            class="fa-solid fa-triangle-exclamation text-warning fa-5x fa-fade px-3 mb-4"
            style="--fa-animation-duration: 1s"
          ></i>
          <b>{{ modal.title }}</b>
        </h4>
        <div class="text-center">
          <span class="my-4">
            <b>{{ modal.subtitle }}</b>
            <p>{{ modal.description }}</p>
          </span>
          <br />
          <br />
          <div class="mt-4 d-flex justify-content-center">
            <button
              class="btn text-uppercase font-weight-light btn-success text-white btn-shadow me-2"
              @click="emitEvent(true);"
            >
              <i class="fa-solid fa-floppy-disk me-1"></i> Confirmar
            </button>
            <button
              @click="emitEvent(false);"
              class="btn text-uppercase font-weight-light btn-danger text-white btn-shadow"
            >
              <i class="fas fa-ban me-1"></i>Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
export default {
  name: "modal-confirmation",
  props: {
    modal: Object,
  },
  data() {
    return {
      confirmation: false,
    };
  },
  mounted () {
/*     console.log(this.modal.class);
 */  },
  methods: {
    emitEvent(e) {
      this.$emit("confirmation", e);
      this.$emit('update-show', false);
    },
  },
};
</script>
<style scoped>
.modal-warning {
  background-color: #342f86;
  color: white;
}

.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
</style>
