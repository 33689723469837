<template>
  <div>
    <div class="px-4">
      <div class="body p-4">
        <span class="text-primary h2 d-flex align-items-center">
          <button
            @click="handleGoBack"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
          >
            <i class="fa fa-arrow-left"></i>
          </button>
          <span class="text-gray"> Iniciar novo chamado</span>
        </span>
        <!-- Guias e Impostoss -->
        <div class="row">
          <div
            :class="
              document
                ? document.type.includes('image') ||
                  !document.type.includes('pdf')
                  ? 'col-lg-8 col-md-6 col-sm-12'
                  : 'col-6'
                : 'col-lg-8 col-md-6 col-sm-12'
            "
          >
            <div class="card p-4 mt-4">
              <div class="p-2">
                <div class="row text-start">
                  <div class="col-lg-6 col-sm-12 text-primary mb-2">
                    <label for="input-title" class="form-label mb-2"
                      >Assunto*</label
                    >
                    <input
                      type="text"
                      id="input-title"
                      class="form-control"
                      aria-describedby="input-title"
                      placeholder="Informe aqui o assunto do chamado"
                      v-model="model.title"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-2">
                    <label for="input-type" class="form-label mb-2 text-primary"
                      >Setor*</label
                    >
                    <base-select
                      :options="areas"
                      :title="'área'"
                      :show_title="false"
                      @update-selected="model.area = $event"
                      :selected_id="model.area"
                    />
                  </div>
                  <div class="col-lg-12 col-sm-12">
                    <label
                      for="input-price_paid"
                      class="form-label mb-2 text-primary"
                      >Descrição/Comentário*</label
                    >
                    <tiptap v-model="model.description" />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-price_paid" class="form-label mb-2"
                      >Arquivo</label
                    >
                    <br />
                    <base-file
                      :value="document"
                      title="Selecione o documento"
                      accept=""
                      :mbSize="50"
                      type="secondary text-white"
                      @input-upload="document = $event"
                    />
                  </div>
                </div>
                <span class="text-center mt-4">
                  <button
                    @click="submmitForm()"
                    class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
                  >
                    <span class="mx-4">Enviar</span>
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div
            :class="
              document
                ? document.type.includes('image') ||
                  !document.type.includes('pdf')
                  ? 'col-lg-4 col-md-6 col-sm-12'
                  : 'col-6'
                : 'col-lg-4 col-md-6 col-sm-12'
            "
          >
            <div class="card p-4 mt-4">
              <div class="p-2">
                <div
                  class="mb-2 text-primary fw-bold pointer mb-3"
                  @click="doc_preview ? openLink() : ''"
                >
                  Pré visualização do arquivo a ser enviado
                  <span v-tooltip="'abrir em nova guia'" v-if="doc_preview"
                    ><i class="ms-1 fa-solid fa-up-right-from-square"></i>
                  </span>
                </div>

                <div v-if="!document">
                  <span class="badge rounded-pill bg-warning px-3 me-2">
                    Nenhum arquivo adicionado até o momento
                  </span>
                </div>
                <div v-else>
                  <div v-if="document.type.includes('image')">
                    <img
                      id="doc"
                      :src="doc_preview"
                      alt="anexo"
                      class="img-fluid rounded-preview"
                    />
                  </div>
                  <div v-else-if="document.type.includes('pdf')">
                    <iframe
                      id="doc"
                      :src="doc_preview"
                      class="container-fluid"
                      style="min-height: 50vh; border: none"
                    ></iframe>
                  </div>
                  <div
                    v-else-if="
                      document.type.includes('zip') ||
                      document.type.includes('rar')
                    "
                  >
                    <span class="badge rounded-pill bg-secondary px-3 me-2">
                      Não há pré-visualização para o formato de arquivo
                      adicionado
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-confirmation
      :modal="modalConfirmation"
      v-on:confirmation="(e) => (confirmation = e)"
      v-on:updateShow="(e) => (modalConfirmation.show = e)"
    />
  </div>
</template>
<script>
import Tiptap from "@/components/Tiptap.vue";
import BaseSelect from "@/components/BaseSelect.vue";
import BaseFile from "@/components/BaseFile.vue";
import ModalConfirmation from "@/components/Modals/ModalConfirmation.vue";
import api from "@/services/api";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "acc-calleds-form",
  components: {
    BaseSelect,
    BaseFile,
    ModalConfirmation,
    Tiptap,
  },
  data() {
    return {
      confirmation: false,
      document: "",
      doc_preview: "",
      model: {
        title: "",
        description: "",
        area: "",
      },
      areas: [
        "Comercial",
        "Departamento Contábil/Fiscal",
        "Departamento Pessoal",
        "Departamento Financeiro",
        "Societário",
        "Suporte",
        "Outro/Não sei",
      ],
      modalConfirmation: {
        show: false,
        title: "",
        subtitle: "",
        description: "",
        class: "modal-warning",
        data: {},
      },
    };
  },
  methods: {
    handleGoBack() {
      const previousUrl = localStorage.getItem("previousUrl");
      if (previousUrl) {
        this.$router.push(previousUrl);
      } else {
        this.$router.push("/");
      }
    },
    handleOpenModal() {
      this.modalConfirmation.data = this.model;
      this.modalConfirmation.title = "Opa!";
      this.modalConfirmation.subtitle =
        "Parece que você escreveu a palavra 'anexo' mas não adicionou nenhum arquivo";
      this.modalConfirmation.description =
        "Tem certeza de que deseja iniciar o chamado mesmo assim?";
      this.modalConfirmation.show = true;
    },
    async submmitForm() {
      if (this.checkContent()) {
        await this.handleSubmmitForm();
      } else {
        this.handleOpenModal();
      }
    },
    async handleSubmmitForm() {
      try {
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const formData = new FormData();
        const formatedData = {
          ...this.model,
        };
        formData.append("data", JSON.stringify(formatedData));
        formData.append("document", this.document);
        const { data } = await api.post(
          `/user-accountant/calleds/${this.$route.params.id}`,
          formData,
          config
        );
        toast.success("Chamado iniciado com sucesso!");
        this.confirmation = false;
        this.$router.push(
          `/chamados/${this.$route.params.id}/ver/${data.called.id}`
        );
        URL.revokeObjectURL(this.doc_preview);
      } catch (e) {
        let message =
          "Ocorreu um erro ao salvar as informações do chamado. Verifique as informações e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    checkContent() {
      if (
        this.model.description.toLowerCase().includes("anexo") &&
        !this.document
      )
        return false;
      else return true;
    },
    openLink() {
      window.open(this.doc_preview, "_blank");
    },
  },
  watch: {
    document: function () {
      if (this.document) {
        this.doc_preview = URL.createObjectURL(this.document);
      }
    },
    confirmation: async function () {
      if (this.confirmation) {
        await this.handleSubmmitForm();
      }
    },
  },
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
.rounded-preview {
  border-radius: 20px;
}
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
</style>
