<template>
  <div class="p-4">
    <div class="card p-4 m-4 text-start">
      <h4>Chamados</h4>
      <br />
      <div>
        <button
          @click="tab = 'opened'"
          :class="
            tab === 'opened'
              ? 'btn-secondary text-white btn-shadow'
              : 'btn-muted'
          "
          class="btn text-uppercase font-weight-light"
        >
          Em aberto
        </button>
        <button
          @click="tab = 'going'"
          :class="
            tab === 'going'
              ? 'btn-secondary text-white btn-shadow'
              : 'btn-muted'
          "
          class="btn text-uppercase font-weight-light"
        >
          Em atendimento
        </button>
        <button
          @click="tab = 'closed'"
          :class="
            tab === 'closed'
              ? 'btn-secondary text-white btn-shadow'
              : 'btn-muted'
          "
          class="btn text-uppercase font-weight-light"
        >
          Encerrados
        </button>
        <button
          @click="tab = 'all'"
          :class="
            tab === 'all' ? 'btn-secondary text-white btn-shadow' : 'btn-muted'
          "
          class="btn text-uppercase font-weight-light"
        >
          Todos
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-center mt-4 mb-4" v-if="loaderActive">
      <div class="spinner-border text-primary spinner-border" role="status">
        <span class="visually-hidden">Aguarde...</span>
      </div>
    </div>
    <div class="card m-4 p-3 mt-4 text-start" v-else-if="!calleds.length">
      Sem chamados encontrados
    </div>
    <div class="card p-0 m-4" v-else>
      <div class="collection text-start m-0">
        <div
          class="collection-item relative"
          :class="called.show ? 'opened' : ''"
          v-for="(called, index) in calleds"
          :key="index"
        >
          <span class="collection-title d-flex align-items-center">
            <span class="me-auto">
              <span
                class="badge rounded-pill bg-primary px-3 me-2"
                v-if="called.status === 0"
              >
                <i class="fa-solid fa-lock-open pe-1"></i> Em aberto
              </span>
              <span
                class="badge rounded-pill bg-warning px-3 me-2"
                v-else-if="called.status === 1"
              >
                <i class="fa-solid fa-unlock pe-1"></i> Em atendimento
              </span>
              <span
                class="badge rounded-pill bg-danger px-3 me-2"
                v-else-if="called.status === 2"
              >
                <i class="fa-solid fa-lock pe-1"></i> Encerrado
              </span>
              <span class="">
                <b>#{{ called.id }}</b> - {{ called.title }}
              </span>
            </span>

            <div class="row justify-content-center">
              <span
                class="col-3"
                v-tooltip="
                  called.status === 0
                    ? 'O chamado já está em aberto'
                    : 'Mudar status para aberto'
                "
              >
                <button
                  class="btn btn-light shadow-sm"
                  :disabled="called.status === 0"
                  @click="handleChangeStatusCalled(0, called.id)"
                >
                  <i class="fa-solid fa-lock-open ml-2 text-primary"></i>
                </button>
              </span>
              <span
                class="col-3"
                v-tooltip="
                  called.status === 1
                    ? 'O chamado já está em atendimento'
                    : 'Mudar status para em atendimento'
                "
              >
                <button
                  class="btn btn-light shadow-sm"
                  :disabled="called.status === 1"
                  @click="handleChangeStatusCalled(1, called.id)"
                >
                  <i class="fa-solid fa-unlock ml-2 text-warning"></i>
                </button>
              </span>
              <span
                class="col-3"
                v-tooltip="
                  called.status === 2
                    ? 'O chamado já está encerrado'
                    : 'Mudar status para encerrado'
                "
              >
                <button
                  class="btn btn-light shadow-sm"
                  :disabled="called.status === 2"
                  @click="handleChangeStatusCalled(2, called.id)"
                >
                  <i class="fa-solid fa-lock ml-2 text-danger"></i>
                </button>
              </span>
              <span class="col-3" v-tooltip="'Ver comentários'">
                <button
                  class="btn btn-light shadow-sm"
                  @click="
                    goToNextPage(
                      `/chamados/${called.company_id}/ver/${called.id}`
                    )
                  "
                >
                  <i class="fas fa-eye ml-2 text-info"></i>
                </button>
              </span>
            </div>
          </span>
        </div>
      </div>
    </div>
    <div class="col-lg-12" v-if="calleds.length">
      <base-pagination
        :pageCount="control.lastPage"
        :perPage="control.limit"
        :value="control.page"
        size="md"
        align="center"
        @input="handlePageInput"
      />
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import { useToast } from "vue-toastification";
const toast = useToast();

import BasePagination from "@/components/BasePagination.vue";
export default {
  name: "calleds-page",
  components: {
    BasePagination,
  },
  data() {
    return {
      loaderActive: false,
      calleds: [],
      tab: "opened",
      menu_fields: [],
      mounths: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      control: {
        page: 1,
        limit: 10,
        lastPage: 0,
        total: 0,
        empty: "",
      },
    };
  },
  async mounted() {
    this.handleTabData("opened", 1);
    this.handleMenuFields();
    this.initlist();
  },
  methods: {
    goToNextPage(route) {
      const currentUrl = this.$route.fullPath;
      localStorage.setItem("previousUrl", currentUrl);
      this.$router.push(route);
    },
    async handleChangeStatusCalled(status, id) {
      try {
        const { data } = await api.put(
          `/user-accountant/calleds/${id}/change-status`,
          { status }
        );
        this.called = data;
        toast.success("Status alterado com sucesso!");
        await this.loadCalled();
      } catch (e) {
        let message =
          "Ocorreu um erro ao alterar o status do chamado. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    handleOpenModal(index) {
      this.handleMenu(index);
    },
    async handleTabData(tab, page) {
      if (tab === "opened") {
        await this.loadCalleds(0, page);
      } else if (tab === "going") {
        await this.loadCalleds(1, page);
      } else if (tab === "closed") {
        await this.loadCalleds(2, page);
      } else {
        await this.loadCalleds(3, page);
      }
    },
    showLoader() {
      this.loaderActive = !this.loaderActive;
    },
    async handlePageInput(page) {
      await this.handleTabData(this.tab, page);
    },
    async loadCalleds(status, page) {
      try {
        this.showLoader();
        let url = `/user-accountant/calleds?status=${status}&limit=${this.control.limit}`;
        if (page) url += `&page=${page}`;
        const { data: calleds } = await api.get(url);
        this.control.lastPage = calleds.meta.last_page;
        this.control.total = calleds.meta.total;
        this.control.page = calleds.meta.current_page;
        this.calleds = calleds.data;
        this.showLoader();
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações dos chamados. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    handleMenuFields() {
      const menu_fields = [
        {
          name: "Ver comentários",
          route: "/chamados",
          icon: "fas fa-comments",
          add_hr: false,
          type: "link",
        },
        {
          name: "Em atendimento",
          icon: "fas fa-handshake",
          add_hr: true,
          type: "link",
        },
        {
          name: "Fechar",
          icon: "fas fa-square-xmark",
          add_hr: false,
          type: "link",
        },
      ];
      this.menu_fields = menu_fields;
    },
    handleMenu(index) {
      const show = this.calleds[index].show_menu;
      this.calleds[index].show_menu = !show;
    },
    initlist() {
      const comp = [];
      this.calleds.forEach((called) => {
        comp.push({
          ...called,
          show_menu: false,
        });
      });
      this.calleds = comp;
    },
  },
  watch: {
    tab: function () {
      this.handleTabData(this.tab, this.control.page);
    },
    sort: async function () {},
  },
};
</script>
<style scoped>
.collection-item {
  background-color: transparent;
}
.collection {
  border: none;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.opened {
  border-left: 2px solid #342f86;
}
.pointer {
  cursor: pointer;
}
.collection-title {
  margin: 0;
}
</style>
