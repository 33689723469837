<template>
  <div>
    <div class="px-4">
      <div class="body p-4">
        <span class="text-primary h2 d-flex align-items-center">
          <button
            @click="$router.push('/cliente/socios')"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
          >
            <i class="fa fa-arrow-left"></i>
          </button>
          <span class="text-gray"> Editar sócio</span>
        </span>
        <!-- Funcionários -->
        <div class="d-flex justify-content-center mt-4" v-if="loaderActive">
          <div class="spinner-border text-primary spinner-border" role="status">
            <span class="visually-hidden">Aguarde...</span>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8">
            <div class="card p-4 mt-4 text-start">
              <div class="p-2">
                <div class="row">
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-cidade" class="form-label">CPF</label>
                    <input
                      type="text"
                      id="input-cpf"
                      class="form-control"
                      aria-describedby="input-cpf"
                      placeholder="Informe aqui o CPF"
                      v-model="partner.cpf"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-nome" class="form-label"
                      >Nome completo</label
                    >
                    <input
                      type="text"
                      id="input-nome"
                      class="form-control"
                      aria-describedby="input-nome"
                      placeholder="Informe aqui o nome do contato"
                      v-model="partner.fullname"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-text" class="form-label"
                      >Nome da mãe</label
                    >
                    <input
                      type="text"
                      id="input-mae"
                      class="form-control"
                      aria-describedby="input-mae"
                      placeholder="Informe aqui o nome da mãe"
                      v-model="partner.mother_name"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-tel" class="form-label"
                      >Nome do pai</label
                    >
                    <input
                      type="text"
                      id="input-pai"
                      class="form-control"
                      aria-describedby="input-pai"
                      placeholder="Informe aqui o nome do pai"
                      v-model="partner.father_name"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-tel" class="form-label"
                      >Data de nascimento</label
                    >
                    <input
                      type="date"
                      id="input-birth"
                      class="form-control"
                      aria-describedby="input-birth"
                      placeholder="Informe aqui a data de nascimento"
                      v-model="partner.date_birth"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-nacionalidade" class="form-label"
                      >Naturalidade</label
                    >
                    <input
                      type="text"
                      id="input-nascionalidade"
                      class="form-control"
                      aria-describedby="input-nascionalidade"
                      placeholder="Informe aqui a naturalidade"
                      v-model="partner.birth_city"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-ethnicity" class="form-label"
                      >Etnia</label
                    >
                    <base-select
                      :options="etnias"
                      title="etnia"
                      :show_title="false"
                      @update-selected="partner.ethnicity = $event"
                      aria-describedby="input-ethnicity"
                      id="input-ethnicity"
                      v-model="partner.ethnicity"
                      :selected_id="partner.ethnicity"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-gender" class="form-label">Gênero</label>
                    <base-select
                      :options="genders"
                      title="gênero"
                      :show_title="false"
                      @update-selected="partner.gender = $event"
                      aria-describedby="input-gender"
                      id="input-gender"
                      v-model="partner.gender"
                      :selected_id="partner.gender"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-profissao" class="form-label"
                      >Profissão</label
                    >
                    <input
                      type="text"
                      id="input-profissao"
                      class="form-control"
                      aria-describedby="input-profissao"
                      placeholder="Informe aqui a Profissão"
                      v-model="partner.profission"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-2">
                    <label for="input-status_civil" class="form-label"
                      >Estado civil</label
                    >
                    <base-select
                      :options="status_civiles"
                      title="estado civil"
                      :show_title="false"
                      @update-selected="partner.status_civil = $event"
                      aria-describedby="input-status_civil"
                      id="input-status_civil"
                      v-model="partner.status_civil"
                      :selected_id="partner.status_civil"
                    />
                  </div>
                  <div
                    class="col-lg-6 col-sm-12 text-primary mb-2"
                    v-show="partner.status_civil === 2"
                  >
                    <label for="input-gender" class="form-label"
                      >Regime de bens</label
                    >
                    <base-select
                      :options="property_regimes"
                      title="regime de bens"
                      :show_title="false"
                      @update-selected="
                        partner.marital_property_systems = $event
                      "
                      aria-describedby="input-marital_property_systems"
                      id="input-marital_property_systems"
                      v-model="partner.marital_property_systems"
                      :selected_id="partner.marital_property_systems"
                    />
                  </div>

                  <hr class="my-4" />
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-rg" class="form-label"
                      >Registro Geral</label
                    >
                    <input
                      type="text"
                      id="input-rg"
                      class="form-control"
                      aria-describedby="input-rg"
                      placeholder="Informe aqui o registro geral"
                      v-model="partner.rg_number"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-dt-oe" class="form-label"
                      >Data de expedição</label
                    >
                    <input
                      type="date"
                      id="input-birth"
                      class="form-control"
                      aria-describedby="input-birth"
                      placeholder="Informe aqui a data de nascimento"
                      v-model="partner.rg_date"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-2">
                    <label for="input-oe" class="form-label"
                      >Órgão Expedidor</label
                    >
                    <input
                      type="text"
                      id="input-oe"
                      class="form-control"
                      aria-describedby="input-oe"
                      placeholder="Informe aqui o órgão expedidor"
                      v-model="partner.rg_emitter"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-2">
                    <label for="input-oe" class="form-label">UF</label>
                    <input
                      type="text"
                      id="input-oe"
                      class="form-control"
                      aria-describedby="input-oe"
                      placeholder="Informe aqui o estado de emissão"
                      v-model="partner.rg_uf"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card p-4 mt-4 text-start">
              <div class="p-2">
                <div class="row">
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-tel" class="form-label">Telefone</label>
                    <input
                      type="text"
                      id="input-tel"
                      class="form-control"
                      aria-describedby="input-tel"
                      placeholder="Informe aqui o telefone"
                      v-model="partner.phone"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-2">
                    <label for="input-tel" class="form-label">Email</label>
                    <input
                      type="text"
                      id="input-email"
                      class="form-control"
                      aria-describedby="input-email"
                      placeholder="Informe aqui o email"
                      v-model="partner.email"
                    />
                  </div>
                  <hr class="my-4" />
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-tel" class="form-label"
                      >Número do NIT/PIS</label
                    >
                    <input
                      type="text"
                      id="input-birth"
                      class="form-control"
                      aria-describedby="input-birth"
                      placeholder="Informe aqui o número do NIT ou PIS"
                      v-model="partner.pis_number"
                    />
                  </div>
                  <hr class="my-4" />
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-tel" class="form-label"
                      >Título de eleitor</label
                    >
                    <input
                      type="text"
                      id="input-te"
                      class="form-control"
                      aria-describedby="input-te"
                      placeholder="Informe aqui o número do título de eleitor"
                      v-model="partner.voter_number"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-tel" class="form-label">Zona</label>
                    <input
                      type="text"
                      id="input-te"
                      class="form-control"
                      aria-describedby="input-te"
                      placeholder="Informe aqui o número da zona"
                      v-model="partner.voter_zone"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-2">
                    <label for="input-tel" class="form-label">Seção</label>
                    <input
                      type="text"
                      id="input-te"
                      class="form-control"
                      aria-describedby="input-te"
                      placeholder="Informe aqui o número da seção"
                      v-model="partner.voter_section"
                    />
                  </div>
                  <hr class="my-4" />
                  <div class="col-lg-6 col-sm-12 text-primary">
                    <label for="input-irpf" class="form-label"
                      >Recibo do IRPF</label
                    >
                    <input
                      type="text"
                      id="input-irpf"
                      class="form-control"
                      aria-describedby="input-irpf"
                      :disabled="switch_irpf"
                      v-model="partner.number_receita"
                      :placeholder="
                        !switch_irpf
                          ? 'Informe aqui o número do recibo da última entrega de IRPF'
                          : 'N/A'
                      "
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary">
                    <label for="switch-irpf" class="form-label"
                      >Nunca declarei</label
                    >
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="switch-irpf"
                        v-model="switch_irpf"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card p-4 mt-4 text-start">
              <div class="p-2">
                <h4 class="text-gray text-disabled font-weight-light">
                  Endereço residencial completo do sócio
                </h4>
                <div class="d-flex flex-row mb-3 align-items-center">
                  <div class="row me-2">
                    <label for="input-cep p-0" class="form-label"
                      >Qual o CEP?</label
                    >
                    <input
                      type="text"
                      id="input-cep"
                      class="form-control mx-3"
                      aria-describedby="input-cep"
                      placeholder="_ _ _ _ _ - _ _ _"
                      v-model="andress.postal_code"
                    />
                  </div>
                  <div class="mt-4 ms-4">
                    <button
                      @click="getCep('model')"
                      class="btn font-weight-bold btn-primary text-white btn-shadow"
                    >
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-rua" class="form-label"
                      >Qual a rua?</label
                    >
                    <input
                      type="text"
                      id="input-rua"
                      class="form-control"
                      aria-describedby="input-rua"
                      placeholder="Informe aqui a rua"
                      v-model="andress.street"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary">
                    <label for="input-bairro" class="form-label"
                      >Qual o bairro?</label
                    >
                    <input
                      type="text"
                      id="input-bairro"
                      class="form-control"
                      aria-describedby="input-bairro"
                      placeholder="Informe aqui o bairro"
                      v-model="andress.neighborhood"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-cidade" class="form-label"
                      >Qual a cidade?</label
                    >
                    <input
                      type="text"
                      id="input-cidade"
                      class="form-control"
                      aria-describedby="input-cidade"
                      placeholder="Informe aqui a cidade"
                      v-model="andress.city"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-estado" class="form-label"
                      >Qual o estado?</label
                    >
                    <input
                      type="text"
                      id="input-estado"
                      class="form-control"
                      aria-describedby="input-estado"
                      placeholder="Informe aqui o estado"
                      v-model="andress.uf"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-nro" class="form-label"
                      >Qual o número?<span
                        v-tooltip="'Informe apenas o número, sem letras'"
                        ><i class="fas fa-info-circle ms-2"></i> </span
                    ></label>
                    <input
                      type="text"
                      id="input-nro"
                      class="form-control"
                      aria-describedby="input-nro"
                      placeholder="Informe aqui a nro"
                      v-model="andress.number"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-complemento" class="form-label"
                      >Complemento</label
                    >
                    <input
                      type="text"
                      id="input-complemento"
                      class="form-control"
                      aria-describedby="input-complemento"
                      placeholder="Se houver, informe o complemento"
                      v-model="andress.complement"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card mt-4 cursor-auto">
              <div class="d-flex justify-content-center">
                <span
                  class="mb-4 rounded-circle shadow mt-n5"
                  style="width: min-content; padding: 2rem"
                >
                  <i class="fa fa-id-card-clip fa-5x text-secondary"></i>
                </span>
              </div>
              <div class="row text-center">
                <div class="col-12 h4">
                  <b>{{ partner.fullname }}</b>
                </div>
                <div class="col-12">
                  <b>CPF:</b> {{ partner.cpf || "Não informado" }}
                </div>
                <div class="col-12">
                  <b>Email:</b>
                  {{ partner.email || "Não informado" }}
                </div>
                <div class="col-12">
                  <b>Telefone:</b> {{ partner.phone || "Não informado" }}
                </div>
                <div class="col-12">
                  <b>Cargo:</b>
                  {{ partner.company_function || "Não informado" }}
                </div>
                <span class="d-flex justify-content-center">
                  <hr class="my-4" style="width: 90%" />
                </span>
                <div class="text-center">
                  <button
                    @click="submmitForm()"
                    class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
                  >
                    <span class="mx-4">Salvar</span>
                  </button>
                  <!-- <span class="col-3" v-tooltip="'Remover'">
                    <button
                      class="btn btn-light shadow-sm"
                      :disabled="true"
                      @click="
                        $router.push(`/socios/editar/${partner.id}`)
                      "
                    >
                      <i class="fas fa-trash ml-2 text-danger"></i>
                    </button>
                  </span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import BaseSelect from "@/components/BaseSelect.vue";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "employees-form",
  components: {
    BaseSelect,
  },
  data() {
    return {
      status_civiles: [
        "Solteiro(a)",
        "Casado(a)",
        "Viúvo(a)",
        "Separado(a)",
        "Divorciado(a)",
      ],
      property_regimes: [
        "Comunhão Parcial de Bens",
        "Comunhão Universal de Bens",
        "Separação Legal de Bens",
        "Participação Final nos Aquestos",
      ],
      switch_irpf: false,
      partner: "",
      andress: "",
      genders: ["Feminino", "Masculino", "Outro"],
      etnias: [
        "Pardo(a)",
        "Branco(a)",
        "Negro(a)",
        "Indígena(a)",
        "Amarelo(a)",
        "Prefiro não informar",
      ],
      contracts: [
        "Não é contrato de expência",
        "30 dias",
        "45 dias + 45 dias",
        "60 dias",
        "90 dias",
        "30 dias + 60 dias",
        "60 dias + 30 dias",
        "90 dias",
      ],
      work_schedule: {
        times: [],
      },
      week_days: [
        "Domingo",
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado",
      ],
      andress_template: {
        postal_code: "", // on table andresses
        street: "", // on table andresses
        neighborhood: "", // on table andresses
        city: "", // on table andresses
        uf: "", // on table andresses
        number: "", // on table andresses
        complement: "", // on table andresses,
      },
      loaderActive: false,
    };
  },
  async mounted() {
    await this.loadEmployee();
  },
  methods: {
    showLoader() {
      this.loaderActive = !this.loaderActive;
    },
    formatDate(date) {
      if (date) {
        const newDate = new Date(date);
        const year = newDate.getUTCFullYear();
        let day = newDate.getUTCDate();
        let month = newDate.getUTCMonth() + 1;
        if (month < "10") {
          month = "0" + month;
        }
        if (day < "10") {
          day = "0" + day;
        }
        //yyyy-MM-dd
        return `${year}-${month}-${day}`;
      }
    },
    async submmitForm() {
      try {
        const formatedData = {
          ...this.partner,
          ...this.andress,
        };
        if (formatedData.marital_property_systems) {
          formatedData.marital_property_systems =
            this.property_regimes[formatedData.marital_property_systems - 1];
        }
        const { data } = await api.put(
          `/company/partners/${this.$route.params.id}`,
          formatedData
        );
        this.partner = data;
        toast.success("Sócio atualizado com sucesso");
        this.$router.push("/cliente/socios");
      } catch (e) {
        let message =
          "Ocorreu um erro ao salvar o sócio. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async getCep() {
      const { data } = await api.get(
        `https://viacep.com.br/ws/${this.andress.postal_code}/json/`
      );
      this.andress.street = data.logradouro;
      this.andress.neighborhood = data.bairro;
      this.andress.city = data.localidade;
      this.andress.uf = data.uf;
      this.andress.city_code = data.ibge;
    },
    async loadEmployee() {
      try {
        this.showLoader();
        const { data } = await api.get(
          `/company/partners/${this.$route.params.id}`
        );
        data.date_birth = this.formatDate(data.date_birth);
        data.rg_date = this.formatDate(data.rg_date);
        if (data.marital_property_systems) {
          data.marital_property_systems =
            this.property_regimes.findIndex((regime) => {
              return regime === data.marital_property_systems;
            }) + 1;
        }
        this.partner = data;
        this.andress = data.andress ? data.andress : this.andress_template;
        this.showLoader();
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações do sócio. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
  },
};
</script>
<style scoped>
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
.rounded-circle {
  background-color: rgb(2, 0, 125);
}
</style>
