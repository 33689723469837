<template>
  <div class="container">
    <div class="row pt-2 text-start">
      <span class="text-primary h5 d-flex align-items-center">
        <a
          href="https://www.contdigital.com.br/"
          class="btn font-weight-bold btn-primary text-white btn-shadow me-2"
        >
          <i class="fa fa-arrow-left"></i>
        </a>
        <span class="text-gray"> Voltar para o site</span>
      </span>
    </div>
    <div class="row mt-2">
      <!-- Login -->
      <div v-if="view_register === 0 && needsTwoFactor">
        <div class="d-flex justify-content-center">
          <form @submit="handleLogin" class="card w-50 col-12 text-center">
            <div>
              <h4 class="text-gray font-weight-light" for="input-reason">
                Autenticação
              </h4>
              <div class="row text-start">
                <div class="col-lg-12 text-primary mb-4">
                  <label for="input-faToken" class="form-label"
                    >Informe o código de verificação disponível no seu
                    aplicativo de autenticação cadastrado.</label
                  >
                  <input
                    type="text"
                    id="input-faToken"
                    class="form-control"
                    aria-describedby="input-faToken"
                    placeholder="Informe aqui o código"
                    v-model="faToken"
                  />
                </div>
              </div>
            </div>
            <span class="text-center mt-4">
              <button
                type="submit"
                @click="handleLogin()"
                class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
              >
                <span class="mx-4">Validar</span>
              </button>
            </span>
          </form>
        </div>
      </div>
      <div v-else-if="view_register === 0">
        <div class="d-flex justify-content-center">
          <form @submit="handleLogin" class="card w-50 col-12 text-center">
            <div>
              <h4 class="text-gray font-weight-light" for="input-reason">
                Login
              </h4>
              <div class="row text-start">
                <div class="col-lg-12 text-primary mb-4">
                  <label for="input-mail" class="form-label">Email</label>
                  <input
                    type="text"
                    id="input-mail"
                    class="form-control"
                    aria-describedby="input-mail"
                    placeholder="Informe aqui o email"
                    v-model="model.email"
                  />
                </div>
                <div class="col-lg-12 text-primary mb-4">
                  <label for="input-pass" class="form-label">Senha</label>
                  <div class="input-group mb-3">
                    <input
                      :type="showPassword ? 'text' : 'password'"
                      id="input-pass"
                      class="form-control"
                      aria-describedby="input-pass"
                      placeholder="Informe aqui a senha"
                      v-model="model.password"
                    />
                    <span
                      v-show="
                        !showPassword ? 'Visualizar senha' : 'Ocultar senha'
                      "
                    >
                      <button
                        class="btn"
                        :class="{
                          'btn-outline-primary': !showPassword,
                          'btn-primary': showPassword,
                        }"
                        type="button"
                        id="button-addon"
                        style="
                          border-radius: 0px 10px 10px 0px;
                          border-color: #ced4da;
                        "
                        @click="showPassword = !showPassword"
                      >
                        <i class="fas fa-eye"></i></button
                    ></span>
                  </div>
                </div>
              </div>
            </div>
            <span class="text-center mt-4">
              <button
                type="submit"
                @click="handleLogin()"
                class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
              >
                <span class="mx-4">Entrar</span>
              </button>
              <p class="mt-3">
                <a
                  href="/esqueci-minha-senha"
                  @click="view_register = 2"
                  class="link-button"
                  ><b>Esqueci minha senha</b></a
                >
              </p>
              <p class="mt-3">
                Não tem conta?
                <a
                  href="/cadastrar"
                  @click="view_register = 1"
                  class="link-button"
                  ><b>Cadastre-se aqui.</b></a
                >
              </p>
            </span>
          </form>
        </div>
      </div>
      <div v-else-if="view_register === 1">
        <div class="d-flex justify-content-center">
          <!-- Register -->
          <div class="card w-50 col-12">
            <div>
              <h4
                class="text-gray font-weight-light text-center"
                for="input-reason"
              >
                Cadastro
              </h4>
              <div class="row text-start">
                <div class="col-lg-12 text-primary mb-4">
                  <label for="input-name" class="form-label">Nome</label>
                  <input
                    type="text"
                    id="input-name"
                    class="form-control"
                    aria-describedby="input-name"
                    placeholder="Informe aqui o seu nome"
                    v-model="model.name"
                  />
                </div>
                <div class="col-lg-12 text-primary mb-4">
                  <label for="input-mail" class="form-label">Email</label>
                  <input
                    type="text"
                    id="input-mail"
                    class="form-control"
                    aria-describedby="input-mail"
                    placeholder="Informe aqui o email"
                    v-model="model.email"
                  />
                </div>

                <div class="col-lg-12 text-primary mb-4">
                  <label for="input-pass" class="form-label">Senha</label>
                  <div class="input-group mb-3">
                    <input
                      :type="showPassword ? 'text' : 'password'"
                      id="input-pass"
                      class="form-control"
                      aria-describedby="input-pass"
                      placeholder="Informe aqui a senha"
                      v-model="model.password"
                    />
                    <span
                      v-show="
                        !showPassword ? 'Visualizar senha' : 'Ocultar senha'
                      "
                    >
                      <button
                        class="btn"
                        :class="{
                          'btn-outline-primary': !showPassword,
                          'btn-primary': showPassword,
                        }"
                        type="button"
                        id="button-addon"
                        style="
                          border-radius: 0px 10px 10px 0px;
                          border-color: #ced4da;
                        "
                        @click="showPassword = !showPassword"
                      >
                        <i class="fas fa-eye"></i></button
                    ></span>
                  </div>
                </div>
                <div class="col-12 text-primary mb-4">
                  <label for="input-pass-c" class="form-label"
                    >Confirmação de senha</label
                  >
                  <div class="input-group mb-3">
                    <input
                      :type="showConfirmationPassword ? 'text' : 'password'"
                      id="input-pass-c"
                      class="form-control"
                      aria-describedby="input-pass-c"
                      placeholder="Confirme aqui a sua senha"
                      v-model="model.password_confirm"
                    />
                    <span
                      v-show="
                        !showConfirmationPassword
                          ? 'Visualizar senha'
                          : 'Ocultar senha'
                      "
                    >
                      <button
                        class="btn"
                        :class="{
                          'btn-outline-primary': !showConfirmationPassword,
                          'btn-primary': showConfirmationPassword,
                        }"
                        type="button"
                        id="button-addon"
                        style="
                          border-radius: 0px 10px 10px 0px;
                          border-color: #ced4da;
                        "
                        @click="
                          showConfirmationPassword = !showConfirmationPassword
                        "
                      >
                        <i class="fas fa-eye"></i></button
                    ></span>
                  </div>
                  <p v-show="!isValidPassword" class="text-danger pt-2">
                    <i class="fas fa-warning text-danger"></i>
                    *As senhas precisam coincidir
                  </p>
                  <p v-show="!isValidCharPassword" class="text-danger pt-2">
                    <i class="fas fa-warning text-danger"></i>
                    *As senhas precisam conter ao menos 1 letra maiúscula, 1
                    caractere especial e 1 número
                  </p>
                  <p v-show="!isValidCharPassword" class="text-danger pt-2">
                    <i class="fas fa-warning text-danger"></i>
                    *As senhas precisam conter ao menos 8 caracteres
                  </p>
                </div>
                <div class="col-12 text-primary mb-4">
                  <div class="form-check">
                    <input
                      class="form-check-input mt-2"
                      type="checkbox"
                      id="check_policy"
                      v-model="model.agreeded_terms_of_use"
                    />
                    <label class="form-check-label" for="check_policy">
                      Declaro que li e aceito os
                      <span
                        v-tooltip="
                          'Ver Termos e Condições de Uso da ContDigital'
                        "
                      >
                        <a
                          href="https://contdigital.com.br/termos-de-uso/"
                          target="_blank"
                          class="text-decoration-underline"
                          >Termos e Condições de Uso da ContDigital</a
                        >
                      </span>
                    </label>
                  </div>
                </div>
                <div class="col-12 text-primary">
                  <div class="form-check">
                    <input
                      class="form-check-input mt-2"
                      type="checkbox"
                      id="check_policy"
                      v-model="model.agreeded_privacy_policy"
                    />
                    <label class="form-check-label" for="check_policy">
                      Li e compreendi o
                      <span
                        v-tooltip="'Ver Aviso de Privacidade da ContDigital'"
                      >
                        <a
                          href="https://contdigital.com.br/aviso-de-privacidade/"
                          target="_blank"
                          class="text-decoration-underline"
                          >Aviso de Privacidade da ContDigital</a
                        >
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <span class="text-center mt-4">
              <span
                v-tooltip="
                  isValidModel
                    ? 'Clique para cadastrar'
                    : 'Preencha todas as informações obrigatórias (marcadas com *) antes de continuar'
                "
              >
                <button
                  :disabled="!isValidModel"
                  @click="handleRegister()"
                  type="submit"
                  class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
                >
                  <span class="mx-4">Cadastrar</span>
                </button>
              </span>
              <p class="mt-3">
                Já tem conta?
                <a href="/entrar" @click="view_register = 0" class="link-button"
                  ><b>Entrar.</b></a
                >
              </p>
            </span>
          </div>
        </div>
      </div>
      <div v-else-if="view_register === 2">
        <div class="d-flex justify-content-center">
          <!-- Register -->
          <div class="card w-50 col-12">
            <div>
              <h4
                class="text-gray font-weight-light text-center"
                for="input-reason"
              >
                Redefinir senha
              </h4>
              <div class="row text-start">
                <div class="col-lg-12 text-primary mb-4">
                  <label for="input-mail" class="form-label">Email</label>
                  <input
                    type="text"
                    id="input-mail"
                    class="form-control"
                    aria-describedby="input-mail"
                    placeholder="Informe aqui o email"
                    v-model="model.email"
                  />
                </div>
              </div>
            </div>
            <span class="text-center mt-4">
              <span
                v-tooltip="'Clique para enviar email de redefinição de senha'"
              >
                <button
                  class="btn btn-primary text-white btn-shadow me-2 px-4"
                  @click="handleRedefinePassword()"
                  :disabled="isDisabled"
                >
                  <i class="fa-solid fa-paper-plane me-1"></i> Enviar
                  {{ countDown > 0 ? `(Aguarde ${countDown}s)` : "" }}
                </button></span
              >
              <p class="mt-3">
                Voltar para o
                <a href="/entrar" @click="view_register = 0" class="link-button"
                  ><b>login.</b></a
                >
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import { login } from "@/services/auth";
import { useToast } from "vue-toastification";
import { getRole } from "@/services/auth";
const toast = useToast();

export default {
  name: "user-register",
  data() {
    return {
      /* View_register: 0 - Login (false), 1 - Register (true), 2 - Redefine Password */
      view_register: 0,
      showPassword: false,
      showConfirmationPassword: false,
      model: {
        email: "",
        name: "",
        password: "",
        password_confirm: "",
        agreeded_privacy_policy: false,
        agreeded_terms_of_use: false,
      },
      requiredFields: [
        "email",
        "name",
        "password",
        "agreeded_privacy_policy",
        "agreeded_terms_of_use",
      ],
      roles: {
        1: "suporte",
        2: "contador",
        3: "cliente",
      },
      user: {},
      needsTwoFactor: false,
      userId: null,
      faToken: "",
      isDisabled: false,
      countDown: 0,
    };
  },
  async mounted() {
    if (this.$route.path === "/entrar") this.view_register = 0;
    else if (this.$route.path === "/cadastrar") this.view_register = 1;
    else this.view_register = 2;
    const role = getRole();
    if (parseInt(role) === 3) {
      // getUser
      await this.loadUser();
      if (!this.user.companies.length) {
        this.$router.push(`/cliente`);
      } else this.$router.push(`/cliente/dashboard`);
    } else if (parseInt(role) === 2) {
      this.$router.push(`/dashboard`);
    }
  },
  methods: {
    async loadUser() {
      try {
        const { data } = await api.get(`/company/user`);
        this.user = data.user;
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações do usuário. Faça login novamente para continuar.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
        this.$router.push("/entrar");
      }
    },
    async handleRedefinePassword() {
      try {
        this.isDisabled = true;
        await api.post("reset-password/email", { email: this.model.email });
        toast.success("Email de redefinição de senha enviado com sucesso!");
      } catch (e) {
        let message =
          "Ocorreu um erro ao enviar o email de redefinição de senha";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      } finally {
        this.countDown = 10;
        const interval = setInterval(() => {
          if (this.countDown > 0) {
            this.countDown--;
          } else {
            this.isDisabled = false;
            clearInterval(interval); // Para o intervalo quando chegar a 0
          }
        }, 1000);
      }
    },
    async handleRegister() {
      try {
        const userData = {
          email: this.model.email,
          name: this.model.name,
          password: this.model.password,
          agreeded_privacy_policy: this.model.agreeded_privacy_policy,
          agreeded_terms_of_use: this.model.agreeded_terms_of_use,
        };
        await api.post("register", userData);
        toast.success("Usuário cadastrado com sucesso!");
        this.view_register = 0;
      } catch (e) {
        let message = "Ocorreu um erro ao cadastrar o usuário";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async handleLogin(event) {
      event.preventDefault();
      try {
        const { data } = await api.post("sessions", {
          email: this.model.email,
          password: this.model.password,
          userId: this.userId,
          token: this.faToken,
        });
        if (data.message === "2FA_REQUIRED") {
          this.needsTwoFactor = true;
          this.userId = data.userId;
          this.errorMessage = "Por favor, insira o código de verificação.";
        } else {
          login(data.auth.token, data.role, data.user_id);
          toast.success("Login realizado com sucesso!");
          if (data.role === 3) {
            if (data.user.default_company) {
              // se já cadastrou empresa
              if (data.user.default_company.company.pending) {
                // se não finalizou o cadastro, redirect p/ tela de cadastro
                this.$router.push(`/cliente`);
              } else {
                // se finalizou, redirect p/ dashboard
                this.$router.push(`/cliente/dashboard`);
              }
            } else {
              // se não tem nenhuma empresa cadastrada, redirect p/ tela de cadastro
              this.$router.push(`/cliente`);
            }
          } else if (data.role === 2) {
            this.$router.push(`/dashboard`);
          }
        }
      } catch (e) {
        //console.log("ERROR", e);
        let message = "Ocorreu um erro ao logar o usuário";
        if (e.response.data.message) message = e.response.data.message;
        if (e.response.data.customMessage)
          message = e.response.data.customMessage;
        toast.error(message);
      }
    },
  },
  computed: {
    isValidCharPassword() {
      const pass = this.model.password;
      const hasNum = /\d/.test(pass);
      const hasChar = /[!@#$%^&*(),.?":{}|<>]/.test(pass);
      const hasCap = /[A-Z]/.test(pass);
      const hasMinLength = pass.length >= 8;
      return hasNum && hasChar && hasCap && hasMinLength;
    },
    isValidPassword() {
      return this.model.password === this.model.password_confirm;
    },
    isValidModel() {
      const data = this.model;
      for (const key of this.requiredFields) {
        if (!data[key]) return false;
      }
      return true;
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}
.card {
  border-radius: 20px;
  padding: 40px;
}
.btn {
  border-radius: 40px;
}
.text-gray {
  color: #545454;
}
.sign-button {
  width: 35%;
}
.bold {
  font-weight: bold;
}
.rounded {
  border-radius: 20px;
}
.desc {
  font-size: 22px;
}
.link-button {
  cursor: pointer;
}
</style>
