<template>
  <div>
    <div>
      <div class="body p-4">
        <div class="text-start">
          <h4 class="row text-capitalize">
            <b>Folhas de Pagamento</b>
          </h4>
        </div>
        <!-- Folhas de Pagamento -->
        <div class="d-flex justify-content-center mt-4" v-if="loaderActive">
          <div class="spinner-border text-primary spinner-border" role="status">
            <span class="visually-hidden">Aguarde...</span>
          </div>
        </div>
        <div class="card p-4 mt-4 text-start">
          <h4>Buscar</h4>
          <div class="row">
            <div class="col-lg-2 col-sm-5">
              <select-input
                :options="months"
                title="mês"
                :pre-selected-id="new Date().getMonth()"
                v-on:update-selected="(e) => updateMonth(e)"
              />
            </div>
            <div class="col-lg-2 col-sm-5">
              <select-input
                title="ano"
                :year="true"
                v-on:update-selected="(e) => updateYear(e)"
              />
            </div>
          </div>
        </div>

        <div class="card p-3 mt-4 text-start" v-if="!payments.length">
          Não encontramos Folhas de Pagamento cadastradas para esse mês ({{
            months[month]
          }})
        </div>
        <div class="card text-start p-2" v-else>
          <div class="collection text-start m-0">
            <div
              class="collection-item relative item-title"
              v-for="(item, d_index) in payments"
              :key="d_index"
            >
              <p class="collection-title d-flex">
                <span class="me-auto">
                  <span
                    class="badge rounded-pill bg-success px-3 me-2"
                    v-if="item.is_paid"
                  >
                    <i class="fa-solid fa-thumbs-up pe-1"></i> Pago
                  </span>
                  <span class="badge rounded-pill bg-danger px-3 me-2" v-else>
                    <i class="fa-solid fa-thumbs-down pe-1"></i> Não pago
                  </span>
                  <span class="">
                    <b>{{ item.employee.fullname }}</b> -
                    {{ formatDate(item.date_due) }} - R$ {{ item.price || "-" }}
                  </span>
                </span>
                <span
                  class="mx-2"
                  v-tooltip="'Ver guia de pagamento'"
                  @click="handleViewFile(item.id, 'payments')"
                >
                  <button class="btn btn-light shadow-sm">
                    <i class="fas fa-eye text-info"></i> Documento
                  </button>
                </span>
                <span
                  class="mx-2"
                  v-tooltip="'Ver comprovante de pagamento'"
                  @click="handleViewFile(item.id, 'payments-paid')"
                  v-if="item.is_paid && item.document_payment_proof"
                >
                  <button class="btn btn-light shadow-sm">
                    <i class="fas fa-eye text-success"></i> Comprovante
                  </button>
                </span>
                <span
                  class="mx-2"
                  v-tooltip="'Anexar comprovante de pagamento'"
                  @click="handleUploadFile(item)"
                  v-else
                >
                  <button class="btn btn-light shadow-sm">
                    <i class="fas fa-upload text-success"></i> Anexar
                    comprovante
                  </button>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <modal-add-payment-proof
        :modal="modalPayment"
        v-on:updateData="(e) => (modalPayment.data = e)"
        v-on:updateShow="(e) => (modalPayment.show = e)"
      />
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import ModalAddPaymentProof from "@/components/Modals/ModalAddPaymentProof";
import SelectInput from "@/components/SelectInput.vue";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "payments-modal",
  components: {
    SelectInput,
    ModalAddPaymentProof,
  },
  data() {
    return {
      month: "",
      year: "",
      payments: [],
      months: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      modalPayment: {
        show: false,
        title: "",
        subtitle: "",
        description: "",
        data: "",
        add_document: true,
        url: "payments",
      },
      loaderActive: false,
    };
  },
  mounted() {
    this.month = new Date().getMonth();
    this.year = new Date().getFullYear();
  },
  methods: {
    showLoader() {
      this.loaderActive = !this.loaderActive;
    },
    handleUploadFile(data) {
      this.modalPayment.data = data;
      this.modalPayment.title = "Folhas de pagamento";
      this.modalPayment.subtitle = "Anexar comprovante de pagamento da folha";
      this.modalPayment.description =
        "Insira o arquivo comprobatório e preencha as informações solicitadas:";
      this.modalPayment.show = true;
    },
    formatDate(date) {
      if (date) {
        const newDate = new Date(date);
        const year = newDate.getUTCFullYear();
        let day = newDate.getUTCDate();
        let month = newDate.getUTCMonth() + 1;
        if (month < "10") {
          month = "0" + month;
        }
        if (day < "10") {
          day = "0" + day;
        }
        //dd-MM-yyyy
        return `${day}/${month}/${year}`;
      } else {
        return "Data não informada";
      }
    },
    async handleViewFile(id, functionality) {
      try {
        const { data } = await api.get(
          `/company/document/${id}?functionality=${functionality}&functionality_row_id=${id}`
        );
        window.open(`${data.public_url}`, "_blank");
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar o documento. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async loadPayments() {
      try {
        this.showLoader();
        const { data } = await api.get(`/company/payments${this.getUrl()}`);
        this.payments = data;
        this.showLoader();
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações das Folhas de Pagamento. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    updateMonth(e) {
      this.month = e;
    },
    updateYear(e) {
      this.year = e;
    },
    getUrl() {
      let url = "?type=employee";
      const month = this.month + 1;
      if (month && this.year) url += `&month=${month}&year=${this.year}`;
      return url;
    },
  },
  watch: {
    "modalPayment.show": async function () {
      if (!this.modalPayment.show) {
        await this.loadPayments();
      }
    },
    month: async function () {
      await this.loadPayments();
    },
    year: async function () {
      await this.loadPayments();
    },
  },
};
</script>
<style scoped>
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
</style>
