<template>
  <div>
    <div>
      <div class="body p-4">
        <div class="text-start">
          <h4 class="row">
            <b>Pagamentos e Despesas</b>
          </h4>
        </div>
        <!-- Pagamentos e despesas -->
        <div class="d-flex justify-content-center mt-4" v-if="loaderActive">
          <div class="spinner-border text-primary spinner-border" role="status">
            <span class="visually-hidden">Aguarde...</span>
          </div>
        </div>
        <div class="card p-4 mt-4 text-start">
          <h4>Buscar</h4>
          <div class="row">
            <div class="col-lg-2 col-sm-5">
              <select-input
                :options="mounths"
                title="mês"
                :pre-selected-id="new Date().getMonth()"
                v-on:update-selected="(e) => updateMonth(e)"
              />
            </div>
            <div class="col-lg-2 col-sm-5">
              <select-input
                title="ano"
                :year="true"
                v-on:update-selected="(e) => updateYear(e)"
              />
            </div>
          </div>
        </div>
        <div class="text-end">
          <button
            @click="$router.push('/cliente/pagamentos-e-despesas/novo')"
            class="px-3 btn text-uppercase font-weight-light btn-secondary text-white btn-shadow mb-2 btn-sm"
          >
            <i class="fas fa-plus me-2"></i>Cadastrar novo(a)
          </button>
        </div>
        <div class="card p-3 mt-4 text-start" v-if="!expenses.length">
          Não encontramos pagamentos e despesas cadastradas para esse mês ({{
            mounths[month]
          }})
        </div>
        <div class="card text-start p-2" v-else>
          <div class="collection text-start m-0">
            <div
              class="collection-item relative item-title"
              v-for="(item, d_index) in expenses"
              :key="d_index"
            >
              <p class="collection-title d-flex">
                <span class="me-auto">
                  <span
                    class="badge rounded-pill bg-success px-3 me-2"
                    v-if="item.is_paid"
                  >
                    <i class="fa-solid fa-thumbs-up pe-1"></i> Pago
                  </span>
                  <span class="badge rounded-pill bg-danger px-3 me-2" v-else>
                    <i class="fa-solid fa-thumbs-down pe-1"></i> Não pago
                  </span>
                  <span
                    :class="`badge rounded-pill bg-${
                      item.origin === 1 ? 'info' : 'secondary'
                    } px-3 me-2`"
                  >
                    {{ item.origin === 1 ? "Operacional" : "Não operacional" }}
                  </span>
                  <b>{{ item.name }}</b> - {{ formatDate(item.date_due) }} - R$
                  {{ item.price }}
                  <span v-if="item.is_paid">
                    | Pago em: {{ formatDate(item.payday) }} - R$ {{ item.price_paid }}
                  </span>
                </span>
                <span
                  class="mx-2"
                  v-tooltip="'Ver despesa'"
                  @click="handleViewFile(item.id, 'expenses')"
                >
                  <button class="btn btn-light shadow-sm">
                    <i class="fas fa-eye text-info"></i>
                  </button>
                </span>
                <span
                  v-if="!item.is_paid"
                  class="mx-2"
                  v-tooltip="'Marcar despesa como paga'"
                  @click="handleUploadFile(item)"
                >
                  <button class="btn btn-light shadow-sm">
                    <i class="fas fa-check-to-slot text-primary"></i>
                  </button>
                </span>
                <span class="mx-2" v-tooltip="'Remover despesa'">
                  <button
                    class="btn btn-light shadow-sm"
                    @click="deleteExpense(item.id)"
                  >
                    <i class="fas fa-trash text-danger"></i>
                  </button>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-add-payment-proof
      :modal="modalPayment"
      v-on:updateData="(e) => (modalPayment.data = e)"
      v-on:updateShow="(e) => (modalPayment.show = e)"
    />
  </div>
</template>
<script>
import api from "@/services/api";
import ModalAddPaymentProof from '@/components/Modals/ModalAddPaymentProof';
import SelectInput from "@/components/SelectInput.vue";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "expenses-modal",
  props: {
    modal: Object,
  },
  components: {
    SelectInput,
    ModalAddPaymentProof
  },
  data() {
    return {
      month: "",
      year: "2024",
      expenses: [],
      mounths: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      loaderActive: false,
      modalPayment: {
        show: false,
        title: "",
        subtitle: "",
        description: "",
        data: "",
        add_document: false,
        url: 'expenses'
      }
    };
  },
  mounted() {
    this.month = new Date().getMonth();
    this.year = new Date().getFullYear();
  },
  methods: {
    handleUploadFile(data) {
      this.modalPayment.data = data;
      this.modalPayment.title = 'Pagamentos e despesas';
      this.modalPayment.subtitle = 'Confirmar despesa como paga';
      this.modalPayment.description = 'Preencha as informações solicitadas, referentes ao pagamento:';
      this.modalPayment.show = true;
    },
    async deleteExpense(id) {
      this.showLoader();
      try {
        await api.delete(`/company/expenses/${id}`);
        this.$router.push("/cliente/pagamentos-e-despesas");
        toast.success("Pagamento/Despesa removida com sucesso!");
        await this.loadExpenses()
      } catch (e) {
        let message = "Ocorreu um erro ao remover o pagamento/despesa. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
      this.showLoader();
    },
    showLoader() {
      this.loaderActive = !this.loaderActive;
    },
    async loadExpenses() {
      try {
        this.showLoader();
        const { data } = await api.get(`/company/expenses${this.getUrl()}`);
        this.expenses = data;
        this.showLoader();
      } catch (e) {
        let message = "Ocorreu um erro ao carregar as informações dos pagamentos e despesas. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    updateMonth(e) {
      this.month = e;
    },
    updateYear(e) {
      this.year = e;
    },
    getUrl() {
      let url = "";
      const month = this.month + 1;
      if (month && this.year) url = `?month=${month}&year=${this.year}`;
      else if (month) url = `?month=${month}`;
      else if (this.year) url = `?year=${this.year}`;
      return url;
    },
    formatDate(date) {
      if (date) {
        const newDate = new Date(date);
        const year = newDate.getUTCFullYear();
        let day = newDate.getUTCDate();
        let month = newDate.getUTCMonth() + 1;
        if (month < "10") {
          month = "0" + month;
        }
        if (day < "10") {
          day = "0" + day;
        }
        //dd-MM-yyyy
        return `${day}/${month}/${year}`;
      } else {
        return "Data não informada";
      }
    },
    async handleViewFile(id, functionality) {
      try {
        const { data } = await api.get(
          `/company/document/${id}?functionality=${functionality}&functionality_row_id=${id}`
        );
        window.open(`${data.public_url}`, "_blank");
      } catch (e) {
        let message = "Ocorreu um erro ao carregar o documento. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
  },
  watch: {
    month: async function () {
      await this.loadExpenses();
    },
    year: async function () {
      await this.loadExpenses();
    },
  },
};
</script>
<style scoped>
.pointer {
  cursor: help;
}
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
</style>
