<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="card center p-4">
          <h6>Bem vindo, contador! Selecione uma das opções abaixo.</h6>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6 mb-4">
        <div class="card card-shadow h-100 center" @click="$router.push('/empresas')">
          <a href="" title="Verificar franquias">
            <div class="waves-effect" style="padding: 30px">
              <i class="fa-solid fa-building fa-5x mb-3"></i>
              <h5 class="mt-4">Empresas</h5>
            </div>
          </a>
          <p>Veja informações de seus clientes.</p>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-4">
        <div class="card card-shadow h-100 center" @click="$router.push('/chamados')">
          <a href="" title="Verificar contadores">
            <div class="waves-effect" style="padding: 30px">
              <i class="fa-solid fa-comments fa-5x mb-3"></i>
              <h5 class="mt-4">Chamados</h5>
            </div>
          </a>
          <p>Comunique-se com seus clientes por aqui.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "home-page",
};
</script>
<style scoped>
a {
  text-decoration: none;
}
</style>
