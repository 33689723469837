<template>
  <div class="select">
    <div
      class="select-overlay"
      :class="showOptions ? 'show' : 'hide'"
      @click="showOptions = false"
    ></div>
    <label class="text-muted fw-light"
      ><small class="text-capitalize">{{ title }}</small></label
    >
    <select class="form-select mb-3" id="select-input" v-model="selected">
      <option selected disabled value="">Selecione o {{ title }}</option>
      <option
        :value="title === 'mês' ? index : option"
        v-for="(option, index) in select_options"
        :key="index"
      >
        {{ option.name || option }}
      </option>
    </select>
    <!-- <ul
      :id="`${name}-dropdown-nav`"
      class="dropdown-content text-left mt-4 text-secondary p-0"
      :class="showDropdown ? 'show-dropdown' : 'hidden-dropdown'"
    >
      <li
        class="dropdown-item p-0 text-left"
        v-for="(field, index) in fields"
        :key="`${name}-dropdown-field-${index}`"
      >
        <div class="break">
          <hr v-if="field.add_hr" class="mb-3 m-0 text-muted" />
        </div>
        <span class="hoverable p-3" @click="handleMenuItemAction(field)">
          <a>
            <span class="text-center p-2 wrapp-icon"
              ><i class="item-icon" :class="field.icon"></i></span
            ><span class="ms-2"
              >{{ field.name }}
              <span
                v-if="
                  name === 'calleds-menu' && field.name === 'Ver comentários'
                "
              >
                {{ `(${data.comments})` }}</span
              ></span
            ></a
          ></span
        >
      </li>
    </ul> -->
  </div>
</template>
<script>
export default {
  name: "select-input",
  props: {
    options: Array,
    placeholder: String,
    title: String,
    preSelectedId: Number,
    year: Boolean,
  },
  data() {
    return {
      select_options: [],
      showOptions: false,
      selected: "",
    };
  },
  mounted() {
    if (this.year) {
      const years = [];
      for (let i = 2018; i <= this.getActualYear(); i++) {
        years.push(i);
      }
      this.select_options = years;
      this.selected = this.getActualYear();
    } else {
      this.select_options = this.options;
      this.selected = this.preSelectedId || this.getActualMonth();
    }
  },
  methods: {
    getActualYear() {
      return new Date().getFullYear();
    },
    getActualMonth() {
      return new Date().getMonth();
    },
  },
  watch: {
    selected: function () {
      this.$emit("updateSelected", this.selected);
    },
  },
};
</script>
<style scoped></style>
