<template>
  <div>
    <menu-top @menuReady="handleMenuReady" />
    <span
      v-if="!requiresAuth || (menuReady && authenticated)"
      class="text-center"
      ><router-view></router-view
    ></span>
  </div>
</template>

<script>
import MenuTop from "./layout/MenuTop.vue";
import api from "@/services/api";
import { isAuthenticated } from "@/services/auth";
import { logout } from "@/services/auth";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  name: "App",
  components: { MenuTop },
  data() {
    return {
      menuReady: false,
      requiresAuth: true,
      authenticated: isAuthenticated(),
      session_timer: null,
    };
  },
  mounted() {
    this.requiresAuth = this.$route.meta.requiresAuth;
    this.authenticatedMonitor();
  },
  methods: {
    authenticatedMonitor() {
      console.log("Verificando local storage");
      let prevStatus = isAuthenticated();
      setInterval(() => {
        const currStatus = isAuthenticated();
        if (currStatus !== prevStatus) {
          console.log("status mudou");
          prevStatus = currStatus;
          this.authenticated = currStatus;
          if (currStatus) {
            this.startSessionTimer();
          } else {
            clearInterval(this.session_timer);
          }
        }
      }, 500); // Verifica a cada 500ms
    },
    startSessionTimer() {
      const sessionTime = 120 * 60 * 1000; // 30 minutos 30 * 60 * 1000
      this.session_timer = setInterval(async () => {
        let message = "Sua sessão expirou, por favor, faça o login novamente.";
        toast.warning(message);
        await this.logout();
      }, sessionTime);
    },
    handleMenuReady() {
      this.menuReady = true;
    },
    async logout() {
      try {
        await api.post(`/logout`);
        this.showDropdown = false;
        this.showProfileDropdown = false;
        this.showSidebar = false;
        this.$router.push("/entrar");
        logout();
      } catch (e) {
        let message = "Não foi possível encerrar a sessão, tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.form-check-input {
  width: 4em;
  height: 2em;
}
.form-check-input[data-v-654d9cb2] {
  width: 4em;
  height: 2em;
}
.form-check-label {
  margin-top: 5px;
  margin-left: 5px;
}
</style>
