<template>
  <div>
    <div>
      <div class="body p-4">
        <div class="text-start">
          <h4 class="row text-capitalize">
            <b>Notas fiscais</b>
          </h4>
        </div>
        <!-- Notas Fiscais -->
        <div class="d-flex justify-content-center mt-4" v-if="loaderActive">
          <div class="spinner-border text-primary spinner-border" role="status">
            <span class="visually-hidden">Aguarde...</span>
          </div>
        </div>
        <div class="card p-4 mt-4 text-start">
          <h4>Buscar</h4>
          <div class="row">
            <div class="col-lg-2 col-sm-5">
              <select-input
                :options="months"
                title="mês"
                :pre-selected-id="new Date().getMonth()"
                v-on:update-selected="(e) => updateMonth(e)"
              />
            </div>
            <div class="col-lg-2 col-sm-5">
              <select-input
                title="ano"
                :year="true"
                v-on:update-selected="(e) => updateYear(e)"
              />
            </div>
          </div>
        </div>
        <div class="text-end">
          <button
            @click="$router.push('/cliente/notas-fiscais/novo')"
            class="px-3 btn text-uppercase font-weight-light btn-secondary text-white btn-shadow mb-2 btn-sm"
          >
            <i class="fas fa-plus me-2"></i>Cadastrar nova
          </button>
        </div>
        <div class="card p-3 mt-4 text-start" v-if="!invoices.length">
          Sem notas fiscais cadastradas para esse mês ({{ months[month] }})
        </div>
        <div class="card text-start p-2" v-else>
          <div class="collection text-start m-0">
            <div
              class="collection-item relative item-title"
              v-for="(item, d_index) in invoices"
              :key="d_index"
            >
              <p class="collection-title d-flex align-items-center">
                <span class="me-auto">
                  <span
                    class="badge rounded-pill bg-success px-3 me-2"
                    v-if="item.type === 1"
                  >
                    <i class="fa-solid fa-right-to-bracket pe-1"></i> Entrada
                  </span>
                  <span
                    class="badge rounded-pill bg-danger px-3 me-2"
                    v-else-if="item.type === 2"
                  >
                    <i class="fa-solid fa-right-from-bracket pe-1"></i> Saída
                  </span>
                  <span class="badge rounded-pill bg-info px-3 me-2" v-else>
                    <i class="fa-solid fa-reply pe-1"></i> Devolução
                  </span>
                  <span class="">
                    <b>{{ `#${d_index + 1} ${item.name}` || "-" }}</b> -
                    {{ item.amount }}
                  </span>
                </span>
                <span
                  class="mx-2"
                  v-tooltip="'Ver Nota Fiscal'"
                  @click="handleViewFile(item.id, 'invoices')"
                >
                  <button class="btn btn-light shadow-sm">
                    <i class="fas fa-eye text-info"></i> Nota Fiscal
                  </button>
                </span>
                <span class="mx-2" v-tooltip="'Remover Nota Fiscal'">
                  <button
                    class="btn btn-light shadow-sm"
                    @click="handleOpenModal(item)"
                  >
                    <i class="fas fa-trash text-danger"></i>
                  </button>
                </span>
                <!-- <span class="mx-2" v-tooltip="'Baixar arquivo'">
                  <button
                    class="btn btn-light shadow-sm"
                  >
                    <i class="fas fa-download text-primary"></i>
                  </button>
                </span> -->
              </p>
            </div>
          </div>
        </div>
      </div>
      <modal-confirmation
        :modal="modalConfirmation"
        v-on:confirmation="(e) => (confirmation = e)"
        v-on:updateShow="(e) => (modalConfirmation.show = e)"
      />
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import SelectInput from "@/components/SelectInput.vue";
import ModalConfirmation from "@/components/Modals/ModalConfirmation.vue";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "invoices-modal",
  props: {
    modal: Object,
  },
  components: {
    SelectInput,
    ModalConfirmation,
  },
  data() {
    return {
      month: 1,
      year: 2024,
      invoices: [],
      confirmation: false,
      modalConfirmation: {
        show: false,
        title: "",
        subtitle: "",
        description: "",
        class: "modal-warning",
        data: {},
      },
      classes: ["NFe", "NFCe", "NFSe", "outro"],
      months: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      loaderActive: false,
    };
  },
  mounted() {
    this.month = new Date().getMonth();
    this.year = new Date().getFullYear();
  },
  methods: {
    handleOpenModal(data) {
      this.modalConfirmation.data = data;
      this.modalConfirmation.title = "Opa!";
      this.modalConfirmation.subtitle =
        "Tem certeza de que deseja remover a nota fiscal?";
      this.modalConfirmation.description = `Nota Fiscal a ser excluída: ${data.name}, no valor de: ${data.amount}`;
      this.modalConfirmation.show = true;
    },
    showLoader() {
      this.loaderActive = !this.loaderActive;
    },
    async handleViewFile(id, functionality) {
      try {
        const { data } = await api.get(
          `/company/document/${id}?functionality=${functionality}&functionality_row_id=${id}`
        );
        window.open(`${data.public_url}`, "_blank");
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar o documento. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async loadInvoices() {
      try {
        this.showLoader();
        const { data } = await api.get(`/company/invoices${this.getUrl()}`);
        this.invoices = data;
        this.showLoader();
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações das notas fiscais. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async removeInvoice() {
      const invoiceId = this.modalConfirmation.data.id;
      try {
        this.showLoader();
        await api.delete(`/company/invoices/${invoiceId}`);
        await this.loadInvoices();
        this.confirmation = false;
        this.showLoader();
        toast.success("Nota Fiscal removida com sucesso!");
      } catch (e) {
        let message =
          "Ocorreu um erro ao remover a Nota Fiscal. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    updateMonth(e) {
      this.month = e;
    },
    updateYear(e) {
      this.year = e;
    },
    getUrl() {
      let url = "";
      const month = this.month + 1;
      if (month && this.year) url = `?month=${month}&year=${this.year}`;
      else if (month) url = `/?month=${month}`;
      else if (this.year) url = `/?year=${this.year}`;
      return url;
    },
  },
  watch: {
    confirmation: async function () {
      if (this.confirmation) {
        await this.removeInvoice();
      }
    },
    month: async function () {
      await this.loadInvoices();
    },
    year: async function () {
      await this.loadInvoices();
    },
  },
};
</script>
<style scoped>
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
</style>
