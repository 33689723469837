<template>
  <div>
    <div class="px-4">
      <div class="body p-4">
        <div class="d-flex justify-content-center mt-4" v-if="loaderActive">
          <div class="spinner-border text-primary spinner-border" role="status">
            <span class="visually-hidden">Aguarde...</span>
          </div>
        </div>
        <span class="text-primary h2 d-flex align-items-center">
          <button
            @click="handleGoBack()"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
          >
            <i class="fa fa-arrow-left"></i>
          </button>
          <span class="text-gray"> Editar prolabore</span>
        </span>
        <!-- prolabore -->
        <div class="card p-4 mt-4">
          <div class="p-2">
            <!-- <h4 class="text-gray font-weight-light">Novo banco</h4> -->
            <div class="row text-start">
              <div class="col-lg-6 col-md-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-0">Nome*</label>
                <input
                  type="text"
                  id="input-cod"
                  class="form-control"
                  aria-describedby="input-cod"
                  placeholder="Informe aqui o nome"
                  v-model="prolabore.name"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-3">
                <label for="input-banco" class="form-label">Sócio*</label>
                <base-select
                  :options="partners"
                  title="Sócio*"
                  :show_title="false"
                  id="input-partner"
                  @update-selected="prolabore.partner = $event"
                  aria-describedby="input-partner"
                  :selected_id="prolabore.partner"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-type" class="form-label mb-2"
                  >Data de vencimento*</label
                >
                <input
                  type="date"
                  id="input-type"
                  class="form-control"
                  aria-describedby="input-type"
                  v-model="prolabore.date_due"
                />
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 text-primary mb-4">
                <label for="input-agency" class="form-label mb-">Valor*</label>
                <input
                  type="text"
                  id="input-agency"
                  class="form-control"
                  aria-describedby="input-agency"
                  placeholder="Informe aqui o valor pago"
                  v-model="formatedPrice"
                  @input="formatPrice"
                />
              </div>

              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <div v-if="prolabore.document_paid">
                  <span class="mx-2 mt-0" v-tooltip="'Ver arquivo'">
                    <label for="input-price_paid" class="form-label mb-2"
                      >Arquivo anexado</label
                    >
                    <br />
                    <button
                      class="btn btn-primary shadow-sm"
                      @click="handleViewFile(comment.id, 'calleds')"
                    >
                      <i class="fas fa-eye"></i> Arquivo em anexo
                    </button>
                  </span>
                </div>
                <div v-else>
                  <label for="input-price" class="form-label mb-2"
                    >Arquivo*</label
                  >
                  <br />
                  <base-file
                    :value="document"
                    title="Selecione o documento"
                    accept=""
                    :mbSize="50"
                    type="secondary text-white"
                    @input-upload="document = $event"
                  />
                </div>
              </div>
            </div>
            <span
              class="text-center mt-4"
              v-tooltip="
                isValidModel
                  ? 'Clique para salvar'
                  : 'Preencha todas as informações obrigatórias (marcadas com *) antes de continuar'
              "
            >
              <button
                @click="submmitForm()"
                :disabled="!isValidModel"
                class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
              >
                <span class="mx-4">Salvar</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseFile from "@/components/BaseFile.vue";
import BaseSelect from "@/components/BaseSelect.vue";
import api from "@/services/api";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "prolabores-edit-form",
  components: {
    BaseFile,
    BaseSelect,
  },
  data() {
    return {
      month: "",
      year: "",
      partners: [],
      prolabore: {
        name: "",
        date_due: "",
        price: "",
        partner: "",
        document_paid: "",
      },
      document: "",
      doc_preview: "",
      requiredFields: ["name", "partner", "date_due", "price"],
      loaderActive: false,
    };
  },
  async mounted() {
    await this.loadPartners();
    await this.loadProlabore();
  },
  methods: {
    handleGoBack() {
      const previousUrl = localStorage.getItem("previousUrl");
      if (previousUrl) {
        this.$router.push(previousUrl);
      } else {
        this.$router.push("/");
      }
    },
    showLoader() {
      this.loaderActive = !this.loaderActive;
    },
    async loadProlabore() {
      try {
        this.showLoader();
        const { data } = await api.get(
          `/user-accountant/payments/${this.$route.params.idProlabore}`
        );
        this.prolabore.name = data.name;
        this.prolabore.price = data.price;
        this.prolabore.document_paid = data.document_paid;
        this.prolabore.date_due = this.formatDate(data.date_due);
        this.prolabore.partner = data.partner.id;
        this.showLoader();
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações do sócio. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    formatDate(date) {
      const newDate = new Date(date);
      const year = newDate.getUTCFullYear();
      let day = newDate.getUTCDate();
      let month = newDate.getUTCMonth() + 1;
      if (month < "10") {
        month = "0" + month;
      }
      if (day < "10") {
        day = "0" + day;
      }
      //yyyy-MM-dd
      return `${year}-${month}-${day}`;
    },
    formatPrice(event) {
      const value = event.target.value.replace(/[^\d]/g, "");
      this.prolabore.price = parseInt(value || "0", 10);
      event.target.value = this.formatedPrice;
      setTimeout(() => {
        event.target.setSelectionRange(
          event.target.value.length,
          event.target.value.length
        );
      });
    },
    updatePrice() {
      var options = { style: "currency", currency: "BRL" };
      var formatter = new Intl.NumberFormat("pt-BR", options);
      var result = formatter.format(this.prolabore.price);
      this.amount_preview = result;
      return result;
    },
    async submmitForm() {
      try {
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const formData = new FormData();
        for (const att of Object.getOwnPropertyNames(this.prolabore)) {
          if (this.prolabore[att]) {
            if (att === "price") {
              formData.append(att, this.prolabore[att] / 100);
            } else {
              formData.append(att, this.prolabore[att]);
            }
          }
        }
        formData.append("document", this.document);
        formData.append("document_proof", this.document_proof);
        formData.append(
          "month",
          parseInt(this.prolabore.date_due.split("-")[1])
        );
        formData.append(
          "year",
          parseInt(this.prolabore.date_due.split("-")[0])
        );
        const { data } = await api.put(
          `/user-accountant/payments/${this.$route.params.idProlabore}`,
          formData,
          config
        );
        this.prolabores = data;
        toast.success("Prolabore editado com sucesso");
        this.handleGoBack();
      } catch (e) {
        let message =
          "Ocorreu um erro ao salvar a prolabore. Verifique as informações e tente novamente";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async loadPartners() {
      try {
        const { data } = await api.get(
          `/user-accountant/partners/${this.$route.params.id}`
        );
        this.partners = data;
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações dos funcionários. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
  },
  computed: {
    formatedPrice() {
      if (!this.prolabore.price) return "";
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      })
        .format(this.prolabore.price / 100)
        .replace("R$", "")
        .trim();
    },
    isValidModel() {
      const data = this.prolabore;
      for (const key of this.requiredFields) {
        if (!data[key]) return false;
      }
      if (!this.document && !this.prolabore.document_paid) {
        return false;
      }
      return true;
    },
  },
};
</script>
<style scoped>
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
</style>
