<template>
  <div class="px-4">
    <div class="body p-4">
      <span class="text-primary h2 d-flex align-items-center mb-4">
        <button
          @click="$router.push('/empresas')"
          class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
        >
          <i class="fa fa-arrow-left"></i>
        </button>
        <span class="text-gray"> Chamados</span>
      </span>
      <div class="card p-4 m-4 text-start">
        <div>
          <button
            @click="calleds_tab = 'opened'"
            :class="
              calleds_tab === 'opened'
                ? 'btn-secondary text-white btn-shadow'
                : 'btn-muted'
            "
            class="btn text-uppercase font-weight-light"
          >
            Em aberto
          </button>
          <button
            @click="calleds_tab = 'going'"
            :class="
              calleds_tab === 'going'
                ? 'btn-secondary text-white btn-shadow'
                : 'btn-muted'
            "
            class="btn text-uppercase font-weight-light"
          >
            Em atendimento
          </button>
          <button
            @click="calleds_tab = 'closed'"
            :class="
              calleds_tab === 'closed'
                ? 'btn-secondary text-white btn-shadow'
                : 'btn-muted'
            "
            class="btn text-uppercase font-weight-light"
          >
            Encerrados
          </button>
          <button
            @click="calleds_tab = 'all'"
            :class="
              calleds_tab === 'all'
                ? 'btn-secondary text-white btn-shadow'
                : 'btn-muted'
            "
            class="btn text-uppercase font-weight-light"
          >
            Todos
          </button>
        </div>
      </div>
      <div class="text-end">
        <button
          @click="$router.push(`/chamados/${$route.params.id}/novo`)"
          class="me-4 px-3 btn text-uppercase font-weight-light btn-secondary text-white btn-shadow mb-2 btn-sm"
        >
          <i class="fas fa-plus me-2"></i>Abrir novo chamado
        </button>
      </div>
      <div class="d-flex justify-content-center mt-4" v-if="loaderActive">
        <div class="spinner-border text-primary spinner-border" role="status">
          <span class="visually-hidden">Aguarde...</span>
        </div>
      </div>
      <div class="card m-4 p-3 mt-2 text-start" v-else-if="!calleds.length">
        Sem chamados encontrados
      </div>
      <div class="card p-0 mt-2 mx-4" v-else>
        <div class="collection text-start m-0">
          <div
            class="collection-item relative"
            :class="called.show ? 'opened' : ''"
            v-for="(called, index) in calleds"
            :key="index"
          >
            <span class="collection-title d-flex align-items-center">
              <span class="me-auto">
                <span
                  class="badge rounded-pill bg-primary px-3 me-2"
                  v-if="called.status === 0"
                >
                  <i class="fa-solid fa-lock-open pe-1"></i> Em aberto
                </span>
                <span
                  class="badge rounded-pill bg-warning px-3 me-2"
                  v-else-if="called.status === 1"
                >
                  <i class="fa-solid fa-unlock pe-1"></i> Em atendimento
                </span>
                <span
                  class="badge rounded-pill bg-danger px-3 me-2"
                  v-else-if="called.status === 2"
                >
                  <i class="fa-solid fa-lock pe-1"></i> Encerrado
                </span>
                <span class="">
                  <b>#{{ called.id }}</b> - {{ called.title }}
                </span>
              </span>

              <div class="row justify-content-center">
                <span
                  class="col-3"
                  v-tooltip="
                    called.status === 0
                      ? 'O chamado já está em aberto'
                      : 'Mudar status para aberto'
                  "
                >
                  <button
                    class="btn btn-light shadow-sm"
                    :disabled="called.status === 0"
                  >
                    <i class="fa-solid fa-lock-open ml-2 text-primary"></i>
                  </button>
                </span>
                <span
                  class="col-3"
                  v-tooltip="
                    called.status === 1
                      ? 'O chamado já está em atendimento'
                      : 'Mudar status para em atendimento'
                  "
                >
                  <button
                    class="btn btn-light shadow-sm"
                    :disabled="called.status === 1"
                  >
                    <i class="fa-solid fa-unlock ml-2 text-warning"></i>
                  </button>
                </span>
                <span
                  class="col-3"
                  v-tooltip="
                    called.status === 2
                      ? 'O chamado já está encerrado'
                      : 'Mudar status para encerrado'
                  "
                >
                  <button
                    class="btn btn-light shadow-sm"
                    :disabled="called.status === 2"
                  >
                    <i class="fa-solid fa-lock ml-2 text-danger"></i>
                  </button>
                </span>
                <span class="col-3" v-tooltip="'Ver comentários'">
                  <button
                    class="btn btn-light shadow-sm"
                    @click="
                      $router.push(
                        `/chamados/${$route.params.id}/ver/${called.id}`
                      )
                    "
                  >
                    <i class="fas fa-eye ml-2 text-info"></i>
                  </button>
                </span>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "calleds-page",
  data() {
    return {
      loaderActive: false,
      calleds: [],
      calleds_tab: "opened",
      menu_fields: [],
      months: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
    };
  },
  async mounted() {
    this.handleTabData("opened");
    this.handleMenuFields();
    this.initlist();
    await this.loadCalleds(0);
  },
  methods: {
    showLoader() {
      this.loaderActive = !this.loaderActive;
    },
    async handleTabData(tab) {
      if (tab === "opened") {
        await this.loadCalleds(0);
      } else if (tab === "going") {
        await this.loadCalleds(1);
      } else if (tab === "closed") {
        await this.loadCalleds(2);
      } else {
        await this.loadCalleds(3);
      }
    },
    async loadCalleds(status) {
      this.showLoader();
      try {
        const { data } = await api.get(
          `/user-accountant/calleds/${this.$route.params.id}?status=${status}`
        );
        this.calleds = data;
        this.showLoader();
      } catch (e) {
        let message = 
          "Ocorreu um erro ao carregar as informações dos chamados. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    handleMenuFields() {
      const menu_fields = [
        {
          name: "Ver comentários",
          route: "/chamados",
          icon: "fas fa-comments",
          add_hr: false,
          type: "link",
        },
        {
          name: "Em atendimento",
          icon: "fas fa-handshake",
          add_hr: true,
          type: "link",
        },
        {
          name: "Fechar",
          icon: "fas fa-square-xmark",
          add_hr: false,
          type: "link",
        },
      ];
      this.menu_fields = menu_fields;
    },
    handleMenu(index) {
      const show = this.calleds[index].show_menu;
      this.calleds[index].show_menu = !show;
    },
    initlist() {
      const comp = [];
      this.calleds.forEach((called) => {
        comp.push({
          ...called,
          show_menu: false,
        });
      });
      this.calleds = comp;
    },
  },
  watch: {
    calleds_tab: function () {
      this.handleTabData(this.calleds_tab);
    },
  },
};
</script>
<style scoped>
.collection-item {
  background-color: transparent;
}
.collection {
  border: none;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.opened {
  border-left: 2px solid #342f86;
}
.pointer {
  cursor: pointer;
}
.collection-title {
  margin: 0;
}
</style>
