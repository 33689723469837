<template>
  <modal :show="modal.show" :modalClass="modal.class"
    ><slot name="header" class="modal-title p-2" id="modal-title-notification">
      <slot name="close-button"
        ><div class="text-end">
          <button @click="emitEvent()" class="px-3 close-button text-primary">
            <i class="fas fa-xmark text-white"></i>
          </button></div
      ></slot>
    </slot>
    <div class="body p-4" v-if="modal.title">
      <div class="text-start p-4">
        <h4 class="row text-center">
          <i
            class="fa-solid fa-shield-halved text-primary fa-3x fa-fade px-3 mb-4"
          ></i>
          <b>{{ modal.title }}</b>
        </h4>
        <hr class="my-4" />
        <div class="text-left">
          <span class="my-4">
            <h5>{{ modal.subtitle }}</h5>
            <p>{{ modal.description }}</p>
          </span>
        </div>
        <span class="row text-center">
          <div class="col-lg-12 col-md-12 col-sm-12 text-primary mb-4">
            <label for="input-type" class="form-label mb-2">QR Code</label>
            <div class="d-flex justify-content-center">
              <div class="card" style="width: 18rem">
                <img
                  :src="model.qrcode_img"
                  class="card-img-top"
                  alt="QRCode gerado automaticamente"
                />
                <!-- <div class="card-body">
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              </div> -->
                <button class="btn btn-primary" @click="generateQrCode()">
                  <i class="fa-solid fa-rotate-right text-white"></i> Gerar novo
                  código
                </button>
              </div>
              <img />
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 text-primary mb-4">
            <label for="input-agency" class="form-label mb-"
              >Código gerado pelo aplicativo*</label
            >
            <br />
            <div class="d-flex justify-content-center">
              <input
                type="text"
                id="input-agency"
                class="form-control"
                aria-describedby="input-agency"
                placeholder="Informe aqui o código de 6 dígitos"
                min-length="6"
                max-length="6"
                v-model="model.two_factor_secret"
                required
                style="width: 18rem"
              />
            </div>
          </div>
        </span>
        <div class="mt-2 d-flex justify-content-center">
          <span class="me-2">
            <button
              @click="submmitForm(false)"
              class="btn text-uppercase font-weight-light btn-danger text-white btn-shadow px-4"
            >
              <i class="fas fa-ban me-1"></i>Cancelar
            </button>
          </span>
          <span
            v-tooltip="
              isValidModel
                ? 'Clique para salvar'
                : 'Preencha todas as informações obrigatórias (marcadas com *) antes de continuar'
            "
          >
            <button
              class="btn text-uppercase font-weight-light btn-success text-white btn-shadow me-2 px-4"
              @click="submmitForm(true)"
              :disabled="!isValidModel"
            >
              <i class="fa-solid fa-floppy-disk me-1"></i> Salvar
            </button></span
          >
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import api from "@/services/api";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "modal-add-payment-proof",
  props: {
    modal: Object,
  },
  data() {
    return {
      model: {
        qrcode_img: "",
        two_factor_secret: "",
      },
      requiredFields: ["two_factor_secret"],
    };
  },
  methods: {
    emitEvent() {
      this.$emit("update-show", false);
      this.$emit("update-token", "");
      this.$emit("update-enable2fa", false);
    },
    generateQrCode() {
      this.$emit("generate-qrcode", true);
    },
    async submmitForm(enable_two_factor_secret) {
      if (enable_two_factor_secret) {
        try {
          api.post(`company/2fa/enable`, {
            token: this.model.two_factor_secret,
          });
          toast.success(
            "Verificação em duas etapas foi adicionada com sucesso!"
          );
          this.$emit("update-show", false);
          this.$emit("update-token", this.model.two_factor_secret);
          this.$emit("update-enable2fa", true);
        } catch (e) {
          let message =
            "Ocorreu um erro ao validar a verificação em duas etapas. Verifique se o código informado é válido e tente novamente.";
          if (e.response.data.message) message = e.response.data.message;
          toast.error(message);
        }
      } else {
        try {
          await api.put(`company/2fa/disable`, {});
          toast.warning("Verificação em duas etapas não foi adicionada.");
          this.emitEvent();
        } catch (e) {
          let message =
            "Ocorreu um erro ao validar a verificação em duas etapas.";
          if (e.response.data.message) message = e.response.data.message;
          toast.error(message);
        }
      }
    },
  },
  computed: {
    isValidModel() {
      const data = this.model;
      for (const key of this.requiredFields) {
        if (!data[key]) return false;
      }
      return true;
    },
  },
  watch: {
    "modal.data.qrcode_img": function () {
      this.model.qrcode_img = this.modal.data.qrcode_img;
    },
  },
};
</script>
<style scoped>
.modal-warning {
  background-color: #342f86;
  color: white;
}

.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
</style>
