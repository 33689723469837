<template>
  <div>
    <div class="px-4">
      <div class="body p-4">
        <span class="text-primary h2 d-flex align-items-center">
          <button
            @click="$router.push('/cliente/notas-fiscais')"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
          >
            <i class="fa fa-arrow-left"></i>
          </button>
          <span class="text-gray"> Editar guia</span>
        </span>
        <!-- Guias e Impostoss -->
        <div class="card p-4 mt-4">
          <div class="p-2">
            <!-- <h4 class="text-gray font-weight-light">Nova guia</h4> -->
            <div class="row text-start">
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2"
                  >Código da guia*</label
                >
                <input
                  type="text"
                  id="input-cod"
                  class="form-control"
                  aria-describedby="input-cod"
                  placeholder="Informe aqui o código da guia"
                  v-model="taxe.taxe_code"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-2">Nome*</label>
                <input
                  type="text"
                  id="input-name"
                  class="form-control"
                  aria-describedby="input-name"
                  placeholder="Informe aqui o nome da guia"
                  v-model="taxe.name"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-type" class="form-label mb-2"
                  >Tipo de conta*</label
                >
                <input
                  type="text"
                  id="input-type"
                  class="form-control"
                  aria-describedby="input-type"
                  placeholder="Informe aqui o tipo de conta"
                  v-model="taxe.account_type"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-agency" class="form-label mb-2">Nome*</label>
                <input
                  type="text"
                  id="input-agency"
                  class="form-control"
                  aria-describedby="input-agency"
                  placeholder="Informe aqui a agência"
                  v-model="taxe.routing_number"
                />
              </div>

              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-account" class="form-label mb-2"
                  >Conta*</label
                >
                <input
                  type="text"
                  id="input-account"
                  class="form-control"
                  aria-describedby="input-account"
                  placeholder="Informe aqui a conta bancária"
                  v-model="taxe.account_number"
                />
              </div>
            </div>
            <span class="text-center mt-4">
              <button
                @click="submmitForm()"
                class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
              >
                <span class="mx-4">Salvar</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "taxes-form",
  data() {
    return {
      taxe: "",
    };
  },
  async mounted() {
    await this.loadTaxe();
  },
  methods: {
    async submmitForm() {
      try {
        const { data } = await api.put(`/company/taxes/${this.$route.params.id}`, this.taxe);
        this.taxe = data;
        toast.success(
          "Guias e Impostos atualizado com sucesso"
        );
        this.$router.push("/cliente/notas-fiscais");
      } catch (e) {
        let message = "Ocorreu um erro ao salvar a guia. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async loadTaxe() {
      try {
        const { data } = await api.get(`/company/taxes/${this.$route.params.id}`);
        this.taxe = data;
      } catch (e) {
        let message = "Ocorreu um erro ao carregar as informações da guia. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    }
  },
};
</script>
<style scoped>
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
</style>
