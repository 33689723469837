<template>
  <div class="mb-4" tabindex="0" @keydown.enter="triggerFileInput">
    <label class="file-select">
      <div class="select-button" v-tooltip.bottom="title">
        <span v-if="value" class="btn btn-primary px-4">{{ value.name }}</span>
        <span v-else :class="' px-4 btn btn-' + type"
          ><i class="fas fa-upload fas-4 pb-0 me-2"></i>{{ title }}</span
        >
      </div>
      <input
        type="file"
        @change="handleFileChange"
        :accept="accept"
        ref="fileInput"
      />
    </label>
    <span
      v-if="!value && index != 0"
      class="ms-2 text-danger pointer"
      v-tooltip="'Remover campo'"
      @click="handleRemoveField()"
      ><i class="fas fa-trash"></i
    ></span>
    <span
      v-else-if="value"
      class="ms-2 text-danger pointer"
      v-tooltip="'Remover anexo'"
      @click="handleRemoveFile()"
      ><i class="fas fa-trash"></i
    ></span>
    <!-- <span v-if="value" class="ms-2 text-primary pointer" v-tooltip="'Adicionar outro arquivo'" @click="handleAddFile()"
      ><i class="fas fa-plus"></i
    ></span> -->
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "file-select",
  props: {
    value: File,
    index: Number,
    title: {
      default: "Selecione o arquivo",
      description: "Upload buttton label",
    },
    type: {
      default: "warning",
      description: "Upload button type",
    },
    accept: {
      type: String,
      default: "",
      description: "String with the accepted files",
    },
    mbSize: {
      type: Number,
      default: 0,
      description: "Max file size in MB",
    },
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(e) {
      const file = e.target.files[0];
      console.log(this.mbSize, file.size);
      if (file && this.mbSize && file.size > this.mbSize * 1024 * 1024) {
        toast.error(
          `O tamanho do arquivo excede o máximo permitido (${this.mbSize}MB)`
        );
      } else {
        if (file) {
          this.$emit("inputUpload", file);
        }
      }
    },
    handleRemoveField() {
      this.$emit("removeField", true);
    },
    handleRemoveFile() {
      this.$emit("inputUpload", "");
    },
    handleAddFile() {
      this.$emit("addFile", true);
    },
  },
};
</script>

<style scoped>
.file-select,
.pointer {
  cursor: pointer;
}
.file-select > input[type="file"] {
  display: none;
}
</style>
