import { createApp } from 'vue'
import App from './App.vue'
import Bootstrap from "./assets/scss/custom.scss"
import VueRouter from "vue-router"

import Router from "./router"
import Modal from '../src/components/Modal.vue'

import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import "vue-add-to-calendar";
import 'add-to-calendar-button';
import vSelect from 'vue-select'



const options = {
  timeout: 5000,  
  theme: "bubble", 
  position: "bottom-right", 
  duration: 5000,
  keepOnHover: true
};

createApp(App)
    .use(Bootstrap)
    .use(VueRouter)
    .use(Router)
    .use(FloatingVue)
    .use(Toast, options)
    .component('modal', Modal)
    .component('v-select', vSelect)
    .mount('#app')
