<template>
  <div>
    <div
      class="modal"
      @click.self="closeModal"
      :class="[{ 'show d-block': show }, { 'd-none': !show }, {'m-normal': !modalClass}, {'m-warning': modalClass}]"
      v-show="show"
      tabindex="-1"
      :aria-hidden="!show"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header" v-if="$slots.header">
            <slot name="header"></slot>
            <slot name="close-button">
              <button
                type="button"
                class="btn btn-link px-3 close"
                @click="closeModal"
                data-bs-dismiss="modal"
                aria-label="Fechar"
              >
                <span :aria-hidden="!show">X</span>
              </button>
            </slot>
          </div>
          <div class="modal-body scroll" :class="modalClass">
            <slot></slot>
          </div>
          <div class="modal-footer" v-if="$slots.footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "modal-component",
  props: {
    show: Boolean,
    modalClass: String
  },
  methods: {
    closeModal() {
      this.$emit("update", false);
    },
  },
  watch: {
    show(val) {
      let documentClasses = document.body.classList;
      if (val) {
        documentClasses.add("modal-open");
      } else {
        documentClasses.remove("modal-open");
      }
    },
  },
  beforeUnmount() {
    document.body.classList.remove("modal-open");
  },
};
</script>
<style>

.scroll {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}
.close-button {
  border: none;
  background-color: transparent;
}
</style>
<style scoped>
.modal-body {
  min-height: 100%;
}
.btn.btn-link {
  color: rgb(31, 31, 31);
  text-decoration: none;
  font-weight: bold;
}
.btn.btn-link:hover {
  color: black;
}
.scroll {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}
</style>
<style>
.modal.show {
  background-color: rgba(0, 0, 0, 0.3);
}
.modal.m-warning {
  transition: all .2s ease-in-out; 
  transform: scale(1.1);
}
.modal.m-normal {
  --bs-modal-width: 80%;
  transition: all .2s ease-in-out; 
  transform: scale(1.1);
}
.modal-content {
  background-color: rgb(249, 249, 249);
  border-radius: 20px
}
.modal-warning {
  background-color: #342f86;
  color: white;
  border-radius: 20px;
}
</style>
