<template>
  <div>
    <div class="px-4">
      <div class="body p-4">
        <span class="text-primary h2 d-flex align-items-center">
          <button
            @click="$router.push('/cliente/bancos')"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
          >
            <i class="fa fa-arrow-left"></i>
          </button>
          <span class="text-gray"> Cadastrar banco</span>
        </span>
        <!-- Bancos -->
        <div class="card p-4 mt-4">
          <div class="p-2">
            <!-- <h4 class="text-gray font-weight-light">Novo banco</h4> -->
            <div class="row text-start">
              <div class="col-lg-6 col-md-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-0">Banco*</label>
                <v-select
                  label="fullName"
                  :options="banks"
                  v-model="model.bank_code"
                  placeholder="Digite o nome ou código do banco"
                  :reduce="(bank) => bank.code"
                  :filter="filterBanks"
                />
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 text-primary mb-4">
                <label for="input-type" class="form-label mb-0"
                  >Tipo de conta*</label
                >
                <base-select
                  :options="types"
                  title="tipo de conta*"
                  :show_title="false"
                  @update-selected="model.account_type = $event"
                />
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 text-primary mb-4">
                <label for="input-agency" class="form-label mb-0"
                  >Agência*</label
                >
                <input
                  type="text"
                  id="input-agency"
                  class="form-control"
                  aria-describedby="input-agency"
                  placeholder="Informe aqui a agência"
                  v-model="model.routing_number"
                />
              </div>

              <div class="col-lg-6 col-md-6 col-sm-12 text-primary mb-4">
                <label for="input-account" class="form-label mb-0"
                  >Conta*</label
                >
                <input
                  type="text"
                  id="input-account"
                  class="form-control"
                  aria-describedby="input-account"
                  placeholder="Informe aqui a conta bancária"
                  v-model="model.account_number"
                />
              </div>
            </div>
            <span class="text-center mt-4">
              <button
                @click="submmitForm()"
                class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
              >
                <span class="mx-4">Cadastrar</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "vue-select/dist/vue-select.css";
import api from "@/services/api";
import BaseSelect from "@/components/BaseSelect.vue";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "banks-form",
  components: { BaseSelect },
  data() {
    return {
      banks: [],
      types: ["Poupança", "Corrente"],
      mounths: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      model: {
        bank_code: "",
        name: "",
        account_type: "",
        routing_number: "",
        account_number: "",
      },
    };
  },
  async mounted() {
    await this.getBanks();
  },
  methods: {
    filterBanks(options, search) {
      const result = options.filter((option) => {
        if (
          (Object.prototype.hasOwnProperty.call(option, "fullName") &&
            option.fullName &&
            option.fullName.toLowerCase().includes(search.toLowerCase())) ||
          (Object.prototype.hasOwnProperty.call(option, "code") &&
            option.code &&
            option.code.toString().includes(search.toLowerCase()))
        )
          return option;
      });
      return result;
    },
    async getBanks() {
      try {
        const { data } = await api.get(
          `https://brasilapi.com.br/api/banks/v1`,
          this.model
        );
        this.banks = data.filter((option) => {
          if (
            Object.prototype.hasOwnProperty.call(option, "name") &&
            option.name &&
            Object.prototype.hasOwnProperty.call(option, "code") &&
            option.code
          ) {
            return option;
          }
        });
        const addbank = "InfinitePay";
        if (this.banks.find((bank) => bank.fullName !== addbank)) {
          this.banks.push({
            code: 679,
            fullName: addbank,
            name: addbank,
          });
        }
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar os bancos. Por favor, recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async submmitForm() {
      try {
        const formattedData = {
          ...this.model,
        };
        formattedData.name = this.banks.find(
          (bank) => bank.code === this.model.bank_code
        ).fullName;
        formattedData.account_type = this.types[this.model.account_type - 1];
        await api.post(`/company/banks`, formattedData);
        toast.success("Banco cadastrado com sucesso");
        this.$router.push("/cliente/bancos");
      } catch (e) {
        let message =
          "Ocorreu um erro ao salvar as informações do banco. Por favor, tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    updateMonth(e) {
      this.month = e;
    },
    updateYear(e) {
      this.year = e;
    },
    getUrl() {
      let url = "";
      const month = this.month + 1;
      if (month && this.year) url = `?month=${month}&year=${this.year}`;
      else if (month) url = `/?month=${month}`;
      else if (this.year) url = `/?year=${this.year}`;
      return url;
    },
  },
};
</script>
<style scoped>
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
</style>
