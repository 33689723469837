<template>
  <div>
    <div>
      <div class="body p-4">
        <span class="text-primary h2 d-flex align-items-center mb-4">
          <button
            @click="$router.push(`/empresas`)"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
          >
            <i class="fa fa-arrow-left"></i>
          </button>
          <b>Extratos Bancários</b>
        </span>
        <!-- Extratos Bancários -->
        <div class="card p-4 text-start">
          <h4>Buscar</h4>
          <div class="row">
            <div class="col-lg-2 col-sm-5">
              <select-input
                :options="months"
                title="mês"
                :pre-selected-id="new Date().getMonth()"
                v-on:update-selected="(e) => updateMonth(e)"
              />
            </div>
            <div class="col-lg-2 col-sm-5">
              <select-input
                title="ano"
                :year="true"
                v-on:update-selected="(e) => updateYear(e)"
              />
            </div>
          </div>
        </div>
        <div class="card p-3 mt-4 text-start" v-if="!extratos.length">
          Não encontramos Extratos Bancários cadastrados para esse mês ({{
            months[month]
          }})
        </div>
        <div class="card text-start p-2" v-else>
          <div class="collection text-start m-0">
            <div
              class="collection-item relative item-title"
              v-for="(item, d_index) in extratos"
              :key="d_index"
            >
              <p class="collection-title d-flex">
                <span class="me-auto">
                  {{ item.bank.name }} - {{ item.document }}
                </span>
                <span
                  class="mx-2"
                  v-tooltip="'Ver extrato'"
                  @click="handleViewFile(item.id, 'BankReports')"
                >
                  <button class="btn btn-light shadow-sm">
                    <i class="fas fa-eye text-info"></i>
                  </button>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import SelectInput from "@/components/SelectInput.vue";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "extratos-view",
  props: {
    modal: Object,
  },
  components: {
    SelectInput,
  },
  data() {
    return {
      month: "",
      year: "",
      extratos: [],
      months: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
    };
  },
  mounted() {
    this.month = new Date().getMonth();
    this.year = new Date().getFullYear();
  },
  methods: {
    async handleViewFile(id, functionality) {
      try {
        const { data } = await api.get(
          `/user-accountant/document/${this.$route.params.id}?functionality=${functionality}&functionality_row_id=${id}`
        );
        window.open(`${data.public_url}`, "_blank");
      } catch (e) {
        let message = 
          "Ocorreu um erro ao carregar o documento. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async loadExtratos() {
      try {
        const { data } = await api.get(
          `/user-accountant/extratos/${this.$route.params.id}${this.getUrl()}`
        );
        this.extratos = data;
      } catch (e) {
        let message = 
          "Ocorreu um erro ao carregar as informações dos extratos bancários. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    updateMonth(e) {
      this.month = e;
    },
    updateYear(e) {
      this.year = e;
    },
    getUrl() {
      let url = "";
      const month = this.month + 1;
      if (month && this.year) url = `?month=${month}&year=${this.year}`;
      else if (month) url = `/?month=${month}`;
      else if (this.year) url = `/?year=${this.year}`;
      return url;
    },
  },
  watch: {
    month: async function () {
      await this.loadExtratos();
    },
    year: async function () {
      await this.loadExtratos();
    },
  },
};
</script>
<style scoped>
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
</style>
