<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12">
        <label for="input-cod" class="form-label mb-2"
          >Início<span v-if="required">*</span></label
        >
        <input
          type="time"
          id="input-name"
          class="form-control mt-2 mb-3"
          aria-describedby="input-name"
          placeholder="Selecionar horário"
          v-model="model_time.start_hour"
          :disabled="!day.checked"
        />
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <label for="input-cod" class="form-label mb-2"
          >Fim<span v-if="required">*</span></label
        >
        <input
          type="time"
          id="input-name"
          class="form-control mt-2"
          aria-describedby="input-name"
          placeholder="Selecionar horário"
          v-model="model_time.end_hour"
          :disabled="!day.checked"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "day-schedule-input",
  props: {
    day: Object,
    time: Object,
    required: Boolean,
  },
  data() {
    return {
      model_time: "",
      hours: 0,
      minutes: 0
    };
  },
  mounted() {
    this.model_time = this.time;
    this.hours = this.day.hours;
    this.minutes = this.day.minutes;
  },
  methods: {
    /* calculateHours() {
      let minutes = 0;
      const s_hour = this.model_time.start_hour.split(":")[0];
      const s_min = this.model_time.start_hour.split(":")[1];
      const e_hour = this.model_time.end_hour.split(":")[0];
      const e_min = this.model_time.end_hour.split(":")[1];

      const total_h = e_hour - s_hour;
      const total_m = e_min - s_min;
      minutes += total_h * 60 + total_m;
      this.minutes = minutes;
      this.hours = Math.trunc(minutes / 60);
      this.minutes = ((minutes % 60) / 60) * 60;
    }, */
  },
  watch: {
    "model_time.start_hour": function () {
      //this.calculateHours();
      this.$emit("updateStartHour", this.model_time.start_hour);
      this.$emit("updateMinutes", this.minutes);
    },
    "model_time.end_hour": function () {
      //this.calculateHours();
      this.$emit("updateEndHour", this.model_time.end_hour);
      this.$emit("updateMinutes", this.minutes);
    },
    /* minutes: function () {
      this.$emit("updateMinutes", this.minutes);
    }, */
  },
};
</script>
<style scoped>
.dom {
  border-radius: 20px 20px 0px 0px;
}
.sab {
  border-radius: 0px 0px 20px 20px;
}
.dom,
.ter,
.qui,
.sab {
  background-color: rgba(147, 147, 147, 0.059);
}
.seg,
.qua,
.sex {
  background-color: rgba(227, 227, 227, 0.059);
}
</style>
