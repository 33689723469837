<template>
  <modal
    :show="
      modal.show &&
      this.$router.currentRoute.value.name !== 'socios' &&
      this.$router.currentRoute.value.name !== 'funcionarios' &&
      this.$router.currentRoute.value.name !== 'chamados'
    "
    :modalClass="modal.class"
    ><slot name="header" class="modal-title p-2" id="modal-title-notification">
      <slot name="close-button"
        ><div class="text-end">
          <!-- <button
            @click="emitEvent(false);"
            class="px-3 close-button text-primary"
          >
            <i class="fas fa-xmark text-white"></i>
          </button> -->
        </div></slot
      >
    </slot>
    <div class="body modal-body" v-if="modal.title">
      <div class="text-start">
        <h4 class="row text-center py-2">
          <i
            class="fa-solid fa-triangle-exclamation text-warning fa-5x fa-fade px-3 mb-4"
            style="--fa-animation-duration: 1s"
          ></i>
          <b>Oops! Não conseguimos identificar o pagamento deste mês!</b>
          <hr class="my-4" />
        </h4>
        <div class="text-start">
          <span class="my-4">
            <p>
              Se você <b class="text-secondary">já efetuou o pagamento</b>,
              entre em contato com o nosso suporte através do número
              <a class="text-secondary fw-bold" href="tel:55996789811"
                >(55) 99678-9811</a
              >
              ou do email
              <a
                class="text-secondary fw-bold"
                href="mailto:financeiro@contdigital.com.br?subject=Acesso bloqueado ao sistema"
                >financeiro@contdigital.com.br</a
              >
              para ter seu acesso reativado.
            </p>

            <p>
              Caso você ainda
              <b class="text-secondary">não tenha efetuado o pagamento</b>,
              pedimos com gentileza que o realize para que você possa voltar a
              usufruir de todos os benefícios da plataforma sem restrições.
            </p>
            <p>Desde já, gradecemos a atenção.</p>
            
          <span class="text-secondary pointer">
            <span v-tooltip="'Telefonar'">
              <a
                class="text-secondary fw-bold"
                href="tel:55996789511"
                target="_blank"
                ><i class="fa-solid fa-phone me-2 fa-xl"></i
              ></a>
            </span>
            <span v-tooltip="'Conversar no whatsapp'">
              <a
                class="text-secondary fw-bold"
                href="https://wa.me/55996789511/"
                target="_blank"
                ><i class="fa-brands fa-whatsapp me-2 fa-xl"></i
              ></a>
            </span>
            <span v-tooltip="'Enviar email'">
              <a
                class="text-secondary fw-bold"
                href="mailto:financeiro@contdigital.com.br?subject=Acesso bloqueado ao sistema"
                ><i class="fa-regular fa-envelope me-2 fa-xl"></i></a
            ></span>
          </span>
            <hr class="mt-4 mb-0" />
          </span>
        </div>
        <div class="text-center">
          <p class='my-4'>Não se preocupe, enquanto você está restringido, você ainda pode visualizar as seguintes informações:</p>
          <div class="d-flex justify-content-center mb-4">
            <button
              class="btn text-uppercase font-weight-light btn-light btn-shadow me-2 text-primary"
              @click="$router.push('/cliente/socios')"
            >
              <i class="fa-solid fa-id-card-clip me-1"></i> Sócios
            </button>
            <button
              @click="$router.push('/cliente/funcionarios')"
              class="btn text-uppercase font-weight-light btn-light btn-shadow me-2 text-primary"
            >
              <i class="fas fa-user-tie me-1"></i>Colaboradores
            </button>
            <button
              @click="$router.push('/cliente/chamados')"
              class="btn text-uppercase font-weight-light btn-light btn-shadow text-primary"
            >
              <i class="fas fa-comments me-1"></i>Chamados
            </button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
export default {
  name: "modal-confirmation",
  props: {
    modal: Object,
  },
  data() {
    return {
      confirmation: false,
    };
  },
  mounted() {
   // console.log(this.$router.currentRoute.value.name);
  },
  methods: {
    emitEvent(e) {
      this.$emit("confirmation", e);
      this.$emit("update-show", false);
    },
  },
};
</script>
<style scoped>
.modal-body {
  padding-inline: 7%;
}
.modal-warning {
  background-color: #342f86;
  color: white;
}

.collection {
  border: none;
}
.pointer {
  cursor: pointer;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
</style>
