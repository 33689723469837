<template>
  <div class="container">
    <div class="row pt-4 text-start">
      <span class="text-primary h2 d-flex align-items-center">
        <button
          @click="$router.push('/cliente/area-do-mei')"
          class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
        >
          <i class="fa fa-arrow-left"></i>
        </button>
        <span class="text-gray"> <b>Opção:</b> Abrir MEI</span>
      </span>
      <p>
        Por favor, responda às perguntas a seguir para que possamos lhe
        encaminhar corretamente.
      </p>
    </div>
    <div class="row mt-4">
      <!-- Step 1 -->
      <div class="card col-12 text-start" v-if="actual_step === 0">
        <div
          class="progress mb-4"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="progress-bar"
            :style="'width: ' + actual_step + '%'"
          ></div>
        </div>
        <span>
          <span class="text-primary h4"
            >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
            <span class="small">- * Campos obrigatórios</span></span
          >
        </span>
        <br />
        <h4 class="h1 text-primary text-center font-weight-bold mb-4">
          Você possui CNH?
        </h4>
        <div class="row mt-4">
          <div class="col-6 text-center">
            <div class="d-flex justify-content-end">
              <div class="card p-4 width-50">
                <i class="fas fa-solid fa-check fa-5x text-primary"></i>
                <br />
                <h4 class="text-primary">Sim</h4>
                <div class="d-flex justify-content-center">
                  <button
                    @click="
                      company_model.cnh = true;
                      handleStep(1);
                    "
                    class="card-btn btn font-weight-bold btn-primary mt-4 text-white btn-shadow"
                  >
                    Selecionar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 text-center">
            <div class="d-flex justify-content-start">
              <div class="card p-4 width-50">
                <i class="fas fa-solid fa-xmark fa-5x text-primary"></i>
                <br />
                <h4 class="text-primary">Não</h4>
                <div class="d-flex justify-content-center">
                  <button
                    @click="
                      company_model.cnh = false;
                      handleStep(1);
                    "
                    class="card-btn btn font-weight-bold btn-primary mt-4 text-white btn-shadow"
                  >
                    Selecionar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Step 2 -->
      <div class="card col-12 text-start" v-if="actual_step === 34">
        <div
          class="progress mb-4"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="progress-bar"
            :style="'width: ' + actual_step + '%'"
          ></div>
        </div>
        <span class="text-primary h4 d-flex align-items-center">
          <button
            @click="handleStep(0)"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-2"
          >
            <i class="fa fa-arrow-left"></i>
          </button>

          <span>
            <span class="text-primary h4"
              >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
              <span class="small">- * Campos obrigatórios</span></span
            >
          </span>
        </span>
        <br />
        <div v-if="company_model.cnh">
          <h4 class="text-gray font-weight-light" for="input-reason">
            Informações cadastrais
          </h4>
          <div class="row">
            <div class="col-lg-6 col-sm-12 text-primary mb-4">
              <label for="input-tel" class="form-label"
                >Data de nascimento</label
              >
              <input
                type="date"
                id="input-birth"
                class="form-control"
                aria-describedby="input-birth"
                placeholder="Informe aqui a data de nascimento"
                v-model="company_model.partners.date_birth"
              />
            </div>
            <div class="col-lg-6 col-sm-12 text-primary mb-4">
              <label for="input-rg" class="form-label">Registro Geral</label>
              <input
                type="text"
                id="input-rg"
                class="form-control"
                aria-describedby="input-rg"
                placeholder="Informe aqui o registro geral"
                v-model="company_model.partners.rg_number"
              />
            </div>
            <div class="col-lg-6 col-sm-12 text-primary mb-4">
              <label for="input-oe" class="form-label">Órgão Expedidor</label>
              <input
                type="text"
                id="input-oe"
                class="form-control"
                aria-describedby="input-oe"
                placeholder="Informe aqui o órgão expedidor"
                v-model="company_model.partners.rg_emitter"
              />
            </div>
            <div class="col-lg-6 col-sm-12 text-primary mb-4">
              <label for="input-dt-oe" class="form-label"
                >Data de expedição</label
              >
              <input
                type="date"
                id="input-dt-oe"
                class="form-control"
                aria-describedby="input-dt-oe"
                placeholder="Informe aqui a data de expedição"
                v-model="company_model.partners.rg_date"
              />
            </div>
            <div class="col-lg-6 col-sm-12 text-primary mb-4">
              <label for="input-tel" class="form-label"
                >Título de eleitor</label
              >
              <input
                type="text"
                id="input-te"
                class="form-control"
                aria-describedby="input-te"
                placeholder="Informe aqui o número do título de eleitor"
                v-model="company_model.voter_number"
              />
            </div>
            <hr class="my-4" />
            <div class="col-lg-6 col-sm-12 text-primary mb-4">
              <label for="input-irpf" class="form-label">Recibo do IRPF</label>
              <input
                type="text"
                id="input-irpf"
                class="form-control"
                aria-describedby="input-irpf"
                :disabled="company_model.switch_irpf"
                v-model="company_model.irpf"
                :placeholder="
                  !company_model.switch_irpf
                    ? 'Informe aqui o número do recibo da última entrega de IRPF'
                    : 'N/A'
                "
              />
            </div>
            <div class="col-lg-6 col-sm-12 text-primary">
              <label for="switch-irpf" class="form-label">Nunca declarei</label>
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="switch-irpf"
                  v-model="company_model.switch_irpf"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <h5 class="text-gray font-weight-light">
            Para que possamos auxiliá-lo na abertura da sua MEI, precisaremos
            que você siga alguns passos sozinho no site Gov.br.
          </h5>
          <div class="ratio ratio-16x9 mb-12">
            <iframe
              src="data:text/html;charset=utf-8https://www.gov.br/pt-br"
              title="Gov.br"
            ></iframe>
          </div>
        </div>
        <span class="text-center mt-4">
          <button
            @click="handleStep(1)"
            class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
          >
            <span class="mx-4">Salvar</span>
          </button>
        </span>
      </div>
      <!-- Step 3 -->
      <div class="card col-12 text-start" v-if="actual_step === 68">
        <div
          class="progress mb-4"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="progress-bar"
            :style="'width: ' + actual_step + '%'"
          ></div>
        </div>
        <span class="text-primary h4 d-flex align-items-center">
          <button
            @click="handleStep(0)"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-2"
          >
            <i class="fa fa-arrow-left"></i>
          </button>

          <span>
            <span class="text-primary h4"
              >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
              <span class="small">- * Campos obrigatórios</span></span
            >
          </span>
        </span>
        <br />
        <h4 class="text-gray text-disabled font-weight-light">
          Contratar um plano
        </h4>
        <div class="card p-4">
          <div class="row">
            <div
              class="col-lg-12 col-md-4 col-sm-6"
              v-for="(plan, index) in plans"
              :key="'plan' + index"
            >
              <div class="card p-4 text-center">
                <div class="h2 text-primary font-weight-bold">
                  Plano {{ plan.title }}
                </div>
                <div class="d-flex justify-content-center">
                  <span class="badge badge-plan bg-secondary p-3"
                    ><span class="badge-plan-font-start font-weight-light"
                      >R$</span
                    ><span class="font-weight-bold bold">
                      {{ plan.value }} </span
                    ><span class="badge-plan-font-end font-weight-light"
                      >/mês</span
                    ></span
                  >
                </div>
                <div class="mt-3 desc">{{ plan.description }}</div>
                <div class="d-flex justify-content-center">
                  <button
                    @click="handleSubmmitPlan(1)"
                    class="mt-3 plan-btn btn px-4 font-weight-bold btn-primary text-white btn-shadow sign-button"
                  >
                    <span class="mx-4">Assinar</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 ps-4 mt-4">
              <div class="h3 text-primary">O plano <b>inclui</b>:</div>
              <div class="mt-2 ms-4">
                <ul class="list-group list-group-flush">
                  <li class="">Abertura gratuita*</li>
                  <li class="">Até 1 sócio</li>
                  <li class="">
                    Contabilidade completa + emissão DAS-MEI e declaração anual
                    MEI
                  </li>
                  <li class="">Atendimento completo por contadores</li>
                  <li class="">
                    Adicional de R$ 30,00 por sócio a partir do 3º sócio
                  </li>
                  <li class="">Adicional de R$ 40,00 por funcionário</li>
                </ul>
              </div>
              <p class="mt-4">
                *<b>Abertura gratuita</b> na opção de assinatura anual. São
                cobradas taxas de protocolo de Junta Comercial e Prefeitura,
                quando for o caso.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* import BaseSelect from "../../components/BaseSelect.vue";
 */
import api from "@/services/api";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "migrate-contability",
  /*  components: {
    BaseSelect,
  }, */
  data() {
    return {
      actual_step: 0,
      user: {
        email: "sample@mail.com",
        phone: "(99)9999-9999",
      },
      steps: {
        0: "Parte 1",
        34: "Parte 2",
        68: "Parte 3",
      },
      company_model: {
        cnh: false,
        reason: "",
        partners: [
          {
            date_birth: "",
            rg_number: "",
            rg_emitter: "",
            rg_date: "",
          },
        ],
        switch_irpf: false,
        irpf: "",
        plan: "",
      },
      plans: [
        {
          title: "MEI",
          value: 39.9,
          description: "Faturamento de até R$ 81.000,00/ano",
        },
      ],
    };
  },
  methods: {
    async handleStep(type) {
      await this.handleSubmmit();
      if (type) this.actual_step += 34;
      else this.actual_step -= 34;
    },
    async handleSubmmit() {
      await this.loadCompany();
      if (!this.company) {
        try {
          const formatedData = {
            ...this.company_model,
          };
          const { data } = await api.post("/company/mei", {
            ...formatedData,
          });
          this.company = data;
          toast.success("Informações salvas com sucesso!");
        } catch (e) {
          let message = "Ocorreu um erro ao salvar informações";
          if (e.response.data.message) message = e.response.data.message;
          toast.error(message);
        }
      } else {
        try {
          const formatedData = {
            ...this.company_model,
          };
          formatedData.monthly_billing = "Até R$ 25.000,00/mês";
          formatedData.simple_national = "Sim";
          formatedData.social_capital = formatedData.social_capital
            ? formatedData.social_capital.toString()
            : "";
          formatedData.social_capital_integrate =
            formatedData.social_capital_integrate
              ? formatedData.social_capital_integrate.toString()
              : "";
          const { data } = await api.put(
            `/company/mei/${this.company.id}`,
            formatedData
          );
          this.company = data;
          toast.success("Informações salvas com sucesso!");
        } catch (e) {
          let message = "Ocorreu um erro ao salvar informações";
          if (e.response.data.message) message = e.response.data.message;
          toast.error(message);
        }
      }
    },
    async handleSubmmitPlan(plan) {
      await this.loadCompany();
      if (this.company) {
        const dt = {
          company: this.company,
          plan: {
            ...plan,
            ...this.plans[0],
            plan_total: this.plans[0].value,
            partners_count: 1,
            employees_count: 0,
            type: 1, // ja tenho empresa
          },
        };
        try {
          await api.post(`/company/plan/`, dt);
          toast.success("Plano contratado com sucesso!");
          this.$router.push(`/cliente/dashboard`);
        } catch (e) {
          let message = "Ocorreu um erro ao salvar informações";
          if (e.response.data.message) message = e.response.data.message;
          toast.error(message);
        }
      } else {
        toast.error(
          "Termine de preencher seu cadastro para contratar um plano."
        );
      }
    },
    async loadCompany(mounted) {
      try {
        const { data } = await api.get(`/company/company-pending`);
        if (data && data.company) this.company = data.company;

        if (mounted && data.company) {
          //this.getSelectedArea(data.company.area);
          this.company_model.cnpj = data.company.cnpj_cpf;
          this.company_model.name = data.company.name;
          this.company_model.secondary_name = data.company.secondary_name;
          this.company_model.cnae = data.company.cnae;
          this.company_model.social_capital = data.company.social_capital;
          this.company_model.social_capital_integrate =
            data.company.social_capital_integrate;
          this.company_model.legal_nature = parseInt(data.company.legal_nature);
          this.company_model.partners_count = 1;
          this.company_model.employees_count = 0;
          if (data.company && data.company.andress) {
            this.company_model = {
              ...this.company_model,
              ...{
                postal_code: data.company.andress.postal_code,
                street: data.company.andress.street,
                neighborhood: data.company.andress.neighborhood,
                city: data.company.andress.city,
                uf: data.company.andress.uf,
                number: data.company.andress.number,
                complement: data.company.andress.complement,
                iptu: data.company.iptu, // on table companies,
                area_building: data.company.area_building,
              },
            };
          }
          this.getSelectedMonthlyBuilding(data.company.monthly_billing);
        }
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações da empresa. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}
.card {
  border-radius: 20px;
  padding: 40px;
}
.btn {
  border-radius: 40px;
}
.form-check,
.text-gray {
  color: #545454;
}
.progress {
  border-radius: 20px;
}
.bold {
  font-weight: bold;
}

.card-select {
  transition: 0.3s;
}

.card-select:hover {
  transition: 0.3s;
}
.card-select:hover {
  transition: 0.3s;
  transform: translateY(-7px);
  cursor: pointer;
}
.card-select.selected {
  transition-property: border;
  transition-duration: 0.3s;
  border: 2px #342f86 solid;
}
.form-check-input {
  width: 4em;
  height: 2em;
}
.form-check-input[data-v-654d9cb2] {
  width: 4em;
  height: 2em;
}
.badge-plan-font-start {
  font-size: 20px;
  font-weight: 500;
  display: inline-block;
  vertical-align: top;
}
.badge-plan-font-end {
  font-size: 20px;
  font-weight: 500;
}
.plan-btn,
.card-btn,
.width-50 {
  width: 60%;
}
.badge-plan,
.badge-plan {
  font-size: 35px;
  border-radius: 20px;
  color: #342f86;
  width: 20%;
}
.sign-button {
  width: 35%;
}
.bold {
  font-weight: bold;
}
.rounded {
  border-radius: 20px;
}
.desc {
  font-size: 22px;
}
</style>
