<template>
  <div class="container">
    <div class="d-flex justify-content-center mt-4" v-if="loaderActive">
      <div class="spinner-border text-primary spinner-border" role="status">
        <span class="visually-hidden">Aguarde...</span>
      </div>
    </div>
    <div v-else>
      <div class="row pt-4 text-start">
        <h2>Seja bem vindo(a) à ContDigital, {{ user.name }}!</h2>
        <p>Selecione abaixo o que você deseja</p>
      </div>
      <div class="row">
        <div class="card-group">
          <div class="card col-lg-4 col-md-4 col-sm-12">
            <i class="fas fa-building fa-5x text-primary"></i>
            <br />
            <h4 class="text-primary">Abrir minha empresa</h4>
            <p class="my-4 py-4">Abrir um CNPJ</p>
            <button
              @click="$router.push('/cliente/abrir-cnpj')"
              class="btn font-weight-bold btn-primary text-white btn-shadow"
            >
              Selecionar
            </button>
          </div>
          <div class="card col-lg-4 col-md-4 col-sm-12 mx-2">
            <i class="fas fa-building-circle-arrow-right fa-5x text-primary"></i>
            <br />
            <h4 class="text-primary">Migrar minha contabilidade</h4>
            <p class="my-4 py-4">Migrar contabilidade</p>
            <button
              @click="$router.push('/cliente/migrar-contabilidade')"
              class="btn font-weight-bold btn-primary text-white btn-shadow"
            >
              Selecionar
            </button>
          </div>
          <div class="card col-lg-4 col-md-4 col-sm-12">
            <i class="fas fa-house-user fa-5x text-primary"></i>
            <br />
            <h4 class="text-primary">Área do MEI</h4>
            <p class="my-4 py-4">
              Migrar MEI para ME; <br />Abrir uma MEI;<br />
              Sou MEI.
            </p>
            <button
              @click="$router.push('/cliente/area-do-mei')"
              class="btn font-weight-bold btn-primary text-white btn-shadow"
            >
              Selecionar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "home-page",
  data() {
    return {
      user: "",
      active: "",
      loaderActive: false
    };
  },
  async mounted() {
    await this.loadUser()
  },
  methods: {
    showLoader() {
      this.loaderActive = !this.loaderActive;
    },
    async loadUser() {
      try {
        this.showLoader();
        const { data } = await api.get(`/company/user`);
        this.user = data.user;
        this.active = data.active;
        if (this.user.default_company) {
          if (this.user.default_company.company.plan) {
            this.$router.push("/cliente/dashboard");
          }
        }
        this.showLoader();
      } catch (e) {
        let message = "Ocorreu um erro ao carregar as informações do usuário. Faça login novamente para continuar.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
        this.$router.push("/entrar");
      }
    }
  }
};
</script>
<style scoped>
a {
  text-decoration: none;
}
.card {
  border-radius: 20px;
  padding: 60px;
}
.btn {
  border-radius: 40px;
}
</style>
