<template>
  <div class="container">
    <div class="row pt-4 text-start">
      <span class="text-primary h2 d-flex align-items-center">
        <button
          @click="$router.push('/cliente/area-do-mei')"
          class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
        >
          <i class="fa fa-arrow-left"></i>
        </button>
        <span class="text-gray">
          <b>Opção:</b> Contratar contador para Meu MEI</span
        >
      </span>
      <p>
        Por favor, responda às perguntas a seguir para que possamos lhe
        encaminhar corretamente.
      </p>
    </div>
    <div class="row mt-4">
      <!-- Step 1 -->
      <div class="card col-12 text-start" v-if="actual_step === 0">
        <div
          class="progress mb-4"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="progress-bar"
            :style="'width: ' + actual_step + '%'"
          ></div>
        </div>
        <span>
          <span class="text-primary h4"
            >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
            <span class="small">- * Campos obrigatórios</span></span
          >
        </span>
        <br />
        <h4 class="text-gray font-weight-light">Qual o seu CNPJ</h4>
        <div class="col-lg-12 col-sm-12 text-primary mb-4">
          <label for="input-cnpj" class="form-label mb-0">CNPJ*</label>
          <input
            type="text"
            id="input-cnpj"
            class="form-control"
            aria-describedby="input-cnpj"
            placeholder="Informe aqui o CNPJ da sua empresa"
            v-model="step_2.cnpj"
          />
        </div>
        <span class="text-center">
          <button
            @click="
              handleStep(1);
              getToken();
            "
            class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
          >
            <span class="mx-4">Salvar</span>
          </button>
        </span>
      </div>
      <!-- Step 2 -->
      <div class="card col-12 text-start" v-if="actual_step === 34">
        <div
          class="progress mb-4"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="progress-bar"
            :style="'width: ' + actual_step + '%'"
          ></div>
        </div>
        <span class="text-primary h4 d-flex align-items-center">
          <button
            @click="handleStep(0)"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-2"
          >
            <i class="fa fa-arrow-left"></i>
          </button>

          <span>
            <span class="text-primary h4"
              >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
              <span class="small">- * Campos obrigatórios</span></span
            >
          </span>
        </span>
        <br />
        <h4 class="text-gray font-weight-light">Dados do CNPJ - Contato*</h4>
        <div class="row">
          <div class="col-lg-6 col-sm-12 text-primary">
            <label for="input-tel" class="form-label">Telefone*</label>
            <input
              type="text"
              id="input-tel"
              class="form-control"
              aria-describedby="input-tel"
              placeholder="Informe aqui o telefone"
              v-model="step_2.phone"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label for="input-tel" class="form-label">Email*</label>
            <input
              type="text"
              id="input-email"
              class="form-control"
              aria-describedby="input-email"
              placeholder="Informe aqui o email"
              v-model="step_2.email"
            />
          </div>
          <hr class="my-4" />
          <h5 class="text-gray font-weight-light">Dados do CNPJ</h5>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">Razão Social*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_2.name"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">Nome fantasia*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_2.secondary_name"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">CNPJ*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_2.cnpj"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">CNAE*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_2.cnae"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">Capital social*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_2.social_capital"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">Natureza Jurídica*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_2.legal_nature"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">Ramo de atividade*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_2.activity_branch"
              :disabled="true"
            />
          </div>
          <hr class="my-4" />
          <h5 class="text-gray font-weight-light">Endereço</h5>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">CEP*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_2.postal_code"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">Rua*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_2.street"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">Bairro*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_2.neighborhood"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">Cidade*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_2.city"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">Estado*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_2.uf"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label"
              >Número*<span v-tooltip="'Informe apenas o número, sem letras'"
                ><i class="fas fa-info-circle ms-2"></i> </span
            ></label>
            <input
              type="text"
              class="form-control"
              v-model="step_2.number"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">Complemento*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_2.complement"
              :disabled="true"
            />
          </div>
        </div>
        <span class="text-center mt-4">
          <button
            @click="handleStep(1)"
            class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
          >
            <span class="mx-4">Salvar</span>
          </button>
        </span>
      </div>
      <!-- Step 3 -->
      <div class="card col-12 text-start" v-if="actual_step === 68">
        <div
          class="progress mb-4"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="progress-bar"
            :style="'width: ' + actual_step + '%'"
          ></div>
        </div>
        <span class="text-primary h4 d-flex align-items-center">
          <button
            @click="handleStep(0)"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-2"
          >
            <i class="fa fa-arrow-left"></i>
          </button>

          <span>
            <span class="text-primary h4"
              >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
              <span class="small">- * Campos obrigatórios</span></span
            >
          </span>
        </span>
        <br />
        <h4 class="text-gray text-disabled font-weight-light">
          Contratar um plano
        </h4>
        <div class="card p-4">
          <div class="row">
            <div
              class="col-lg-12 col-md-4 col-sm-6"
              v-for="(plan, index) in plans"
              :key="'plan' + index"
            >
              <div class="card p-4 text-center">
                <div class="h2 text-primary font-weight-bold">
                  Plano {{ plan.title }}
                </div>
                <div class="d-flex justify-content-center">
                  <span class="badge badge-plan bg-secondary p-3"
                    ><span class="badge-plan-font-start font-weight-light"
                      >R$</span
                    ><span class="font-weight-bold bold">
                      {{ plan.value }} </span
                    ><span class="badge-plan-font-end font-weight-light"
                      >/mês</span
                    ></span
                  >
                </div>
                <div class="mt-3 desc">{{ plan.description }}</div>
                <div class="d-flex justify-content-center">
                  <button
                    @click="handleSubmmitPlan(1)"
                    class="mt-3 plan-btn btn px-4 font-weight-bold btn-primary text-white btn-shadow sign-button"
                  >
                    <span class="mx-4">Assinar</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 ps-4 mt-4">
              <div class="h3 text-primary">O plano <b>inclui</b>:</div>
              <div class="mt-2 ms-4">
                <ul class="list-group list-group-flush">
                  <li class="">Até 1 sócio</li>
                  <li class="">
                    Contabilidade completa + emissão DAS-MEI e declaração anual
                    MEI
                  </li>
                  <li class="">Atendimento completo por contadores</li>
                  <li class="">
                    Adicional de R$ 30,00 por sócio a partir do 3º sócio
                  </li>
                  <li class="">Adicional de R$ 40,00 por funcionário</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import { useToast } from "vue-toastification";
const toast = useToast();
/* import BaseSelect from "../../components/BaseSelect.vue";
 */ export default {
  name: "migrate-contability",
  /*  components: {
    BaseSelect,
  }, */
  data() {
    return {
      actual_step: 0,
      user: {
        email: "sample@mail.com",
        phone: "(99)9999-9999",
      },
      steps: {
        0: "Parte 1",
        34: "Parte 2",
        68: "Parte 3",
      },
      step_1: {
        cnpj: "",
      },
      step_2: {
        email: "",
        phone: "",
        activity_branch: "",
        name: "",
        secondary_name: "",
        cnae: "",
        social_capital: "",
        legal_nature: "",
        business_attachment: "",
        partners_count: 1,
        postal_code: "",
        street: "",
        neighborhood: "",
        city: "",
        uf: "",
        number: "",
        complement: "",
      },
      step_3: {
        plan_total: 0,
        plan: "",
      },
      plans: [
        {
          title: "MEI",
          value: 39.9,
          description: "Faturamento de até R$ 81.000,00/ano",
        },
      ],
    };
  },
  methods: {
    async handleStep(type) {
      await this.handleSubmmit();
      if (type) {
        this.actual_step += 34;
      } else {
        this.actual_step -= 34;
      }
    },
    async getToken() {
      try {
        const { data } = await api.get(`/cnpj-token`);
        if (!data) {
          const { dt } = await api.post(`/cnpj-token`);
          this.token = dt;
        } else this.token = data;
        await this.getCNPJData();
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar ao recuperar o token para buscar o seu CNPJ. Retorne um passo e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async getCNPJData() {
      // step_2 = cnpj
      // step_3 = cnpj data
      try {
        const { data } = await api.post(`/cnpj-token/${this.step_2.cnpj}`, {
          token: this.token.access_token,
        });
        this.step_2.email = data.email;
        this.step_2.phone = data.phone;
        this.step_2.name = data.name;
        this.step_2.secondary_name = data.secondary_name;
        this.step_2.cnae = data.cnae;
        this.step_2.social_capital = data.social_capital;
        this.step_2.legal_nature = data.legal_nature;
        this.step_2.activity_branch = data.activity_branch;
        this.step_2.postal_code = data.postal_code;
        this.step_2.street = data.street;
        this.step_2.neighborhood = data.neighborhood;
        this.step_2.city = data.city;
        this.step_2.uf = data.uf;
        this.step_2.number = data.number;
        this.step_2.complement = data.complement;
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar ao recuperar os dados do cnpj. Retorne um passo e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async handleSubmmit() {
      await this.loadCompany();
      if (!this.company) {
        try {
          const formatedData = {
            ...this.step_1,
            ...this.step_2,
            ...this.step_3,
          };
          const { data } = await api.post("/company/migrate", {
            ...formatedData,
          });
          this.company = data;
          toast.success("Informações salvas com sucesso!");
        } catch (e) {
          let message = "Ocorreu um erro ao salvar informações";
          if (e.response.data.message) message = e.response.data.message;
          toast.error(message);
        }
      } else {
        try {
          const formatedData = {
            ...this.step_1,
            ...this.step_2,
            ...this.step_3,
          };
          formatedData.monthly_billing = "Até R$ 25.000,00/mês";
          formatedData.simple_national = "Sim";
          formatedData.social_capital = formatedData.social_capital
            ? formatedData.social_capital.toString()
            : "";
          const { data } = await api.put(
            `/company/migrate/${this.company.id}`,
            formatedData
          );
          this.company = data;
          toast.success("Informações salvas com sucesso!");
        } catch (e) {
          let message = "Ocorreu um erro ao salvar informações";
          if (e.response.data.message) message = e.response.data.message;
          toast.error(message);
        }
      }
    },
    async handleSubmmitPlan(plan) {
      await this.loadCompany();
      if (this.company) {
        const dt = {
          company: this.company,
          plan: {
            ...plan,
            ...this.plans[0],
            plan_total: this.plans[0].value,
            partners_count: 1,
            employees_count: 0,
            type: 1, // ja tenho empresa
          },
        };
        try {
          await api.post(`/company/plan/`, dt);
          toast.success("Plano contratado com sucesso!");
          this.$router.push(`/cliente/dashboard`);
        } catch (e) {
          let message = "Ocorreu um erro ao salvar informações";
          if (e.response.data.message) message = e.response.data.message;
          toast.error(message);
        }
      } else {
        toast.error(
          "Termine de preencher seu cadastro para contratar um plano."
        );
      }
    },
    async loadCompany(mounted) {
      try {
        const { data } = await api.get(`/company/company-pending`);
        if (data && data.company) this.company = data.company;

        if (mounted && data.company) {
          //this.getSelectedArea(data.company.area);
          this.step_1.cnpj = data.company.cnpj_cpf;
          this.step_2.name = data.company.name;
          this.step_2.secondary_name = data.company.secondary_name;
          this.step_2.cnae = data.company.cnae;
          this.step_2.social_capital = data.company.social_capital;
          this.step_2.legal_nature = parseInt(data.company.legal_nature);
          this.step_2.partners_count = 1;
          this.step_2.employees_count = 0;
          if (data.company && data.company.andress) {
            this.step_2 = {
              ...this.step_2,
              ...{
                postal_code: data.company.andress.postal_code,
                street: data.company.andress.street,
                neighborhood: data.company.andress.neighborhood,
                city: data.company.andress.city,
                uf: data.company.andress.uf,
                number: data.company.andress.number,
                complement: data.company.andress.complement,
              },
            };
          }
          this.getSelectedMonthlyBuilding(data.company.monthly_billing);
        }
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações da empresa. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}
.card {
  border-radius: 20px;
  padding: 40px;
}
.btn {
  border-radius: 40px;
}
.form-check,
.text-gray {
  color: #545454;
}
.progress {
  border-radius: 20px;
}
.bold {
  font-weight: bold;
}

.card-select {
  transition: 0.3s;
}

.card-select:hover {
  transition: 0.3s;
}
.card-select:hover {
  transition: 0.3s;
  transform: translateY(-7px);
  cursor: pointer;
}
.card-select.selected {
  transition-property: border;
  transition-duration: 0.3s;
  border: 2px #342f86 solid;
}
.form-check-input {
  width: 4em;
  height: 2em;
}
.form-check-input[data-v-654d9cb2] {
  width: 4em;
  height: 2em;
}
.badge-plan-font-start {
  font-size: 20px;
  font-weight: 500;
  display: inline-block;
  vertical-align: top;
}
.badge-plan-font-end {
  font-size: 20px;
  font-weight: 500;
}
.plan-btn,
.card-btn,
.width-50 {
  width: 60%;
}
.badge-plan,
.badge-plan {
  font-size: 35px;
  border-radius: 20px;
  color: #342f86;
  width: 20%;
}
.sign-button {
  width: 35%;
}
.bold {
  font-weight: bold;
}
.rounded {
  border-radius: 20px;
}
.desc {
  font-size: 22px;
}
</style>
