<template>
  <modal :show="modal.show" :modalClass="modal.class"
    ><slot name="header" class="modal-title p-2" id="modal-title-notification">
      <slot name="close-button"
        ><div class="text-end">
          <button
            @click="emitEvent(false)"
            class="px-3 close-button text-primary"
          >
            <i class="fas fa-xmark text-primary"></i>
          </button></div
      ></slot>
    </slot>
    <div class="body p-4" v-if="modal.title">
      <div class="text-start">
        <h4 class="row text-center">
          <i
            class="fa-solid fa-calendar text-primary fa-3x fa-fade px-3 mb-4"
          ></i>
          <b>{{ modal.title }}</b>
        </h4>
        <div class="text-center">
          <span class="my-4">
            <b>{{ modal.subtitle }}</b>
            <p>{{ modal.description }}</p>
          </span>
        </div>
        <span class="text-start label">
          <b>Adicionar/alterar sua agenda</b>
        </span>
        <span class="row">
          <div class="col-lg-12 text-primary mb-4">
            <label for="input-type" class="form-label mb-2"
              >ID da agenda Google*</label
            >
            <input
              type="text"
              id="input-type"
              class="form-control"
              aria-describedby="input-type"
              placeholder="Adicione aqui o seu ID"
              v-model="model.googleCalendarId"
            />
          </div>
        </span>
        <div class="mt-2 d-flex justify-content-center mb-4">
          <span class="me-2">
            <button
              @click="emitEvent(false)"
              class="btn text-uppercase font-weight-light btn-danger text-white btn-shadow px-4"
            >
              <i class="fas fa-ban me-1"></i>Cancelar
            </button>
          </span>
          <span>
            <button
              class="btn text-uppercase font-weight-light btn-success text-white btn-shadow me-2 px-4"
              @click="updateCalendar()"
            >
              <i class="fa-solid fa-floppy-disk me-1"></i> Salvar
            </button></span
          >
        </div>
        <hr />
        <span class="text-start label">
          <b>Ou então desvincule sua agenda</b>
        </span>
        <div class="mt-4">
          <span class="me-2">
            <button
              @click="removeCalendar(true)"
              class="btn text-uppercase font-weight-light btn-danger text-white btn-shadow px-4"
            >
              <i class="fas fa-trash me-1"></i>Desvincular agenda
            </button>
          </span>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
export default {
  name: "modal-add-payment-proof",
  props: {
    modal: Object
  },
  data() {
    return {
      confirmation: false,
      model: {
        googleCalendarId: ""
      },
    };
  },
  methods: {
    emitEvent(e) {
      this.$emit("update-show", e);
    },
    async removeCalendar(e) {
      this.$emit("remove-calendar", e);
    },
    async updateCalendar() {
      this.$emit("update-calendar", this.model.googleCalendarId);
    },
  },
};
</script>
<style scoped>
.modal-warning {
  background-color: #342f86;
  color: white;
}

.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
</style>
