<template>
  <div class="container">
    <div class="mt-4 pt-4 pb-4">
      <span class="d-flex align-items-center">
        <button
          @click="$router.push('/cliente')"
          class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
        >
          <i class="fa fa-arrow-left"></i>
        </button>
        <div>
          <span class="h2 d-flex justify-content-start text-gray"
            >Área do{{ "&nbsp;" }}<b>MEI</b></span
          >
          <p class="text-start">
            Selecione abaixo a opção mais adequada para você
          </p>
        </div>
      </span>
      <br />
    </div>
    <div class="row">
      <div class="card-group">
        <div class="card col-lg-4 col-md-4 col-sm-12 mx-2">
          <i class="fas fa-building-circle-arrow-right fa-5x text-primary"></i>
          <br />
          <h4 class="text-primary">Migrar MEI para ME</h4>
          <p class="my-4 py-4">
            Migrar de Microempreendedor individual para Microempresa
          </p>
          <button
            @click="$router.push('/cliente/area-do-mei/migrar-mei')"
            class="btn font-weight-bold btn-primary text-white btn-shadow"
          >
            Selecionar
          </button>
        </div>
        <div class="card col-lg-4 col-md-4 col-sm-12">
          <i class="fas fa-solid fa-folder-plus fa-5x text-primary"></i>
          <br />
          <h4 class="text-primary">Abrir MEI</h4>
          <p class="my-4 py-4">Quero abrir uma MEI</p>
          <button
            @click="$router.push('/cliente/area-do-mei/abrir-mei')"
            class="btn font-weight-bold btn-primary text-white btn-shadow"
          >
            Selecionar
          </button>
        </div>
        <div class="card col-lg-4 col-md-4 col-sm-12 mx-2">
          <i class="fas fa-solid fa-house-circle-check fa-5x text-primary"></i>
          <br />
          <h4 class="text-primary">Sou MEI</h4>
          <p class="my-4 py-4">Sou MEI e quero um contador</p>
          <button
            @click="$router.push('/cliente/area-do-mei/contratar-contador')"
            class="btn font-weight-bold btn-primary text-white btn-shadow"
          >
            Selecionar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "mei-area",
  data() {
    return {};
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}
.card {
  border-radius: 20px;
  padding: 60px;
}
.btn {
  border-radius: 40px;
}
</style>
