<template>
  <div class="container">
    <h1 class="my-4">Tutoriais</h1>
    <div class="row">
      <div
        v-for="(option, index) in videos"
        class="col-lg-4 col-md-6 col-sm-12"
        :key="index"
      >
        <div class="card">
          <iframe
            :src="option.url"
            :title="option.title"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
            class="card-img-top"
          ></iframe>
          <div class="card-body">
            <h5 class="card-title">{{ option.title }}</h5>
            <!-- <p class="card-text">
              {{ option.description }}
            </p> -->
            <a :href="option.url" target="_blank" class="btn btn-primary px-4 mt-2"
              >Abrir em nova guia
              <i class="ms-1 fa-solid fa-up-right-from-square text-white"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
window.FontAwesomeConfig = { autoReplaceSvg: "nest" };
export default {
  name: "tutorial-page",
  data() {
    return {
      videos: [
        {
          title: "O que são as tarefas na tela inicial? Como funcionam?",
          url: "https://www.youtube.com/embed/zH6T9KmjFtk?si=vCXmJpW6M1Bnf1kq",
          description: "",
        },
        {
          title: "Como ver e cadastrar um funcionário?",
          url: "https://www.youtube.com/embed/sQ2xTu3V34M?si=iwPNGkd0jZLOs2LV",
          description: "",
        },
        {
          title: "Como cadastrar e ver meus extratos bancários?",
          url: "https://www.youtube.com/embed/cBqDuuWeufs?si=AoLnN4CIXb_2WhN4",
          description: "",
        },
        {
          title: "Como cadastrar e ver meus sócios?",
          url: "https://www.youtube.com/embed/GKKCNGo7JjI?si=bPQOKDI9SKUmhzD5",
          description: "",
        },
        {
          title: "Como cadastrar e ver minhas notas fiscais?",
          url: "https://www.youtube.com/embed/2KUWd_654S8?si=AYqOvlTjPz2Oh5rm",
          description: "",
        },
        {
          title: "Como cadastrar e ver os pagamentos?",
          url: "https://www.youtube.com/embed/WfexEQR4nAI?si=5H-f3gh3CN28bwO5",
          description:
            "Para cadastrar um novo pagamento, preencha as informações necessárias e adicione o documento a ser pago. Para adicionar o comprovante, adicione um novo campo de upload clicando em +, e em seguida, anexe o comprovante.",
        },
        {
          title: "Como cadastrar e ver meus bancos?",
          url: "https://www.youtube.com/embed/AxA5D3Cu--0?si=X8w6AMfy3flA9hk9",
          description:
            "Tutorial para: Ver meus bancos cadastrados e cadastrar novo banco",
        },
        {
          title: "Como cadastrar usuário, realizar o login e cadastrar empresa",
          url: "https://www.youtube.com/embed/XokVKgUi1k8?si=RHPVv7Ul7aEf-ZJ0",
          description:
            "Tutorial para: Cadastro de usuário, Login e Cadastro de empresa/CNPJ. Todos os cadastros funcionam da mesma forma.",
        },
      ],
    };
  },
};
</script>
<style scoped>
.rounded-circle {
  border: 3px solid;
  border-color: rgb(2, 0, 125);
}
.task-title {
  text-decoration: underline;
  font-weight: bold;
}
.btn-gray {
  margin-inline: 3px;
  border-radius: 0px;
  background-color: rgb(244, 244, 244);
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.25);
  border: none;
}

.btn-gray:hover {
  background-color: #fbb040;
  color: black;
  border: none;
}
a {
  text-decoration: none;
}
.card {
  padding: 2rem;
}
.btn {
  border-radius: 40px;
}
</style>
