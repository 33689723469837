<template>
  <div class="container">
    <div>
      <div class="row pt-3 text-start">
        <span class="my-2"
          ><span class="h2"> Seja bem vindo(a) à ContDigital,</span>
          <div
            class="d-flex justify-content-center mt-4"
            v-if="loaderActive.user"
          >
            <div
              class="spinner-border text-primary spinner-border"
              role="status"
            >
              <span class="visually-hidden">Aguarde...</span>
            </div>
          </div>
          <span v-else class="h2"
            >{{ user.default_company.company.name || user.name }}!</span
          >
        </span>
      </div>
      <div class="row mt-4">
        <div class="card" v-if="done < 99 && !loaderActive.chart">
          <div class="row">
            <div class="col-lg-6 mt-6 mb-5">
              <div class="row"></div>
              <Doughnut
                v-if="loaded"
                :data="chartConfig.data"
                :options="chartConfig.options"
              />
            </div>
            <div class="col-lg-6 mt-6 d-flex align-items-center">
              <div>
                <h3 class="text-start">
                  <i class="fa fa-exclamation-triangle text-warning me-2"></i
                  >Oops! Parece que você não finalizou o seu cadastro...
                </h3>
                <p class="text-start">
                  Seu cadastro está
                  <span class="text-bold text-danger">{{ done }}%</span>
                  completo
                </p>
                <p class="text-start">
                  Não desista! Mais um pouco e seu cadastro estará completo e
                  você poderá aproveitar todos os benefícios da ContDigital!
                </p>
                <div class="text-center mt-4">
                  <button
                    @click="goToNextPage('/cliente/empresa/editar')"
                    class="btn btn-primary px-4 font-weight-bold text-white rounded-pill me-2"
                  >
                    <span class="mx-4">Completar cadastro empresa</span>
                  </button>
                  <button
                    @click="goToNextPage('/cliente/socios/')"
                    class="btn btn-primary px-4 font-weight-bold text-white rounded-pill"
                  >
                    <span class="mx-4">Completar cadastro sócios</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-lg-6 col-md-12 col-sm-12">
          <div class="card">
            <h2 class="fw-bold">
              Tarefas a concluir
              <div
                class="d-flex justify-content-center mt-4"
                v-if="loaderActive.task"
              >
                <div
                  class="spinner-border text-primary spinner-border"
                  role="status"
                >
                  <span class="visually-hidden">Aguarde...</span>
                </div>
              </div>
              <span v-else> ({{ totalPendingCount }}) </span>
            </h2>
            <hr />
            <div class="card p-3 m-0">
              <div
                class="d-flex justify-content-center mt-4"
                v-if="loaderActive.task"
              >
                <div
                  class="spinner-border text-primary spinner-border"
                  role="status"
                >
                  <span class="visually-hidden">Aguarde...</span>
                </div>
              </div>
              <div class="row text-start bg-gray" v-else>
                <button
                  v-for="(button, index) in buttons"
                  :key="`btn-${index}`"
                  :class="`${btn_group_class} ${
                    selected_button === index ? ' active' : ''
                  }`"
                  @click="selected_button = index"
                  v-tooltip.top="button.title"
                >
                  <span>
                    <i :class="button.icon" /><i
                      class="position-absolute fa-solid fa-triangle-exclamation text-warning warning"
                      v-if="button.data.length"
                    ></i
                    ><i
                      class="position-absolute fa-solid fa-check-double text-success warning"
                      v-else
                    ></i>
                  </span>
                </button>
                <hr class="my-0" />
                <div class="mt-3">
                  <div class="pt-2 d-flex justify-content-center">
                    <div
                      class="row justify-content-center align-items-center award-message shadow-sm"
                      v-if="!buttons[selected_button].data.length"
                    >
                      <div class="col-9 text-start">
                        <h4 class="fw-bold">Muito bem!</h4>
                        Não foram encontrad{{ buttons[selected_button].conj }}s
                        <u>{{ buttons[selected_button].title }}</u> em aberto.
                      </div>
                      <div class="col-3 text-start">
                        <i
                          :id="`beat_icon_${selected_button}`"
                          class="fa-regular fa-circle-check fa-3x text-white fa-beat"
                          style="
                            --fa-animation-duration: 2s;
                            animation-iteration-count: var(
                              --fa-animation-iteration-count,
                              2
                            );
                          "
                        ></i>
                      </div>
                    </div>
                    <div v-else>
                      <div
                        class="d-flex justify-content-center align-items-center warning-message p-2 shadow-sm mb-4"
                      >
                        <i
                          :id="`beat_icon_${selected_button}`"
                          class="fa-solid fa-triangle-exclamation text-white fa-2x fa-fade px-3"
                          style="--fa-animation-duration: 2s"
                        ></i>
                        <span class="text-center">
                          Ainda há algumas tarefas a realizar em
                          <u>{{ buttons[selected_button].title }}</u
                          >!!</span
                        >
                        <i
                          :id="`beat_icon_${selected_button}`"
                          class="fa-solid fa-triangle-exclamation text-white fa-2x fa-fade px-3"
                          style="--fa-animation-duration: 2s"
                        ></i>
                      </div>
                      <div class="collection text-start m-0">
                        <div
                          v-for="(item, index) in buttons[selected_button].data"
                          :key="'item-' + index"
                          v-tooltip.top="'Ir concluir tarefa'"
                          class="task"
                          @click="
                            $router.push(
                              `/cliente${buttons[selected_button].route}/`
                            )
                          "
                        >
                          <div
                            class="collection-title d-flex align-items-center text-center justify-content-between p-2 mb-4 px-4"
                          >
                            <span
                              class="rounded-circle me-2"
                              style="
                                min-height: 50px;
                                min-width: 50px;
                                padding-top: 0.6rem;
                              "
                            >
                              <i
                                :class="buttons[selected_button].icon"
                                class="text-primary fa-1x"
                              >
                                ></i
                              >
                            </span>
                            <span class="text-start">
                              <span class="h5"
                                ><span class="task-title pe-2"
                                  >{{ item.name }} ({{ item.month }}/{{
                                    item.year
                                  }})</span
                                >
                                <i
                                  class="fa fa-exclamation-triangle text-warning me-2"
                                ></i
                              ></span>
                              <p class="mb-0">
                                {{ buttons[selected_button].subtitle }}
                              </p>
                            </span>
                            <i
                              class="fa-solid fa-chevron-right text-default fa-1x px-3"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12">
          <span class="text-right pb-0 mb-0">
            <button
              class="btn-muted btn btn-secondary text-white btn-shadow me-2"
              @click="this.modalCalendar.show = true"
            >
              <span class="small">
                <i class="fas fa-calendar me-2"></i> Adicionar agenda</span
              >
            </button>
          </span>
          <div class="card mt-0">
            <h2 class="fw-bold">Minha Agenda</h2>
            <hr />
            <div
              class="d-flex justify-content-center mt-4"
              v-if="loaderActive.calendar"
            >
              <div
                class="spinner-border text-primary spinner-border"
                role="status"
              >
                <span class="visually-hidden">Aguarde...</span>
              </div>
            </div>
            <span v-else>
              <FullCalendar :options="calendarOptions" />
            </span>
          </div>
        </div>

        <modal-block
          :modal="modalBlock"
          v-on:confirmation="(e) => (confirmation = e)"
          v-on:updateShow="(e) => (modalBlock.show = e)"
        />
        <!-- <add-to-calendar-button
          name="Guia a pagar"
          options="'Google'"
          startDate="2024-03-13"
          endDate="2024-03-13"
          timeZone="America/Sao_Paulo"
          calendar="Cont teste"
        ></add-to-calendar-button> -->
      </div>
      <modal-calendar
        :modal="modalCalendar"
        v-on:removeCalendar="(e) => (modalCalendar.remove = e)"
        v-on:updateCalendar="(e) => handleUpdate(e)"
        v-on:updateShow="(e) => (modalCalendar.show = e)"
      />
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
import googleCalendarPlugin from "@fullcalendar/google-calendar";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import { useToast } from "vue-toastification";
import ModalBlock from "@/components/Modals/ModalBlock.vue";
import ModalCalendar from "@/components/Modals/ModalCalendar.vue";
const toast = useToast();
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "vue-chartjs";
import { computed } from "vue";

ChartJS.register(ArcElement, Tooltip, Legend);
window.FontAwesomeConfig = { autoReplaceSvg: "nest" };
export default {
  name: "home-page",
  components: {
    Doughnut,
    FullCalendar,
    ModalBlock,
    ModalCalendar,
  },
  data() {
    return {
      done: 0,
      loaded: false,
      pending: [],
      chartConfig: {
        data: null,
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      },
      selected_button: 0,
      modalCalendar: {
        title: "Adicionar/Alterar sua agenda do Google vinculada à sua conta",
        subtitle:
          "Você pode adicionar/alterar ou desvincular a sua agenda Google abaixo.",
        description: "",
        remove: "",
        update: "",
        show: false,
      },
      modalBlock: {
        show: false,
        title: "",
        subtitle: "",
        description: "",
        class: "modal-warning",
        data: {},
      },
      totalPendingCount: 0,
      user: "",
      buttons: [
        {
          title: "Guias e Impostos",
          nick: "Guia",
          subtitle: "Anexar comprovante de pagamento",
          route: "/guias-e-impostos",
          icon: "fa-solid fa-file-lines",
          conj: "a",
          data: [],
        },
        {
          title: "Despesas",
          nick: "Despesa",
          subtitle: "Anexar comprovante de pagamento",
          route: "/pagamentos-e-despesas",
          icon: "fa-solid fa-wallet",
          conj: "a",
          data: [],
        },
        {
          title: "Folhas de Pagamento",
          nick: "Folha",
          subtitle: "Anexar comprovante de pagamento",
          route: "/folha-de-pagamento",
          icon: "fa-solid fa-file-invoice-dollar",
          conj: "a",
          data: [],
        },
        {
          title: "Honorários",
          nick: "Honorário",
          subtitle: "Anexar comprovante de pagamento",
          route: "/honorarios",
          icon: "fa-solid fa-file-lines",
          conj: "o",
          data: [],
        },
        {
          title: "Chamados",
          nick: "Chamado",
          subtitle: "Ver chamado",
          icon: "fa-solid fa-comments",
          route: "/chamados",
          conj: "o",
          data: [],
        },
      ],
      btn_group_class: "btn btn-gray col p-2 fa-lg",
      calendarOptions: computed(() => {
        return {
          headerToolbar: {
            center: "title",
            left: "prev,next",
            right: "listYear,dayGridMonth",
          },
          plugins: [
            dayGridPlugin,
            listPlugin,
            googleCalendarPlugin,
            bootstrapPlugin,
          ],
          navLinks: true,
          eventDisplay: "block",
          themeSystem: "bootstrap",
          eventBackgroundColor: `#fbb040`,
          eventBorderColor: `rgba(0, 0, 0, 0)`,
          eventMouseEnter: function (mouseEnterInfo) {
            mouseEnterInfo.el.firstChild.title = mouseEnterInfo.event.title;
          },
          initialView: "dayGridMonth",
          eventsSet: this.handleEventsSet,
          weekends: true,
          locale: ptBrLocale,
          /* googleCalendarApiKey: "AIzaSyAh091F6FXyejBLuaH1_GVfhBZoB8DFHns", */
          events: {
            googleCalendarId: "",
          },
          /*eventDidMount: function (info) {
          var tooltip = new Tooltip (info.el, {
            title: info.event.extendedProps.description,
            placement: 'top',
            trigger: 'hover',
            container: 'body'
          });
        }, */
        };
      }),
      all: false,
      loaderActive: {
        user: true,
        calendar: false,
        task: true,
        chart: true,
      },
      hasCalendar: false,
    };
    // AIzaSyAh091F6FXyejBLuaH1_GVfhBZoB8DFHns
    // "c_8c8964ae4f2bcee700fe13a7d0c0ba975a90237d752fef1d81d0b2892589a3cb@group.calendar.google.com"
  },
  async mounted() {
    await this.loadUser();
    if (this.active) {
      await this.loadTasks();
      this.selectDefaultButton();
      this.getPendingTasksCount();
      await this.loadCalendar();
    }
    this.handleChart();
  },
  methods: {
    goToNextPage(route) {
      const currentUrl = this.$route.fullPath;
      localStorage.setItem("previousUrl", currentUrl);
      this.$router.push(route);
    },
    getCount(field, keys) {
      let empty_count = 0;
      let total_count = 0;
      for (let key of keys) {
        if (!this.user.default_company.company[field][key]) empty_count++;
        total_count++;
      }
      return { empty_count, total_count };
    },
    handleChart() {
      let empty_count = 0;
      let total_count = 0;
      let keys = [
        "area",
        "area_building",
        "cnae",
        "cnpj_cpf",
        "description",
        "email",
        "iptu",
        "legal_nature",
        "monthly_billing",
        "name",
        "phone",
        "secondary_name",
        "social_capital",
        "social_capital_integrate",
      ];
      for (let key of keys) {
        if (!this.user.default_company.company[key]) empty_count++;
        total_count++;
      }
      keys = ["city", "neighborhood", "number", "postal_code", "street", "uf"];
      const andress_counts = this.getCount("andress", keys);

      empty_count += andress_counts.empty_count;
      total_count += andress_counts.total_count;
      this.done = parseInt(((total_count - empty_count) * 100) / total_count);
      let color = "";
      if (this.done <= 50 && this.done > 45) color = "#ff783e";
      else if (this.done <= 45) color = "#DD1B16";
      else color = "#4caf50";

      (this.chartConfig.data = {
        labels: ["% de Completude", "% A completar"],
        datasets: [
          {
            backgroundColor: [color, "#DCDCDC"],
            data: [this.done, 100 - this.done],
          },
        ],
      }),
        (this.loaded = true);
      this.showLoader("chart");
    },
    handleUpdate(e) {
      this.modalCalendar.update = e;
    },
    async removeCalendar() {
      try {
        this.showLoader("calendar");
        const { data } = await api.delete(`/company/calendar`);
        this.calendarOptions.events.googleCalendarId = data;
        if (data) this.hasCalendar = true;
        this.showLoader("calendar");
        this.modalCalendar.show = false;
        this.modalCalendar.remove = false;
      } catch (e) {
        let message =
          "Ocorreu um erro ao alterar a sua agenda do Google. Recarregue a página e tente novamente, ou reinsira o seu ID.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async updateCalendar() {
      try {
        this.showLoader("calendar");
        const { data } = await api.post(`/company/calendar`, {
          googleCalendarId: this.modalCalendar.update,
        });
        this.calendarOptions.events.googleCalendarId = data;
        if (data) this.hasCalendar = true;
        this.showLoader("calendar");
        this.modalCalendar.show = false;
        this.modalCalendar.update = "";
      } catch (e) {
        let message =
          "Ocorreu um erro ao alterar a sua agenda do Google. Recarregue a página e tente novamente, ou reinsira o seu ID.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async loadCalendar() {
      try {
        this.showLoader("calendar");
        const { data } = await api.get(`/company/calendar`);
        this.pending = [];
        if (data.pending) {
          data.pending.taxes.forEach((item) => {
            this.pending.push(item);
          });
          data.pending.expenses.forEach((item) => {
            this.pending.push(item);
          });
          data.pending.payments.forEach((item) => {
            this.pending.push(item);
          });
          data.pending.honoraries.forEach((item) => {
            this.pending.push(item);
          });
        }
        if (data.calendar && data.calendar.google_calendar_id) {
          this.calendarOptions.events.googleCalendarId =
            data.calendar.google_calendar_id;
        }
        if (data) this.hasCalendar = true;
        this.showLoader("calendar");
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar a sua agenda do Google. Recarregue a página e tente novamente, ou reinsira o seu ID.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    /*     handleOpenModal() {
      this.modalBlock.data = this.user;
      this.modalBlock.title =
        "Oops! Não conseguimos identificar o pagamento deste mês!";
      this.modalBlock.subtitle =
        "Se você já efetuou o pagamento, entre em contato com o nosso suporte através do número (XX) XXXXX-XXXX para ter seu acesso reativado.";
      this.modalBlock.description =
        "Caso você ainda não tenha efetuado o pagamento, pedimos com gentileza que o realize para que você possa voltar a usufruir de todos os benefícios da plataforma sem restrições. Desde já, gradecemos a atenção.";
      this.modalBlock.show = true;
    }, */
    showLoader(field) {
      this.loaderActive[field] = !this.loaderActive[field];
    },
    getPendingTasksCount() {
      let count = 0;
      this.buttons.forEach((btn) => {
        count += btn.data.length;
      });
      this.totalPendingCount = count;
    },
    handleEventClick: function (info) {
      info.jsEvent.preventDefault();
      if (info.event.url) {
        this.openUrl(this.getEventCopyUrl(info.event.url));
      }
      return false;
    },
    handleEventsSet: function (events) {
      this.all = false;
      this.events = [];
      /* Fix Calendar later */
      this.pending.forEach((event) => {
        this.events.push({
          id: event.id,
          title: `Pagar: ${event.name}`,
          start: this.formatDate(event.date_due),
          end: this.formatDate(event.date_due),
          /* url: event.url,
          copy: this.getEventCopyUrl(event.url), */
        });
      });
      events.forEach((event) => {
        this.events.push({
          id: event.id,
          title: event.title,
          start: event.start,
          end: event.end,
          url: event.url,
          copy: this.getEventCopyUrl(event.url),
        });
      });
    },
    getEventCopyUrl(url) {
      const eid = url.split("?eid=");
      if (eid && eid[1])
        return `https://calendar.google.com/calendar/u/0/r/eventedit/copy/${eid[1]}`;
      return url;
    },
    openUrl(url) {
      window.open(url, "gcalevent", "width=700,height=600");
    },
    loadAllEvents() {
      this.all = true;
      this.$toasted.show("Os próximos eventos foram carregados");
    },
    formatDate(date) {
      if (date) {
        const newDate = new Date(date);
        const year = newDate.getUTCFullYear();
        let day = newDate.getUTCDate();
        let month = newDate.getUTCMonth() + 1;
        if (month < "10") {
          month = "0" + month;
        }
        if (day < "10") {
          day = "0" + day;
        }
        //yyyy-MM-dd
        return `${year}/${month}/${day}`;
      } else {
        return "Data não informada";
      }
    },
    async loadTasks() {
      try {
        const { data } = await api.get(`/company/tasks`);
        if (data.taxes) this.buttons[0].data = data.taxes;
        if (data.expenses) this.buttons[1].data = data.expenses;
        if (data.payments) this.buttons[2].data = data.payments;
        if (data.honoraries) this.buttons[3].data = data.honoraries;
        this.showLoader("task");
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações das suas tarefas. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    selectDefaultButton() {
      const btn = this.buttons.findIndex((btn) => btn.data.length > 0);
      if (btn >= 0) this.selected_button = btn;
    },
    async loadUser() {
      try {
        const { data } = await api.get(`/company/user`);
        this.user = data.user;
        this.active = data.active;
        if (this.user.default_company) {
          if (!this.user.default_company.company.plan) {
            this.$router.push("/cliente");
          }
        } else {
          this.$router.push("/cliente");
        }
        this.showLoader("user");
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações do usuário. Faça login novamente para continuar.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
        this.$router.push("/entrar");
      }
    },
  },
  watch: {
    "modalCalendar.remove": async function () {
      if (this.modalCalendar.remove) {
        await this.removeCalendar();
      }
    },
    "modalCalendar.update": async function () {
      if (this.modalCalendar.update) {
        await this.updateCalendar();
      }
    },
    "modalCalendar.show": async function () {
      if (!this.modalCalendar.show) {
        await this.loadCalendar();
      }
    },
  },
  computed: {
    nextEvents() {
      const futureEvents = this.events.filter((event) =>
        this.moment(event.start).isSameOrAfter(this.moment())
      );
      futureEvents.sort((a, b) => {
        const date_a = this.moment(a.start);
        const date_b = this.moment(b.start);
        if (date_a.isBefore(date_b)) return -1;
        else if (date_a.isAfter(date_b)) return 1;
        return 0;
      });
      if (this.all) return futureEvents;
      return futureEvents.slice(0, 3);
    },
  },
};
</script>
<style scoped>
.task {
  transition: 0.3s;
}
.task:hover {
  transition: 0.3s;
  transform: translateY(-7px);
  cursor: pointer;
}
.rounded-circle {
  border: 3px solid;
  border-color: rgb(2, 0, 125);
}
.collection,
.collection-title {
  border-radius: 20px;
  border: none;
}
.collection-title {
  min-height: 70px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.025),
    0 3px 1px -2px rgba(0, 0, 0, 0.025), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.warning[data-v-1850a9e5] {
  padding-right: 10px;
}
.warning {
  top: 20px;
  padding-right: 10px;
}
.warning-message {
  background-color: #ff783e;
}
.award-message {
  background-color: #1fb866;
  width: 90%;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.award-message,
.warning-message {
  border-radius: 30px;
  color: white;
  border: none;
}
.btn[data-v-1850a9e5].active {
  border-radius: 20px 20px 0px 0px;
  background-color: #342f86;
  color: white;
  border: none;
}
.btn[data-v-1850a9e5].active:hover {
  background-color: #fbb040;
  color: black;
  border: none;
}

.btn[data-v-1850a9e5] {
  border-radius: 20px 20px 0px 0px;
  border: none;
}
.task-title {
  text-decoration: underline;
  font-weight: bold;
}
.btn-gray {
  margin-inline: 3px;
  border-radius: 0px;
  background-color: rgb(244, 244, 244);
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.25);
  border: none;
}

.btn-gray:hover {
  background-color: #fbb040;
  color: black;
  border: none;
}
a {
  text-decoration: none;
}
.card {
  padding: 2rem;
}
.btn {
  border-radius: 40px;
}
</style>
