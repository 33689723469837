<template>
  <div>
    <div class="px-4">
      <div class="d-flex justify-content-center mt-4" v-if="loaderActive">
        <div class="spinner-border text-primary spinner-border" role="status">
          <span class="visually-hidden">Aguarde...</span>
        </div>
      </div>
      <div class="body p-4">
        <span class="text-primary h2 d-flex align-items-center">
          <button
            @click="$router.push('/cliente/bancos')"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
          >
            <i class="fa fa-arrow-left"></i>
          </button>
          <span class="text-gray"> Editar banco</span>
        </span>
        <!-- Banco -->
        <div class="row">
          <div class="col-lg-8">
            <div class="card p-4 mt-4">
              <div class="p-2" v-show="!loaderActive">
                <div class="row text-start">
                  <div class="col-lg-6 col-md-6 col-sm-12 text-primary mb-4">
                    <label for="input-cod" class="form-label mb-0"
                      >Banco*</label
                    >
                    <v-select
                      label="fullName"
                      :options="banks"
                      v-model="bank.bank_code"
                      placeholder="Digite o nome ou código do banco"
                      :reduce="(bank) => bank.code"
                      :filter="filterBanks"
                    />
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 text-primary mb-2">
                    <label for="input-type" class="form-label mb-0"
                      >Tipo de conta*</label
                    >
                    <base-select
                      :options="types"
                      title="tipo de conta*"
                      :show_title="false"
                      @update-selected="bank.account_type = $event"
                      :selected_id="bank.account_type"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-agency" class="form-label mb-2"
                      >Agência*</label
                    >
                    <input
                      type="text"
                      id="input-agency"
                      class="form-control"
                      aria-describedby="input-agency"
                      placeholder="Informe aqui a agência"
                      v-model="bank.routing_number"
                    />
                  </div>

                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-account" class="form-label mb-2"
                      >Conta*</label
                    >
                    <input
                      type="text"
                      id="input-account"
                      class="form-control"
                      aria-describedby="input-account"
                      placeholder="Informe aqui a conta bancária"
                      v-model="bank.account_number"
                    />
                  </div>
                </div>
                <span class="text-center mt-4">
                  <button
                    @click="submmitForm()"
                    class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
                  >
                    <span class="mx-4">Salvar</span>
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card mt-4 cursor-auto">
              <div class="d-flex justify-content-center">
                <span
                  class="mb-4 rounded-circle shadow mt-n5"
                  style="width: min-content; padding: 2rem"
                >
                  <i class="fa fa-landmark fa-5x text-secondary"></i>
                </span>
              </div>
              <div class="row text-center">
                <div class="col-12 h4">
                  <b>{{ bank.name }}</b>
                </div>
                <div class="col-12">
                  <b>Conta:</b> {{ bank.account_number || "Não informado" }}
                </div>
                <div class="col-12">
                  <b>Tipo de conta:</b>
                  {{ bank.account_type || "Não informado" }}
                </div>
                <div class="col-12">
                  <b>Agência:</b> {{ bank.bank_code || "Não informado" }}
                </div>
                <div class="col-12">
                  <b>Código do banco:</b>
                  {{ bank.bank_code || "Não informado" }}
                </div>
                <span class="d-flex justify-content-center">
                  <hr class="my-4" style="width: 90%" />
                </span>
                <span
                  class="d-flex justify-content-center"
                  v-tooltip="'Remover'"
                >
                  <button class="btn btn-light shadow-sm" @click="deleteBank()">
                    <i class="fas fa-trash text-danger"></i>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "vue-select/dist/vue-select.css";
import api from "@/services/api";
import BaseSelect from "@/components/BaseSelect.vue";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "banks-edit",
  components: { BaseSelect },
  data() {
    return {
      banks: [],
      types: ["Poupança", "Corrente"],
      bank: "",
      loaderActive: false,
    };
  },
  async mounted() {
    await this.getBanks();
    await this.loadBank();
  },
  methods: {
    getSelectedType() {
      this.types.forEach((type, index) => {
        if (this.bank.account_type === type) {
          this.bank.account_type = index + 1;
        }
      });
    },
    filterBanks(options, search) {
      const result = options.filter((option) => {
        if (
          (Object.prototype.hasOwnProperty.call(option, "fullName") &&
            option.fullName &&
            option.fullName.toLowerCase().includes(search.toLowerCase())) ||
          (Object.prototype.hasOwnProperty.call(option, "code") &&
            option.code &&
            option.code.toString().includes(search.toLowerCase()))
        )
          return option;
      });
      return result;
    },
    async getBanks() {
      try {
        const { data } = await api.get(`https://brasilapi.com.br/api/banks/v1`);
        const banks = data.filter((option) => {
          if (
            Object.prototype.hasOwnProperty.call(option, "name") &&
            option.name &&
            Object.prototype.hasOwnProperty.call(option, "code") &&
            option.code
          ) {
            return option;
          }
        });
        const addbank = "InfinitePay";
        if (banks.find((bank) => bank.fullName !== addbank)) {
          banks.push({
            code: 679,
            fullName: addbank,
            ispb: "0679",
            name: addbank,
          });
        }
        this.banks = banks;
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar os bancos. Por favor, recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    showLoader() {
      this.loaderActive = !this.loaderActive;
    },
    async deleteBank() {
      this.showLoader();
      try {
        await api.delete(`/company/banks/${this.$route.params.id}`);
        this.$router.push("/cliente/bancos");
        toast.success("Banco removido com sucesso!");
      } catch (e) {
        let message =
          "Ocorreu um erro ao remover o banco. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
      this.showLoader();
    },
    async submmitForm() {
      try {
        const formattedData = {
          ...this.bank,
        };
        formattedData.name = this.banks.find(
          (bank) => bank.code === this.bank.bank_code
        ).fullName;
        formattedData.account_type = this.types[this.bank.account_type - 1];
        this.showLoader();
        const { data } = await api.put(
          `/company/banks/${this.$route.params.id}`,
          formattedData
        );
        this.bank = data;
        toast.success("Banco atualizado com sucesso");
        this.$router.push("/cliente/bancos");
        this.showLoader();
      } catch (e) {
        let message =
          "Ocorreu um erro ao salvar o banco. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async loadBank() {
      try {
        this.showLoader();
        const { data } = await api.get(
          `/company/banks/${this.$route.params.id}`
        );
        this.bank = data;
        this.getSelectedType();
        this.showLoader();
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações do banco. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
  },
};
</script>
<style scoped>
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
.rounded-circle {
  background-color: rgb(2, 0, 125);
}
</style>
