<template>
  <div class="modal-overlay" v-if="modal.show && showModal && forms.length">
    <div
      :class="
        step === 0
          ? forms.length
            ? 'modal-content-img'
            : 'modal-content'
          : 'modal-content scroll'
      "
    >
      <div class="body" v-if="step === 0 && forms.length">
        <img
          :src="forms[currentIndex].form_image"
          :style="{
            width: 'auto',
            height: '90vh',
            borderRadius: '20px',
          }"
          :title="
            forms[currentIndex].title + ' | ' + forms[currentIndex].description
          "
          class="image"
          @click="handleLoadForm()"
        />
      </div>
      <div class="body" v-else-if="!forms.length">
        <div class="d-flex justify-content-center">
          <span class="badge rounded-pill bg-danger px-3 me-2">
            Não foram encontrados formulários e presquisas em aberto.
          </span>
        </div>
      </div>
      <div class="body" v-else>
        <button
          @click="step = 0"
          class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
        >
          <i class="fa fa-arrow-left"></i>
        </button>
        <div class="h5 mt-4 text-primary fst-italic">
          {{ forms[currentIndex].title }}
        </div>
        <div class="h4 text-primary">{{ forms[currentIndex].description }}</div>
        <div>* Campos Obrigatórios:</div>
        <div class="row d-flex align-items-stretch">
          <div
            v-for="(question, index) in form.questions"
            :key="`form_question_${index}`"
            class="text-primary col-lg-6 col-md-6 col-sm-12 mb-3"
          >
            <div class="">
              <div class="card p-4">
                <div>
                  {{ question.title }}{{ question.isOptional ? "" : "*" }}
                </div>
                <div>{{ question.description }}</div>
                <div class="form-check form-switch mb-2">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    :id="`switch_${index}`"
                    v-model="question.answer"
                  />
                </div>
                <span
                  >(Sua resposta:
                  <span v-if="question.answer" class="text-success">Sim</span
                  ><span v-else class="text-danger">Não</span>)</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="text-center">
          <button
            @click="handleSubmmit()"
            class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
          >
            <span class="mx-4">Salvar</span>
          </button>
        </div>
      </div>
      <button @click="emitEvent()" class="px-3 close-button">
        <span v-tooltip="'Fechar'">
          <i
            class="fas fa-xmark text-white"
            v-if="step === 0 && forms.length"
          ></i>
          <i class="fas fa-xmark text-primary" v-else></i
        ></span>
      </button>
      <!-- <button class="next-button" @click="nextImage">Próximo</button> -->
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "modal-forms",
  props: {
    modal: Object,
  },
  data() {
    return {
      showModal: false,
      currentIndex: 0,
      width: 1080,
      height: 1350,
      forms: [],
      step: 0,
      form: {},
      answers: [],
    };
  },
  methods: {
    async loadFroms() {
      try {
        const { data } = await api.get(`/company/forms`);
        this.forms = data;
        console.log(data);
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações dos formulários.";
        if (e.response) message = e.response;
        toast.error(message);
      }
    },
    async handleLoadForm() {
      try {
        const { data } = await api.get(
          `/company/forms/${this.forms[this.currentIndex].id}`
        );
        const questions = [];
        for (let question of data.questions) {
          questions.push({
            ...question,
            answer: "",
          });
        }
        data.questions = questions;
        this.form = data;
        this.step = 1;
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações do formulário.";
        if (e.response) message = e.response;
        toast.error(message);
      }
    },
    formatAnswers() {
      for (let question of this.form.questions) {
        this.answers.push({
          answer: question.answer.toString(),
          option_id: null,
          question_id: question.id,
        });
      }
    },
    async handleSubmmit() {
      this.formatAnswers();
      try {
        await api.post(`/company/forms/${this.form.id}`, {
          answers: this.answers,
        });
        toast.success("Informações salvas com sucesso!");
        this.form = {};
        this.step = 1;
        this.emitEvent();
      } catch (e) {
        let message = "Ocorreu um erro ao salvar informações";
        if (e.response && e.response.data && e.response.data.message)
          message = e.response.data.message;
        toast.error(message);
      }
    },
    nextImage() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },
    emitEvent() {
      this.$emit("update-show", false);
    },
  },
  watch: {
    "modal.show": async function () {
      await this.loadFroms();
      if (this.modal.mounted) {
        if (!this.forms.length) {
          this.showModal = false;
          this.$emit("update-show", false);
        } else {
          this.showModal = true;
        }
        this.$emit("update-mounted", false);
      } else {
        this.showModal = true;
      }
    },
  },
};
</script>

<style scoped>
.scroll {
  max-height: calc(100vh - 50px);
  overflow-y: auto;
}
.body {
  min-width: auto;
  min-height: auto;
  border-radius: 20px;
}
/* Fundo escuro cobrindo toda a tela */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Modal com largura e altura da imagem */
.modal-content-img {
  cursor: pointer;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  position: relative;
  height: 90vh;
  width: auto;
}
.modal-content {
  border-radius: 20px;
  padding: 30px;
  position: relative;
  max-width: 80%;
  height: 90vh;
  width: auto;
}
.image {
  filter: grayscale(0%);
  transition: 1s ease;
}
.image:hover {
  filter: grayscale(50%);
  filter: gray;
  transition: 1s ease;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #2a266b;
  border: none;
  cursor: pointer;
}
.next-button {
  background: rgba(255, 255, 255, 0.8);
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.next-button:hover {
  background: white;
}
</style>
