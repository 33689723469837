import axios from "axios";
import { getToken } from "./auth";

const api = axios.create({
    baseURL: "https://prod-new-contdigital-app-e68e48353516.herokuapp.com/"
});
api.interceptors.request.use(async api_config => {
const token = getToken();
if (token) api_config.headers.Authorization = `Bearer ${token}`;
    return api_config;
})
export default api;
