<template>
  <div>
    <div class="px-4">
      <div class="body p-4">
        <span class="text-primary h2 d-flex align-items-center">
          <button
            @click="handleGoBack(`/notas-fiscais/${$route.params.id}`)"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
          >
            <i class="fa fa-arrow-left"></i>
          </button>
          <span class="text-gray"> Cadastrar Nota Fiscal</span>
        </span>
        <!-- Guias e Impostoss -->
        <div class="row">
          <div
            :class="
              document
                ? document.type.includes('image')
                  ? 'col-lg-8 col-md-6 col-sm-12'
                  : 'col-6'
                : 'col-lg-8 col-md-6 col-sm-12'
            "
          >
            <div class="card p-4 mt-4">
              <div class="p-2">
                <!-- <h4 class="text-gray font-weight-light">Novo nota fiscal</h4> -->
                <div class="row text-start">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <label for="input-type" class="form-label mb-2 text-primary"
                      >Classificação*</label
                    >
                    <base-select
                      :options="classes"
                      :title="'classificação'"
                      :show_title="false"
                      @update-selected="model.name = $event"
                      :selected_id="model.name"
                    />
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <label for="input-type" class="form-label mb-2 text-primary"
                      >Tipo*</label
                    >
                    <base-select
                      :options="types"
                      :title="'tipo'"
                      :show_title="false"
                      @update-selected="model.type = $event"
                      :selected_id="model.type"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-type" class="form-label mb-2"
                      >Mês e ano de competência*</label
                    >
                    <input
                      type="month"
                      id="input-type"
                      class="form-control"
                      aria-describedby="input-type"
                      v-model="model.date_due"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-amount" class="form-label mb-2"
                      >Valor*</label
                    >
                    <input
                      type="text"
                      id="input-amount"
                      class="form-control"
                      aria-describedby="input-amount"
                      placeholder="Informe aqui o valor"
                      v-model="model.amount"
                    />
                  </div>

                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-price_paid" class="form-label mb-2"
                      >Arquivo*</label
                    >
                    <br />
                    <base-file
                      :value="document"
                      title="Selecione o documento"
                      accept=""
                      :mbSize="50"
                      type="secondary text-white"
                      @input-upload="document = $event"
                    />
                  </div>
                </div>
                <span
                  class="text-center mt-4"
                  v-tooltip="
                    isValidModel
                      ? 'Clique para salvar'
                      : 'Preencha todas as informações obrigatórias (marcadas com *) antes de continuar'
                  "
                >
                  <button
                    @click="submmitForm()"
                    :disabled="!isValidModel"
                    class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
                  >
                    <span class="mx-4">Cadastrar</span>
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div
            :class="
              document
                ? document.type.includes('image')
                  ? 'col-lg-4 col-md-6 col-sm-12'
                  : 'col-6'
                : 'col-lg-4 col-md-6 col-sm-12'
            "
          >
            <div class="card p-4 mt-4">
              <div class="p-2">
                <div
                  class="mb-2 text-primary fw-bold pointer mb-3"
                  @click="openLink()"
                >
                  Pré visualização do arquivo
                  <span v-tooltip="'abrir em nova guia'" v-if="doc_preview"
                    ><i class="ms-1 fa-solid fa-up-right-from-square"></i>
                  </span>
                </div>

                <div v-if="!document">
                  <span class="badge rounded-pill bg-warning px-3 me-2">
                    Nenhum arquivo adicionado até o momento
                  </span>
                </div>
                <div v-else>
                  <div v-if="document.type.includes('image')">
                    <img
                      id="doc"
                      :src="doc_preview"
                      alt="nota fiscal"
                      class="img-fluid rounded-preview"
                    />
                  </div>
                  <div v-else-if="document.type.includes('pdf')">
                    <iframe
                      id="doc"
                      :src="doc_preview"
                      class="container-fluid"
                      style="min-height: 50vh; border: none"
                    ></iframe>
                  </div>
                  <div
                    v-else-if="
                      document.type.includes('zip') ||
                      document.type.includes('rar')
                    "
                  >
                    <span class="badge rounded-pill bg-secondary px-3 me-2">
                      Não há pré-visualização para o formato de arquivo
                      adicionado
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseSelect from "@/components/BaseSelect.vue";
import BaseFile from "@/components/BaseFile.vue";
import api from "@/services/api";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "invoices-form",
  components: {
    BaseSelect,
    BaseFile,
  },
  data() {
    return {
      document: "",
      month: "",
      year: "",
      expenses: [],
      mounths: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      classes: ["NFe", "NFCe", "NFSe", "Outro"],
      types: ["Entrada", "Saída", "Devolução"],
      model: {
        name: "",
        date_due: "",
        amount: "",
        type: "",
      },
      doc_preview: "",
      requiredFields: ["name", "type", "date_due", "amount"],
    };
  },
  methods: {
    handleGoBack() {
      const previousUrl = localStorage.getItem("previousUrl");
      if (previousUrl) {
        this.$router.push(previousUrl);
      } else {
        this.$router.push("/");
      }
    },
    async submmitForm() {
      try {
        const model = {
          name: this.classes[this.model.name],
          document: this.document,
          type: this.model.type,
          amount: parseInt(this.model.amount),
          month: parseInt(this.model.date_due.split("-")[1]),
          year: parseInt(this.model.date_due.split("-")[0]),
        };
        const { data } = await api.post(`/company/invoices`, model);
        this.expenses = data;
        toast.success("Guias e Impostos cadastrado com sucesso");
        this.$router.push("/notas-fiscais");
        URL.revokeObjectURL(this.doc_preview);
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações das notas fiscais. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async loadInvoices() {
      try {
        const { data } = await api.get(
          `/user-accountant/expenses${this.getUrl()}`
        );
        this.expenses = data;
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações das notas fiscais. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    openLink() {
      window.open(this.doc_preview, "_blank");
    },
    updateMonth(e) {
      this.month = e;
    },
    updateYear(e) {
      this.year = e;
    },
    getUrl() {
      let url = "";
      const month = this.month + 1;
      if (month && this.year) url = `?month=${month}&year=${this.year}`;
      else if (month) url = `/?month=${month}`;
      else if (this.year) url = `/?year=${this.year}`;
      return url;
    },
  },
  watch: {
    month: async function () {
      await this.loadInvoices();
    },
    year: async function () {
      await this.loadInvoices();
    },
    document: function () {
      if (this.document) {
        this.doc_preview = URL.createObjectURL(this.document);
      }
    },
  },
  computed: {
    isValidModel() {
      const data = this.model;
      for (const key of this.requiredFields) {
        if (!data[key]) return false;
      }
      if (!this.document) {
        return false;
      }
      return true;
    },
  },
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
.rounded-preview {
  border-radius: 20px;
}
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
</style>
