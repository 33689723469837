<template>
  <div class="container">
    <div class="row pt-4 text-start">
      <span class="text-primary h2 d-flex align-items-center">
        <button
          @click="$router.push('/cliente')"
          class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
        >
          <i class="fa fa-arrow-left"></i>
        </button>
        <span class="text-gray"> <b>Opção:</b> Migrar minha contabilidade</span>
      </span>
      <p>
        Por favor, responda às perguntas a seguir para que possamos lhe
        encaminhar corretamente.
      </p>
    </div>
    <div class="row mt-4">
      <!-- Step 1 -->
      <div class="card col-12 text-start" v-if="actual_step === 0">
        <div
          class="progress mb-4"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="progress-bar"
            :style="'width: ' + actual_step + '%'"
          ></div>
        </div>
        <span>
          <span class="text-primary h4"
            >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
            <span class="small">- * Campos obrigatórios</span></span
          >
        </span>
        <br />
        <h4 class="h1 text-primary text-center font-weight-bold mb-4">
          É simples nacional?
        </h4>
        <div class="row mt-4">
          <div class="col-6 text-center">
            <div class="d-flex justify-content-end">
              <div class="card p-4 width-50">
                <i class="fas fa-solid fa-check fa-5x text-primary"></i>
                <br />
                <h4 class="text-primary">Sim</h4>
                <div class="d-flex justify-content-center">
                  <button
                    @click="
                      step_1.simples_nacional = true;
                      handleStep(1);
                    "
                    class="card-btn btn font-weight-bold btn-primary mt-4 text-white btn-shadow"
                  >
                    Selecionar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 text-center">
            <div class="d-flex justify-content-start">
              <div class="card p-4 width-50">
                <i class="fas fa-solid fa-xmark fa-5x text-primary"></i>
                <br />
                <h4 class="text-primary">Não</h4>
                <div class="d-flex justify-content-center">
                  <button
                    @click="
                      step_1.simples_nacional = false;
                      handleStep(1);
                    "
                    class="card-btn btn font-weight-bold btn-primary mt-4 text-white btn-shadow"
                  >
                    Selecionar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Step 2 in case this.step_1.simples_nacional || Step 3 in case não é simples mas foi solicitada adesão -->
      <div
        class="card col-12 text-start"
        v-if="
          (this.step_1.simples_nacional && actual_step === 20) ||
          (!this.step_1.simples_nacional &&
            this.step_1.requested &&
            actual_step === 32)
        "
      >
        <div
          class="progress mb-4"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="progress-bar"
            :style="'width: ' + actual_step + '%'"
          ></div>
        </div>
        <span class="text-primary h4 d-flex align-items-center">
          <button
            @click="handleStep(0)"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-2"
          >
            <i class="fa fa-arrow-left"></i>
          </button>

          <span>
            <span class="text-primary h4"
              >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
              <span class="small">- * Campos obrigatórios</span></span
            >
          </span>
        </span>
        <br />
        <h4 class="text-gray font-weight-light">Qual o seu CNPJ</h4>
        <div class="col-lg-12 col-sm-12 text-primary mb-4">
          <label for="input-cnpj" class="form-label mb-0">CNPJ*</label>
          <input
            type="text"
            id="input-cnpj"
            class="form-control"
            aria-describedby="input-cnpj"
            placeholder="Informe aqui o CNPJ da sua empresa"
            v-model="step_2.cnpj_cpf"
          />
        </div>
        <span class="text-center">
          <button
            @click="
              handleStep(1);
              getToken();
            "
            class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
          >
            <span class="mx-4">Salvar</span>
          </button>
        </span>
      </div>
      <!-- Step 3 in case this.step_1.simples_nacional || Step 4 in case não é simples mas foi solicitada adesão   -->
      <div
        class="card col-12 text-start"
        v-if="
          (this.step_1.simples_nacional && actual_step === 40) ||
          (!this.step_1.simples_nacional &&
            this.step_1.requested &&
            actual_step === 48)
        "
      >
        <div
          class="progress mb-4"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="progress-bar"
            :style="'width: ' + actual_step + '%'"
          ></div>
        </div>
        <span class="text-primary h4 d-flex align-items-center">
          <button
            @click="handleStep(0)"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-2"
          >
            <i class="fa fa-arrow-left"></i>
          </button>

          <span>
            <span class="text-primary h4"
              >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
              <span class="small">- * Campos obrigatórios</span></span
            >
          </span>
        </span>
        <br />
        <h4 class="text-gray font-weight-light">Dados do CNPJ - Contato*</h4>
        <div class="row">
          <div class="col-lg-6 col-sm-12 text-primary">
            <label for="input-tel" class="form-label">Telefone*</label>
            <input
              type="text"
              id="input-tel"
              class="form-control"
              aria-describedby="input-tel"
              placeholder="Informe aqui o telefone"
              v-model="step_3.phone"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label for="input-tel" class="form-label">Email*</label>
            <input
              type="text"
              id="input-email"
              class="form-control"
              aria-describedby="input-email"
              placeholder="Informe aqui o email"
              v-model="step_3.email"
            />
          </div>
          <hr class="my-4" />
          <h5 class="text-gray font-weight-light">Dados do CNPJ</h5>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">Razão Social*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.name"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">Nome fantasia*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.secondary_name"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">CNPJ*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_2.cnpj_cpf"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">CNAE*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.cnae"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">Capital social*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.social_capital"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">Natureza Jurídica*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.legal_nature"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">Ramo de atividade*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.activity_branch"
              :disabled="true"
            />
          </div>
          <hr class="my-4" />
          <h5 class="text-gray font-weight-light">Endereço</h5>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">CEP*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.postal_code"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">Rua*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.street"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">Bairro*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.neighborhood"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">Cidade*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.city"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">Estado*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.uf"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label"
              >Número*<span v-tooltip="'Informe apenas o número, sem letras'"
                ><i class="fas fa-info-circle ms-2"></i> </span
            ></label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.number"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label class="form-label">Complemento*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.complement"
              :disabled="true"
            />
          </div>
        </div>
        <span class="text-center mt-4">
          <button
            @click="handleStep(1)"
            class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
          >
            <span class="mx-4">Salvar</span>
          </button>
        </span>
      </div>
      <!-- Step 4 in case this.step_1.simples_nacional || Step 5 in case não é simples mas foi solicitada adesão   -->
      <div
        class="card col-12 text-start"
        v-if="
          (this.step_1.simples_nacional && actual_step === 60) ||
          (!this.step_1.simples_nacional &&
            this.step_1.requested &&
            actual_step === 64)
        "
      >
        <div
          class="progress mb-4"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="progress-bar"
            :style="'width: ' + actual_step + '%'"
          ></div>
        </div>
        <span class="text-primary h4 d-flex align-items-center">
          <button
            @click="handleStep(0)"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-2"
          >
            <i class="fa fa-arrow-left"></i>
          </button>

          <span>
            <span class="text-primary h4"
              >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
              <span class="small">- * Campos obrigatórios</span></span
            >
          </span>
        </span>
        <br />
        <h4 class="text-gray font-weight-light">Informações adicionais*</h4>
        <div class="row">
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-faturamento-mensal" class="form-label"
              >Faturamento mensal
              <span v-tooltip="'Qual a sua expectativa de faturamento mensal?'"
                ><i class="fas fa-info-circle"></i> </span
            ></label>

            <base-select
              :options="[
                'Até R$ 25.000,00/mês',
                'De R$ 25.000,00 a R$ 99.999,99/mês',
                'Acima de R$ 100.000,00/mês',
              ]"
              title="faturamento mensal*"
              :show_title="false"
              id="input-faturamento-mensal"
              @update-selected="step_4.monthly_billing = $event"
              aria-describedby="input-faturamento-mensal"
              :selected_id="step_4.monthly_billing"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-partners" class="form-label"
              >Número de sócios*</label
            >
            <input
              type="number"
              id="input-partners"
              class="form-control"
              aria-describedby="input-partners"
              placeholder="Informe aqui o número de sócios"
              v-model="step_4.partners_count"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-partners" class="form-label"
              >Número de colaboradores CLT*</label
            >
            <input
              type="number"
              id="input-partners"
              class="form-control"
              aria-describedby="input-partners"
              placeholder="Informe aqui o número de sócios"
              v-model="step_4.employees_count"
            />
          </div>
        </div>
        <span class="text-center mt-4">
          <button
            @click="handleStep(1)"
            class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
          >
            <span class="mx-4">Salvar</span>
          </button>
        </span>
      </div>
      <!-- Step 5 in case this.step_1.simples_nacional || Step 6 in case não é simples mas foi solicitada adesão   -->
      <div
        class="card col-12 text-start"
        v-if="
          (this.step_1.simples_nacional && actual_step === 80) ||
          (!this.step_1.simples_nacional &&
            this.step_1.requested &&
            actual_step === 80)
        "
      >
        <div
          class="progress mb-4"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="progress-bar"
            :style="'width: ' + actual_step + '%'"
          ></div>
        </div>
        <span class="text-primary h4 d-flex align-items-center">
          <button
            @click="handleStep(0)"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-2"
          >
            <i class="fa fa-arrow-left"></i>
          </button>
          <span>
            <span class="text-primary h4"
              >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
              <span class="small">- * Campos obrigatórios</span></span
            >
          </span>
        </span>
        <br />
        <h4 class="text-gray text-disabled font-weight-light">
          Contratar um plano
        </h4>
        <div class="row mt-4">
          <div class="col-lg-6 col-sm-12 text-primary">
            <label for="input-faturamento-mensal" class="form-label"
              >Faturamento mensal
              <span v-tooltip="'Qual a sua expectativa de faturamento mensal?'"
                ><i class="fas fa-info-circle"></i> </span
            ></label>

            <base-select
              :options="[
                'Até R$ 25.000,00/mês',
                'De R$ 25.000,00 a R$ 99.999,99/mês',
                'Acima de R$ 100.000,00/mês',
              ]"
              title="faturamento mensal*"
              :show_title="false"
              id="input-faturamento-mensal"
              @update-selected="step_4.monthly_billing = $event"
              aria-describedby="input-faturamento-mensal"
              :selected_id="step_4.monthly_billing"
            />
          </div>
        </div>
        <div class="row my-4">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <label for="input-partners" class="form-label"
              >Número de sócios</label
            >
            <input
              type="number"
              id="input-partners"
              class="form-control"
              aria-describedby="input-partners"
              placeholder="Informe aqui o número de sócios"
              v-model="step_4.partners_count"
              :disabled="true"
              min="0"
            />
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <label for="input-partners" class="form-label"
              >Número de colaboradores CLT*</label
            >
            <input
              type="number"
              id="input-partners"
              class="form-control"
              aria-describedby="input-partners"
              placeholder="Informe aqui o número de sócios"
              v-model="step_4.employees_count"
              min="0"
            />
          </div>
        </div>
        <div class="card p-4">
          <div class="row">
            <div
              class="col-lg-12 col-md-12 col-sm-12"
              v-for="(plan, index) in plans"
              :key="'plan' + index"
            >
              <div class="card p-4 text-center">
                <div class="h2 text-primary font-weight-bold">
                  Plano {{ plan.title }}
                </div>
                <div class="d-flex justify-content-center">
                  <span class="badge badge-plan bg-secondary p-3"
                    ><span class="badge-plan-font-start font-weight-light"
                      >R$</span
                    ><span class="font-weight-bold bold">
                      {{ plan.value }}
                      <span v-if="plan.value !== step_5.plan_total">
                        <i class="fas fa-right-long mx-2" />
                        {{ step_5.plan_total }}</span
                      ><span class="badge-plan-font-end font-weight-light"
                        >mês</span
                      ></span
                    ></span
                  >
                </div>
                <div class="mt-3 desc">{{ plan.description }}</div>
                <div class="d-flex justify-content-center">
                  <button
                    @click="handleSubmmitPlan(plan)"
                    class="mt-3 plan-btn btn px-4 font-weight-bold btn-primary text-white btn-shadow"
                  >
                    <span class="mx-4">Assinar</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 ps-4 mt-4">
              <div class="h3 text-primary">Os planos <b>incluem</b>:</div>
              <div class="mt-2 ms-4">
                <ul class="list-group list-group-flush">
                  <li class="">Até 2 sócios</li>
                  <li class="">Contabilidade completa</li>
                  <li class="">Atendimento completo por contadores</li>
                  <li class="">
                    Adicional de R$ 30,00 por sócio a partir do 3º sócio
                  </li>
                  <li class="">Adicional de R$ 40,00 por funcionário</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Step 2 in case !this.step_1.simples_nacional -->
      <div v-if="!this.step_1.simples_nacional">
        <div
          class="card col-12 text-start"
          v-if="
            (step_1.requested && actual_step === 16) ||
            (!step_1.requested && actual_step === 45)
          "
        >
          <div
            class="progress mb-4"
            role="progressbar"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              class="progress-bar"
              :style="'width: ' + actual_step + '%'"
            ></div>
          </div>
          <span>
            <span class="text-primary h4"
              >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
              <span class="small">- * Campos obrigatórios</span></span
            >
          </span>
          <br />
          <h4 class="h1 text-primary text-center font-weight-bold mb-4">
            Você já solicitou adesão ao Simples Nacional?
          </h4>
          <div class="row mt-4">
            <div class="col-6 text-center">
              <div class="d-flex justify-content-end">
                <div class="card p-4 width-50">
                  <i class="fas fa-solid fa-check fa-5x text-primary"></i>
                  <br />
                  <h4 class="text-primary">Sim</h4>
                  <div class="d-flex justify-content-center">
                    <button
                      @click="
                        step_1.requested = true;
                        handleStep(1);
                      "
                      class="card-btn btn font-weight-bold btn-primary mt-4 text-white btn-shadow"
                    >
                      Selecionar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 text-center">
              <div class="d-flex justify-content-start">
                <div class="card p-4 width-50">
                  <i class="fas fa-solid fa-xmark fa-5x text-primary"></i>
                  <br />
                  <h4 class="text-primary">Não</h4>
                  <div class="d-flex justify-content-center">
                    <button
                      @click="
                        step_1.requested = false;
                        handleStep(1);
                      "
                      class="card-btn btn font-weight-bold btn-primary mt-4 text-white btn-shadow"
                    >
                      Selecionar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Step 3 in case não é simples e não foi solicitada adesão -->
        <div
          class="card col-12 text-start"
          v-if="!step_1.requested && actual_step === 90"
        >
          <div
            class="progress mb-4"
            role="progressbar"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              class="progress-bar"
              :style="'width: ' + actual_step + '%'"
            ></div>
          </div>
          <span>
            <span class="text-primary h4"
              >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
            </span>
          </span>
          <br />
          <h4 class="h1 text-primary text-center font-weight-bold mb-4">
            Agende uma reunião com um de nossos contadores para que possamos
            compreender sua situação e direcioná-lo(a) pelo o melhor caminho!
          </h4>
          <span class="text-secondary pointer">
            <span v-tooltip="'Conversar no whatsapp'">
              <a
                class="text-secondary fw-bold"
                href="https://wa.me/send?phone=55996789511&text=Olá.%20Estou%20entrando%20em%20contato%20pois%20gostaria%20de%20agendar%20uma%20reunião%20para%20realizar%20o%20processo%20de%20migração%20da%20minha%20contabilidade."
                target="_blank"
                ><i class="fa-brands fa-whatsapp me-2 fa-xl"></i
              ></a>
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseSelect from "../../components/BaseSelect.vue";
import api from "@/services/api";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "migrate-contability",
  components: {
    BaseSelect,
  },
  data() {
    return {
      token: null,
      actual_step: 0,
      user: {
        email: "",
        phone: "",
      },
      steps: {
        0: "Parte 1", // É simples?
        20: "Parte 2", // Informar CNPJ
        40: "Parte 3", // Exibir dados CNPJ
        60: "Parte 4", // Info add
        80: "Parte 5", // Plano
      },
      step_1: {
        simples_nacional: "",
        requested: "",
      },
      step_2: {
        cnpj_cpf: "",
      },
      step_3: {
        email: "",
        phone: "",
        activity_branch: "",
        name: "",
        secondary_name: "",
        cnae: "",
        social_capital: "",
        legal_nature: "",
        business_attachment: "",
        partners_count: 1,
        postal_code: "",
        street: "",
        neighborhood: "",
        city: "",
        uf: "",
        number: "",
        complement: "",
      },
      step_4: {
        partners_count: 1,
        employees_count: 0,
        monthly_billing: "",
      },
      step_5: {
        plan_total: 0,
        plan: "",
      },
      template_socio: {
        fullname: "",
        mother_name: "",
        father_name: "",
        cpf: "",
        date_birth: "",
        rg_number: "",
        rg_emitter: "",
        rg_date: "",
        birth_city: "",
        ethnicity: "",
        profission: "",
        phone: "",
        email: "",
        postal_code: "", // on table andresses
        street: "", // on table andresses
        neighborhood: "", // on table andresses
        city: "", // on table andresses
        uf: "", // on table andresses
        number: "", // on table andresses
        complement: "", // on table andresses
      },
      plans: [
        {
          title: "Start",
          value: 149.9,
          description: "Faturamento de até R$ 25.000,00/mês",
        },
        {
          title: "Plus",
          value: 249.9,
          description: "Faturamento de até R$ 100.000,00/mês",
        },
        {
          title: "Escala",
          value: 399.9,
          description: "Faturamento de até R$ 100.000,00/mês",
        },
      ],
      etnias: [
        "Pardo(a)",
        "Branco(a)",
        "Negro(a)",
        "Indígena(a)",
        "Amarelo(a)",
      ],
      genders: ["Feminino", "Masculino", "Outro"],
      marital_statuses: [
        "Solteiro(a)",
        "Casado(a)",
        "Viúvo(a)",
        "Separado(a)",
        "Divorciado(a)",
      ],
      property_regimes: [
        "Comunhão Parcial de Bens",
        "Comunhão Universal de Bens",
        "Separação Legal de Bens",
        "Participação Final nos Aquestos",
      ],
      areas: [
        {
          name: "Advogado",
          value: 1,
        },
        {
          name: "Desenvolvedor de Software",
          value: 2,
        },
        {
          name: "Profissional da saúde",
          value: 3,
        },
        {
          name: "Arquiteto",
          value: 4,
        },
        {
          name: "Vendedor/Representante Comercial",
          value: 5,
        },
        {
          name: "Corretor de imóveis",
          value: 6,
        },
        {
          name: "Outro",
          value: 7,
        },
      ],
    };
  },
  async mounted() {
    this.month = new Date().getMonth();
    this.year = new Date().getFullYear();
    await this.loadCompany(true);
  },
  methods: {
    handleSteps() {
      if (this.step_1.simples_nacional) {
        // É simples nacional
        this.steps = {
          0: "Parte 1", // É simples?
          20: "Parte 2", // Informar CNPJ
          40: "Parte 3", // Exibir dados CNPJ
          60: "Parte 4", // Info add
          80: "Parte 5", // Plano
        };
      } else {
        // Não é simples nacional
        if (this.step_1.requested) {
          // Foi solicitada adesão
          this.steps = {
            0: "Parte 1", // É simples?
            16: "Parte 2", // Já solicitou adesão?
            32: "Parte 3", // Informar CNPJ
            48: "Parte 4", // Exibir dados CNPJ
            64: "Parte 5", // Info add
            80: "Parte 6", // Plano
          };
        } else {
          // Não foi solicitada adesão
          this.steps = {
            0: "Parte 1", // É simples?
            45: "Parte 2", // Já solicitou adesão?
            90: "Parte 3", // Agendar com contador
          };
        }
      }
    },
    handleSocios() {
      this.step_5.partners = [];
      for (let i = 0; i < this.step_3.partners_count; i++) {
        this.step_5.partners.push(this.template_socio);
      }
    },
    async handleStep(type) {
      this.handleSteps();
      let value = 0;
      const step = this.step_1;
      if (step.simples_nacional) {
        value = 20;
      } else {
        if (step.simples_nacional === 0) this.actual_step = 0;
        if (!step.requested) {
          if (this.actual_step === 16) this.actual_step = 45;
          value = 45;
        } else if (step.requested) {
          if (this.actual_step === 45) this.actual_step = 16;
          value = 16;
        }
      }
      if (type) {
        await this.handleSubmmit();
        this.actual_step += value;
      } else {
        await this.handleSubmmit();
        this.actual_step -= value;
      }
    },
    async getToken() {
      try {
        const { data } = await api.get(`/cnpj-token`);
        if (!data) {
          const { dt } = await api.post(`/cnpj-token`);
          this.token = dt;
        } else this.token = data;
        await this.getCNPJData();
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar ao recuperar o token para buscar o seu CNPJ. Retorne um passo e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async getCNPJData() {
      // step_2 = cnpj
      // step_3 = cnpj data
      try {
        const { data } = await api.post(`/cnpj-token/${this.step_2.cnpj_cpf}`, {
          token: this.token.access_token,
        });
        this.step_3.email = data.email;
        this.step_3.phone = data.phone;
        this.step_3.name = data.name;
        this.step_3.secondary_name = data.secondary_name;
        this.step_3.cnae = data.cnae;
        this.step_3.social_capital = data.social_capital;
        this.step_3.legal_nature = data.legal_nature;
        this.step_3.activity_branch = data.activity_branch;
        this.step_3.postal_code = data.postal_code;
        this.step_3.street = data.street;
        this.step_3.neighborhood = data.neighborhood;
        this.step_3.city = data.city;
        this.step_3.uf = data.uf;
        this.step_3.number = data.number;
        this.step_3.complement = data.complement;
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar ao recuperar o token para buscar o seu CNPJ. Retorne um passo e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async loadCompany(mounted) {
      try {
        const { data } = await api.get(`/company/company-pending`);
        if (data && data.company) this.company = data.company;

        if (mounted && data.company) {
          //this.getSelectedArea(data.company.area);
          this.step_2.cnpj_cpf = data.company.cnpj_cpf;
          this.step_3.name = data.company.name;
          this.step_3.secondary_name = data.company.secondary_name;
          this.step_3.cnae = data.company.cnae;
          this.step_3.social_capital = data.company.social_capital;
          this.step_3.legal_nature = parseInt(data.company.legal_nature);
          this.step_3.partners_count = 1;
          if (data.company && data.company.andress) {
            this.step_2 = {
              ...this.step_2,
              ...{
                postal_code: data.company.andress.postal_code,
                street: data.company.andress.street,
                neighborhood: data.company.andress.neighborhood,
                city: data.company.andress.city,
                uf: data.company.andress.uf,
                number: data.company.andress.number,
                complement: data.company.andress.complement,
              },
            };
            if (data.company.partners.length > 1) {
              this.step_5.partners = data.company.partners;
            } else this.step_5.partners = data.company.partners[0];
            if (
              data.company &&
              data.company.partners[0] &&
              data.company.partners[0].andress
            ) {
              const andress = data.company.partners[0].andress;
              this.step_5.postal_code = andress.postal_code;
              this.step_5.street = andress.street;
              this.step_5.neighborhood = andress.neighborhood;
              this.step_5.city = andress.city;
              this.step_5.uf = andress.uf;
              this.step_5.number = andress.number;
              /* this.step_6.phone = data.company.phone;
              this.step_6.email = data.company.email; */
            }
          }
          this.getSelectedMonthlyBilling(data.company.monthly_billing);
        }
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações da empresa. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async handleSubmmitPlan(plan) {
      await this.loadCompany();
      if (this.company) {
        const dt = {
          company: this.company,
          plan: {
            ...plan,
            plan_total: this.step_5.plan_total,
            partners_count: this.step_4.partners_count,
            employees_count: this.step_4.employees_count,
            type: 1, // ja tenho empresa
          },
        };
        try {
          await api.post(`/company/plan/`, dt);
          toast.success("Plano contratado com sucesso!");
          this.$router.push(`/cliente/dashboard`);
        } catch (e) {
          let message = "Ocorreu um erro ao salvar informações";
          if (e.response.data.message) message = e.response.data.message;
          toast.error(message);
        }
      } else {
        toast.error(
          "Termine de preencher seu cadastro para contratar um plano."
        );
      }
    },
    async handleSubmmit() {
      const monthly_bills = [
        "Até R$ 25.000,00/mês",
        "De R$ 25.000,00 a R$ 99.999,99/mês",
        "Acima de R$ 100.000,00/mês",
      ];
      await this.loadCompany();
      if (!this.company) {
        try {
          const formatedData = {
            ...this.step_1,
            ...this.step_2,
            ...this.step_3,
            ...this.step_4,
            ...this.step_5,
          };
          if (this.step_4.monthly_billing) {
            formatedData.monthly_billing =
              monthly_bills[this.step_4.monthly_billing - 1];
          }
          const { data } = await api.post("/company/migrate", {
            ...formatedData,
          });
          this.company = data;
          toast.success("Informações salvas com sucesso!");
        } catch (e) {
          let message = "Ocorreu um erro ao salvar informações";
          if (e.response.data.message) message = e.response.data.message;
          toast.error(message);
        }
      } else {
        try {
          let formatedData = this[this.getUrlStep()];
          const monthly_bills = [
            "Até R$ 25.000,00/mês",
            "De R$ 25.000,00 a R$ 99.999,99/mês",
            "Acima de R$ 100.000,00/mês",
          ];
          formatedData = {
            ...this.step_1,
            ...this.step_2,
            ...this.step_3,
            ...this.step_4,
            ...this.step_5,
          };
          formatedData.monthly_billing =
            monthly_bills[this.step_4.monthly_billing];
          formatedData.simple_national = this.step_1.simples_nacional
            ? "Sim"
            : "Não";
          formatedData.social_capital = formatedData.social_capital
            ? formatedData.social_capital.toString()
            : "";
          const { data } = await api.put(
            `/company/migrate/${this.company.id}`,
            formatedData
          );
          this.company = data;
          toast.success("Informações salvas com sucesso!");
        } catch (e) {
          let message = "Ocorreu um erro ao salvar informações";
          if (e.response.data.message) message = e.response.data.message;
          toast.error(message);
        }
      }
    },
    getUrlStep() {
      if (this.actual_step === 0) return "step_1";
      else if (this.actual_step === 20) return "step_2";
      else if (this.actual_step === 40) return "step_3";
      else if (this.actual_step === 60) return "step_4";
      else if (this.actual_step === 80 && this.step_1.simples_nacional)
        return "step_5";
      else if (this.actual_step === 16) return "step_1";
      else if (this.actual_step === 32) return "step_2";
      else if (this.actual_step === 48) return "step_3";
      else if (this.actual_step === 64) return "step_4";
      else if (
        this.actual_step === 80 &&
        !this.step_1.simples_nacional &&
        this.step_1.requested
      )
        return "step_5";
      else if (this.actual_step === 45) return "step_2";
      else if (this.actual_step === 90) return "step_5";
    },
    getSelectedMonthlyBilling(dt_mb) {
      const monthly_bills = [
        "Até R$ 25.000,00/mês",
        "De R$ 25.000,00 a R$ 99.999,99/mês",
        "Acima de R$ 100.000,00/mês",
      ];
      if (dt_mb) {
        monthly_bills.forEach((mb, index) => {
          if (dt_mb === mb) {
            this.step_4.monthly_billing = index;
          }
        });
      }
    },
    handlePlans() {
      if (this.step_4.monthly_billing - 1 === 0) {
        this.plans = [
          {
            title: "Start",
            value: 149.9,
            description: "Faturamento de até R$ 25.000,00/mês",
          },
        ];
      } else if (this.step_4.monthly_billing - 1 === 1) {
        this.plans = [
          {
            title: "Plus",
            value: 249.9,
            description: "Faturamento de até R$ 100.000,00/mês",
          },
        ];
      } else if (this.step_4.monthly_billing - 1 === 2) {
        this.plans = [
          {
            title: "Escala",
            value: 399.9,
            description: "Faturamento de até R$ 100.000,00/mês",
          },
        ];
      }
      this.step_5.plan = this.plans[0];
    },
  },
  watch: {
    "step_1.simples_nacional": function () {
      this.handleSteps();
    },
    "step_1.requested": function () {
      this.handleSteps();
    },
    "step_4.employees_count": function () {
      let extra_partners = this.step_4.partners_count - 3;
      if (extra_partners <= 0) extra_partners = 0;
      if (this.step_5.plan) {
        this.step_5.plan_total =
          this.step_5.plan.value +
          extra_partners * 30 +
          this.step_4.employees_count * 40;
      }
    },
    "step_4.monthly_billing": function () {
      this.handlePlans();
      let extra_partners = this.step_4.partners_count - 3;
      if (extra_partners <= 0) extra_partners = 0;
      if (this.step_5.plan) {
        this.step_5.plan_total =
          this.step_5.plan.value +
          extra_partners * 30 +
          this.step_4.employees_count * 40;
      }
    },
    actual_step: function () {
      let extra_partners = this.step_4.partners_count - 3;
      if (extra_partners <= 0) extra_partners = 0;
      if (this.step_5.plan) {
        this.step_5.plan_total =
          this.step_5.plan.value +
          extra_partners * 30 +
          this.step_4.employees_count * 40;
      }
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}
.card {
  border-radius: 20px;
  padding: 40px;
}
.btn {
  border-radius: 40px;
}
.form-check,
.text-gray {
  color: #545454;
}
.form-check-input {
  width: 25px;
  height: 25px;
}
.form-check-label {
  margin-top: 5px;
  margin-left: 5px;
}
.progress {
  border-radius: 20px;
}
.bold {
  font-weight: bold;
}

.card-select {
  transition: 0.3s;
}

.card-select:hover {
  transition: 0.3s;
}
.card-select:hover {
  transition: 0.3s;
  transform: translateY(-7px);
  cursor: pointer;
}
.card-select.selected {
  transition-property: border;
  transition-duration: 0.3s;
  border: 2px #342f86 solid;
}
.form-check-input[data-v-90d5440a] {
  width: 4em;
  height: 2em;
}
.badge-plan-font-start {
  font-size: 20px;
  font-weight: 500;
  display: inline-block;
  vertical-align: top;
}
.badge-plan-font-end {
  font-size: 20px;
  font-weight: 500;
}
.plan-btn,
.badge-plan,
.card-btn,
.width-50 {
  width: 60%;
}
.badge-plan {
  font-size: 35px;
  border-radius: 20px;
  color: #342f86;
}
.bold {
  font-weight: bold;
}
.rounded {
  border-radius: 20px;
}
.desc {
  font-size: 22px;
}
</style>
