<template>
  <div class="select">
    <div
      class="select-overlay"
      :class="showOptions ? 'show' : 'hide'"
      @click="showOptions = false"
    ></div>
    <label class="text-muted fw-light" v-if="show_title"
      ><small class="text-capitalize">{{ title }}</small></label
    >
    <select class="form-select mb-3" id="select-input" v-model="selected">
      <option selected disabled value="">Selecione o(a) {{ title }}</option>
      <option
        :value="option.id"
        v-for="(option, index) in select_options"
        :key="index"
      >
        {{ option.fullname || "Nome do sócio não informado" }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  name: "select-partner",
  props: {
    options: Array,
    placeholder: String,
    title: String,
    show_title: Boolean,
    selected_id: null,
  },
  data() {
    return {
      select_options: [],
      showOptions: false,
      selected: "",
    };
  },
  mounted() {
    this.select_options = this.options;
    if (this.selected_id !== undefined) this.selected = this.selected_id;
  },
  watch: {
    options: function () {
      this.select_options = this.options;
      if (this.selected_id !== undefined) this.selected = this.selected_id;
    },
    selected: function () {
      this.$emit("update-selected", this.selected);
    },
    selected_id: function () {
      if (this.selected_id !== undefined) this.selected = this.selected_id;
    },
  },
};
</script>
<style scoped>
.form-select {
  cursor: pointer;
}
</style>
