<template>
  <div>
    <div class="px-4">
      <div class="body p-4">
        <span class="text-primary h2 d-flex align-items-center">
          <button
            @click="$router.push('/cliente/pagamentos-e-despesas')"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
          >
            <i class="fa fa-arrow-left"></i>
          </button>
          <span class="text-gray"> Cadastrar Pagamento/Despesa</span>
        </span>
        <!-- Pagamentos e despesas -->
        <div class="card p-4 mt-4">
          <div class="p-2">
            <!-- <h4 class="text-gray font-weight-light">Novo Despesa</h4> -->
            <div class="row text-start">
              <div class="col-lg-6 col-md-6 col-sm-12 text-primary mb-3">
                <label for="input-cod" class="form-label mb-2">Nome*</label>
                <input
                  type="text"
                  id="input-name"
                  class="form-control"
                  aria-describedby="input-name"
                  placeholder="Informe aqui o título da despesa/pagamento"
                  v-model="model.name"
                />
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 text-primary mb-4">
                <label for="input-date_due" class="form-label mb-2"
                  >Data de vencimento*</label
                >
                <input
                  type="date"
                  id="input-date_due"
                  class="form-control"
                  aria-describedby="input-date_due"
                  v-model="model.date_due"
                />
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 text-primary mb-4">
                <label for="input-price" class="form-label mb-2">Valor*</label>
                <input
                  type="text"
                  id="input-price"
                  class="form-control"
                  aria-describedby="input-price"
                  placeholder="Digite o valor"
                  v-model="formatedPrice"
                  @input="formatPrice"
                />
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 text-primary mb-4">
                <label for="input-payday" class="form-label mb-2"
                  >Data do pagamento</label
                >
                <input
                  type="date"
                  id="input-payday"
                  class="form-control"
                  aria-describedby="input-payday"
                  v-model="model.payday"
                />
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 text-primary mb-4">
                <label for="input-price_paid" class="form-label mb-2"
                  >Valor pago</label
                >
                <input
                  type="text"
                  id="input-price"
                  class="form-control"
                  aria-describedby="input-price_paid"
                  placeholder="Digite o valor"
                  v-model="formatedPricePaid"
                  @input="formatPricePaid"
                />
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 text-primary mb-4">
                <label for="input-price_paid" class="form-label mb-2"
                  >Arquivo*</label
                >
                <br />
                <base-file
                  :value="document"
                  title="Selecione o documento"
                  accept=""
                  :mbSize="50"
                  type="secondary text-white"
                  @input-upload="document = $event"
                />
              </div>
            </div>
            <span class="text-center mt-4">
              <button
                @click="submmitForm()"
                class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
              >
                <span class="mx-4">Cadastrar</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import { useToast } from "vue-toastification";
import BaseFile from "@/components/BaseFile.vue";
const toast = useToast();
export default {
  name: "expenses-form",
  components: { BaseFile },
  data() {
    return {
      expenses: [],
      document: "",
      model: {
        name: "",
        origin: "",
        price_paid: "",
        price: "",
        date_due: "",
        payday: "",
      },
    };
  },
  computed: {
    formatedPrice() {
      if (!this.model.price) return "";
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      })
        .format(this.model.price / 100)
        .replace("R$", "")
        .trim();
    },
    formatedPricePaid() {
      if (!this.model.price_paid) return "";
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      })
        .format(this.model.price_paid / 100)
        .replace("R$", "")
        .trim();
    },
  },
  methods: {
    formatPrice(event) {
      const value = event.target.value.replace(/[^\d]/g, "");
      this.model.price = parseInt(value || "0", 10);
      event.target.value = this.formatedPrice;
      setTimeout(() => {
        event.target.setSelectionRange(
          event.target.value.length,
          event.target.value.length
        );
      });
    },
    updatePrice() {
      var options = { style: "currency", currency: "BRL" };
      var formatter = new Intl.NumberFormat("pt-BR", options);
      var result = formatter.format(this.model.price);
      this.amount_preview = result;
      return result;
    },
    formatPricePaid(event) {
      const value = event.target.value.replace(/[^\d]/g, "");
      this.model.price_paid = parseInt(value || "0", 10);
      event.target.value = this.formatedPricePaid;
      setTimeout(() => {
        event.target.setSelectionRange(
          event.target.value.length,
          event.target.value.length
        );
      });
    },
    updatePricePaid() {
      var options = { style: "currency", currency: "BRL" };
      var formatter = new Intl.NumberFormat("pt-BR", options);
      var result = formatter.format(this.model.price_paid);
      this.amount_preview = result;
      return result;
    },
    async submmitForm() {
      try {
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const formData = new FormData();
        for (const att of Object.getOwnPropertyNames(this.model)) {
          if (this.model[att]) {
            if (att === "price" || att === "price_paid") {
              formData.append(att, this.model[att] / 100);
            } else {
              formData.append(att, this.model[att]);
            }
          }
        }
        formData.append("month", new Date(this.model.date_due).getMonth() + 1);
        formData.append("year", new Date(this.model.date_due).getFullYear());
        formData.append("document", this.document);
        const { data } = await api.post(`/company/expenses`, formData, config);
        this.expenses = data;
        toast.success("Pagamento cadastrado com sucesso");
        this.$router.push("/cliente/pagamentos-e-despesas");
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações dos pagamentos e despesas. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
  },
};
</script>
<style scoped>
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
</style>
