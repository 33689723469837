<template>
  <div>
    <div class="px-4">
      <div class="body p-4">
        <span class="text-primary h2 d-flex align-items-center">
          <button
            @click="$router.push('/cliente/pagamentos-e-despesas')"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
          >
            <i class="fa fa-arrow-left"></i>
          </button>
          <span class="text-gray"> Pagamento/Despesa</span>
        </span>
        <!-- Pagamentos e despesas -->
        <div class="row">
          <div class="col-lg-8">
            <div class="card p-4 mt-4">
              <div class="p-2">
                <!-- <h4 class="text-gray font-weight-light">Novo Despesa</h4> -->
                <div class="row text-start">
                  <div class="col-lg-6 col-sm-12 text-primary">
                    <label for="input-cod" class="form-label">Nome*</label>
                    <input
                      type="text"
                      id="input-name"
                      class="form-control"
                      aria-describedby="input-name"
                      placeholder="Informe aqui o nome da despesa"
                      v-model="expense.name"
                    />
                  </div>
                  <!-- <div class="col-lg-6 col-md-6 col-sm-12 text-primary">
                    <label for="input-reason" class="form-label">Tipo*</label>
                    <base-select
                      :options="['Operacional', 'Não Operacional']"
                      :selected_id="expense.origin"
                      title="Tipo*"
                      :show_title="false"
                      id="input-reason"
                      @update-selected="expense.origin = $event"
                      aria-describedby="input-reason"
                    />
                  </div> -->
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-date_due" class="form-label mb-2"
                      >Data de vencimento*</label
                    >
                    <input
                      type="date"
                      id="input-date_due"
                      class="form-control"
                      aria-describedby="input-date_due"
                      v-model="expense.date_due"
                    />
                  </div>

                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-price" class="form-label mb-2"
                      >Valor*</label
                    >
                    <input
                      type="text"
                      id="input-price"
                      class="form-control"
                      aria-describedby="input-price"
                      placeholder="Digite o valor"
                      v-model="formatedPrice"
                      @input="formatPrice"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-payday" class="form-label mb-2"
                      >Data do pagamento</label
                    >
                    <input
                      type="date"
                      id="input-payday"
                      class="form-control"
                      aria-describedby="input-payday"
                      v-model="expense.payday"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-price_paid" class="form-label mb-2"
                      >Valor pago</label
                    >
                    <input
                      type="text"
                      id="input-price"
                      class="form-control"
                      aria-describedby="input-price_paid"
                      placeholder="Digite o valor"
                      v-model="formatedPricePaid"
                      @input="formatPricePaid"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-price_paid" class="form-label mb-2"
                      >Arquivo*</label
                    >
                    <br />
                    <base-file
                      :value="document"
                      title="Selecione o documento"
                      accept=""
                      :mbSize="50"
                      type="secondary text-white"
                      @input-upload="document = $event"
                    />
                  </div>
                </div>
                <span class="text-center mt-4">
                  <button
                    @click="submmitForm()"
                    class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
                  >
                    <span class="mx-4">Salvar</span>
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card mt-4 cursor-auto">
              <div class="d-flex justify-content-center">
                <span
                  class="mb-4 rounded-circle shadow mt-n5"
                  style="width: min-content; padding: 2rem"
                >
                  <i class="fa fa-wallet fa-5x text-secondary"></i>
                </span>
              </div>
              <div class="row text-center">
                <div class="col-12 h4">
                  <b>{{ expense.name }}</b>
                </div>
                <div class="col-12">
                  <b>Tipo:</b> {{ expense.origin || "Não informada" }}
                </div>
                <div class="col-12">
                  <b>Vencimento:</b> {{ expense.date_due || "Não informada" }}
                </div>
                <div class="col-12">
                  <b>Valor:</b> {{ expense.price || "Não informada" }}
                </div>
                <div class="col-12" v-if="expense.is_paid">
                  <b>Pagamento:</b> {{ expense.payday || "Não informada" }}
                </div>
                <div class="col-12" v-if="expense.is_paid">
                  <b>Valor pago:</b> {{ expense.price_paid || "Não informada" }}
                </div>
                <span class="d-flex justify-content-center">
                  <hr class="my-4" style="width: 90%" />
                </span>
                <span class="d-flex justify-content-center">
                  <span v-tooltip.bottom="'Remover pagamento/despesa'">
                    <button
                      class="btn btn-light shadow-sm"
                      @click="$router.push(`/cliente/bancos/editar/${bank.id}`)"
                    >
                      <i class="fas fa-trash text-danger"></i>
                    </button>
                  </span>
                  <span class="ms-3" v-tooltip.bottom="'Baixar arquivo'">
                    <button class="btn btn-light shadow-sm">
                      <i class="fas fa-download text-primary"></i>
                    </button>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import { useToast } from "vue-toastification";
import BaseFile from "@/components/BaseFile.vue";
const toast = useToast();
export default {
  name: "expenses-form",
  components: { BaseFile },
  data() {
    return {
      expenses: [],
      document: "",
      expense: "",
    };
  },
  async mounted() {
    await this.loadExpense();
  },
  computed: {
    formatedPrice() {
      if (!this.expense.price) return "";
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      })
        .format(this.expense.price / 100)
        .replace("R$", "")
        .trim();
    },
    formatedPricePaid() {
      if (!this.expense.price_paid) return "";
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      })
        .format(this.expense.price_paid / 100)
        .replace("R$", "")
        .trim();
    },
  },
  methods: {
    formatPrice(event) {
      const value = event.target.value.replace(/[^\d]/g, "");
      this.expense.price = parseInt(value || "0", 10);
      event.target.value = this.formatedPrice;
      setTimeout(() => {
        event.target.setSelectionRange(
          event.target.value.length,
          event.target.value.length
        );
      });
    },
    updatePrice() {
      var options = { style: "currency", currency: "BRL" };
      var formatter = new Intl.NumberFormat("pt-BR", options);
      var result = formatter.format(this.expense.price);
      this.amount_preview = result;
      return result;
    },
    formatPricePaid(event) {
      const value = event.target.value.replace(/[^\d]/g, "");
      this.expense.price_paid = parseInt(value || "0", 10);
      event.target.value = this.formatedPricePaid;
      setTimeout(() => {
        event.target.setSelectionRange(
          event.target.value.length,
          event.target.value.length
        );
      });
    },
    updatePricePaid() {
      var options = { style: "currency", currency: "BRL" };
      var formatter = new Intl.NumberFormat("pt-BR", options);
      var result = formatter.format(this.expense.price_paid);
      this.amount_preview = result;
      return result;
    },
    async loadExpense() {
      try {
        const { data } = await api.get(
          `/company/expenses/${this.$route.params.id}`
        );
        const formatedDate = Object.assign({}, data);
        formatedDate.payday = this.formatDate(data.payday);
        formatedDate.date_due = this.formatDate(data.date_due);
        this.expense = formatedDate;
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações do pagamento. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    formatDate(date) {
      const newDate = new Date(date);
      const year = newDate.getUTCFullYear();
      let day = newDate.getUTCDate();
      let month = newDate.getUTCMonth() + 1;
      if (month < "10") {
        month = "0" + month;
      }
      if (day < "10") {
        day = "0" + day;
      }
      //yyyy-MM-dd
      return `${year}-${month}-${day}`;
    },
    async submmitForm() {
      try {
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const formData = new FormData();
        const formatedData = {
          ...this.expense,
          month: new Date(this.expense.date_due).getMonth() + 1,
          year: new Date(this.expense.date_due).getFullYear(),
        };
        for (const att of Object.getOwnPropertyNames(formatedData)) {
          if (formatedData[att]) {
            if (att === "price" || att === "price_paid") {
              formData.append(att, formatedData[att] / 100);
            } else {
              formData.append(att, formatedData[att]);
            }
          }
        }
        formData.append("document", this.document);
        const { data } = await api.put(
          `/company/expenses/${this.$route.params.id}`,
          formData,
          config
        );
        this.expenses = data;
        toast.success("Pagamento alterado com sucesso");
        this.$router.push("/cliente/pagamentos-e-despesas");
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações das despesas/pagamentos. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
  },
};
</script>
<style scoped>
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
.rounded-circle {
  background-color: rgb(2, 0, 125);
}
</style>
