<template>
  <div class="container">
    <div class="row pt-4 text-start">
      <span class="text-primary h2 d-flex align-items-center">
        <button
          @click="$router.push('/cliente/area-do-mei')"
          class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
        >
          <i class="fa fa-arrow-left"></i>
        </button>
        <span class="text-gray"> <b>Opção:</b> Quero migrar minha MEI</span>
      </span>
      <p>
        Por favor, responda às perguntas a seguir para que possamos lhe
        encaminhar corretamente.
      </p>
    </div>
    <div class="row mt-4">
      <!-- Step 1 -->
      <div class="card col-12 text-start" v-if="actual_step === 0">
        <div
          class="progress mb-4"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="progress-bar"
            :style="'width: ' + actual_step + '%'"
          ></div>
        </div>
        <span>
          <span class="text-primary h4"
            >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
            <span class="small">- * Campos obrigatórios</span></span
          >
        </span>
        <br />
        <h4 class="h1 text-primary text-center font-weight-bold mb-4">
          A solicitação de desenquadramento já foi solicitada?
        </h4>
        <div class="row mt-4">
          <div class="col-6 text-center">
            <div class="d-flex justify-content-end">
              <div class="card p-4 width-50">
                <i class="fas fa-solid fa-check fa-5x text-primary"></i>
                <br />
                <h4 class="text-primary">Sim</h4>
                <div class="d-flex justify-content-center">
                  <button
                    @click="
                      step_1.request = true;
                      handleStep(1);
                    "
                    class="card-btn btn font-weight-bold btn-primary mt-4 text-white btn-shadow"
                  >
                    Selecionar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 text-center">
            <div class="d-flex justify-content-start">
              <div class="card p-4 width-50">
                <i class="fas fa-solid fa-xmark fa-5x text-primary"></i>
                <br />
                <h4 class="text-primary">Não</h4>
                <div class="d-flex justify-content-center">
                  <button
                    @click="
                      step_1.request = false;
                      handleStep(1);
                    "
                    class="card-btn btn font-weight-bold btn-primary mt-4 text-white btn-shadow"
                  >
                    Selecionar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Step 2 -->
      <div class="card col-12 text-start" v-if="actual_step === 25">
        <div
          class="progress mb-4"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="progress-bar"
            :style="'width: ' + actual_step + '%'"
          ></div>
        </div>
        <span class="text-primary h4 d-flex align-items-center">
          <button
            @click="handleStep(0)"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-2"
          >
            <i class="fa fa-arrow-left"></i>
          </button>

          <span>
            <span class="text-primary h4"
              >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
              <span class="small">- * Campos obrigatórios</span></span
            >
          </span>
        </span>
        <br />
        <div v-if="step_1.request">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <h4 class="text-gray font-weight-light" for="input-reason">
                Qual foi o motivo informado?*
              </h4>
              <div class="text-primary mb-4">
                <base-select
                  :options="reasons"
                  title="Qual foi o motivo informado?*"
                  :show_title="false"
                  id="input-reason"
                  @update-selected="step_2.reason = $event"
                  aria-describedby="input-reason"
                  :selected_id="parseInt(step_2.reason)"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <h4 class="text-gray font-weight-light">Qual o seu CNPJ?</h4>
              <div class="col-lg-12 col-sm-12 text-primary mb-4">
                <input
                  type="text"
                  id="input-cnpj"
                  class="form-control"
                  aria-describedby="input-cnpj"
                  placeholder="Informe aqui o CNPJ da sua empresa"
                  v-model="step_2.cnpj_cpf"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <h4 class="text-gray font-weight-light">Motivo para migração</h4>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 text-primary mb-4">
              <label for="input-cnpj" class="form-label mb-2">CNPJ*</label>
              <input
                type="text"
                id="input-cnpj"
                class="form-control"
                aria-describedby="input-cnpj"
                placeholder="Informe aqui o CNPJ da sua MEI"
                v-model="step_2.cnpj_cpf"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 text-primary mb-4">
              <label for="input-reason" class="form-label"
                >Qual é o motivo para migração?*</label
              >
              <base-select
                :options="reasons"
                title="Motivo para migração*"
                :show_title="false"
                id="input-reason"
                @update-selected="step_2.reason = $event"
                aria-describedby="input-reason"
                :selected_id="parseInt(step_2.reason)"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
              <label class="form-label"
                >Você possui financiamento vinculado ao MEI?</label
              >
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="comercio-switch"
                  v-model="step_2.financing"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
              <label class="form-label"
                >Você possui plano de saúde vinculado ao MEI?</label
              >
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="comercio-switch"
                  v-model="step_2.health_plan"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
              <label class="form-label"
                >Você possui contratos vinculados ao CNPJ?
                <span v-tooltip="'Aluguel, luz, internet, etc.'">
                  <i class="fas fa-info-circle text-primary me-2"></i></span
              ></label>
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="comercio-switch"
                  v-model="step_2.contracts"
                />
              </div>
            </div>
          </div>
        </div>
        <span class="text-center">
          <button
            @click="
              handleStep(1);
              getToken();
            "
            class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
          >
            <span class="mx-4">Salvar</span>
          </button>
        </span>
      </div>
      <!-- Step 3 -->
      <div class="card col-12 text-start" v-if="actual_step === 50">
        <div
          class="progress mb-4"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="progress-bar"
            :style="'width: ' + actual_step + '%'"
          ></div>
        </div>
        <span class="text-primary h4 d-flex align-items-center">
          <button
            @click="handleStep(0)"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-2"
          >
            <i class="fa fa-arrow-left"></i>
          </button>

          <span>
            <span class="text-primary h4"
              >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
              <span class="small">- * Campos obrigatórios</span></span
            >
          </span>
        </span>
        <br />
        <h4 class="text-gray font-weight-light">Dados do CNPJ - Contato*</h4>
        <div class="row">
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-tel" class="form-label">Telefone*</label>
            <input
              type="text"
              id="input-tel"
              class="form-control"
              aria-describedby="input-tel"
              placeholder="Informe aqui o telefone"
              v-model="step_3.phone"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-tel" class="form-label">Email*</label>
            <input
              type="text"
              id="input-email"
              class="form-control"
              aria-describedby="input-email"
              placeholder="Informe aqui o email"
              v-model="step_3.email"
            />
          </div>
          <hr class="my-4" />
          <h5 class="text-gray font-weight-light">Dados do CNPJ</h5>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label class="form-label">Razão Social*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.name"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label class="form-label">Nome fantasia*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.secondary_name"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label class="form-label">CNPJ*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_2.cnpj_cpf"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label class="form-label">CNAE*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.cnae"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label class="form-label">Capital social*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.social_capital"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label class="form-label">Natureza Jurídica*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.legal_nature"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label class="form-label">Ramo de atividade*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.activity_branch"
              :disabled="true"
            />
          </div>
          <hr class="my-4" />
          <h5 class="text-gray font-weight-light">Endereço</h5>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label class="form-label">CEP*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.postal_code"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label class="form-label">Rua*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.street"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label class="form-label">Bairro*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.neighborhood"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label class="form-label">Cidade*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.city"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label class="form-label">Estado*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.uf"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label class="form-label"
              >Número*<span v-tooltip="'Informe apenas o número, sem letras'"
                ><i class="fas fa-info-circle ms-2"></i> </span
            ></label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.number"
              :disabled="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label class="form-label">Complemento*</label>
            <input
              type="text"
              class="form-control"
              v-model="step_3.complement"
              :disabled="true"
            />
          </div>
        </div>
        <span class="text-center mt-4">
          <button
            @click="handleStep(1)"
            class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
          >
            <span class="mx-4">Salvar</span>
          </button>
        </span>
      </div>
      <!-- Step 4 -->
      <div class="card col-12 text-start" v-if="actual_step === 75">
        <div
          class="progress mb-4"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="progress-bar"
            :style="'width: ' + actual_step + '%'"
          ></div>
        </div>
        <span class="text-primary h4 d-flex align-items-center">
          <button
            @click="handleStep(0)"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-2"
          >
            <i class="fa fa-arrow-left"></i>
          </button>

          <span>
            <span class="text-primary h4"
              >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
              <span class="small">- * Campos obrigatórios</span></span
            >
          </span>
        </span>
        <br />
        <h4 class="text-gray text-disabled font-weight-light">
          Contratar um plano
        </h4>
        <div class="row mt-4">
          <div class="col-lg-6 col-sm-12 text-primary">
            <label for="input-faturamento-mensal" class="form-label"
              >Faturamento mensal
              <span v-tooltip="'Qual a sua expectativa de faturamento mensal?'"
                ><i class="fas fa-info-circle"></i> </span
            ></label>

            <base-select
              :options="[
                'Até R$ 25.000,00/mês',
                'De R$ 25.000,00 a R$ 99.999,99/mês',
                'Acima de R$ 100.000,00/mês',
              ]"
              title="faturamento mensal*"
              :show_title="false"
              id="input-faturamento-mensal"
              @update-selected="step_4.monthly_billing = $event"
              aria-describedby="input-faturamento-mensal"
              :selected_id="step_4.monthly_billing"
            />
          </div>
        </div>
        <div class="row my-4">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <label for="input-partners" class="form-label"
              >Número de sócios</label
            >
            <input
              type="number"
              id="input-partners"
              class="form-control"
              aria-describedby="input-partners"
              placeholder="Informe aqui o número de sócios"
              v-model="step_4.partners_count"
              :disabled="true"
              min="0"
            />
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <label for="input-partners" class="form-label"
              >Número de colaboradores CLT*</label
            >
            <input
              type="number"
              id="input-partners"
              class="form-control"
              aria-describedby="input-partners"
              placeholder="Informe aqui o número de sócios"
              v-model="step_4.employees_count"
              min="0"
            />
          </div>
        </div>
        <div class="card p-4">
          <div class="row">
            <div
              class="col-lg-12 col-md-12 col-sm-12"
              v-for="(plan, index) in plans"
              :key="'plan' + index"
            >
              <div class="card p-4 text-center">
                <div class="h2 text-primary font-weight-bold">
                  Plano {{ plan.title }}
                </div>
                <div class="d-flex justify-content-center">
                  <span class="badge badge-plan bg-secondary p-3"
                    ><span class="badge-plan-font-start font-weight-light"
                      >R$</span
                    ><span class="font-weight-bold bold">
                      {{ plan.value }}
                      <span v-if="plan.value !== step_4.plan_total">
                        <i class="fas fa-right-long mx-2" />
                        {{ step_4.plan_total }}</span
                      ><span class="badge-plan-font-end font-weight-light"
                        >mês</span
                      ></span
                    ></span
                  >
                </div>
                <div class="mt-3 desc">{{ plan.description }}</div>
                <div class="d-flex justify-content-center">
                  <button
                    @click="handleSubmmitPlan(plan)"
                    class="mt-3 plan-btn btn px-4 font-weight-bold btn-primary text-white btn-shadow"
                  >
                    <span class="mx-4">Assinar</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 ps-4 mt-4">
              <div class="h3 text-primary">Os planos <b>incluem</b>:</div>
              <div class="mt-2 ms-4">
                <ul class="list-group list-group-flush">
                  <li class="">Até 2 sócios</li>
                  <li class="">Contabilidade completa</li>
                  <li class="">Atendimento completo por contadores</li>
                  <li class="">
                    Adicional de R$ 30,00 por sócio a partir do 3º sócio
                  </li>
                  <li class="">Adicional de R$ 40,00 por funcionário</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import { useToast } from "vue-toastification";
const toast = useToast();
import BaseSelect from "../../components/BaseSelect.vue";
export default {
  name: "migrate-contability",
  components: {
    BaseSelect,
  },
  data() {
    return {
      company: "",
      actual_step: 0,
      user: {
        email: "sample@mail.com",
        phone: "(99)9999-9999",
      },
      steps: {
        0: "Parte 1",
        25: "Parte 2",
        50: "Parte 3",
        75: "Parte 4",
      },
      step_1: {
        request: false,
      },
      step_2: {
        reason: "",
        cnpj_cpf: "",
        financing: false,
        health_plan: false,
        contracts: false,
      },
      step_3: {
        email: "",
        phone: "",
        activity_branch: "",
        name: "",
        secondary_name: "",
        cnae: "",
        social_capital: "",
        legal_nature: "",
        business_attachment: "",
        partners_count: 1,
        postal_code: "",
        street: "",
        neighborhood: "",
        city: "",
        uf: "",
        number: "",
        complement: "",
      },
      step_4: {
        partners_count: 1,
        employees_count: 0,
        monthly_billing: "",
        plan_total: 0,
        plan: "",
      },
      plans: [
        {
          title: "MEI",
          value: 39.9,
          description: "Faturamento de até R$ 81.000,00/ano",
        },
      ],
      reasons: [
        "Desenquadramento por comunicação obrigatória do contribuinte – Participação em outra empresa",
        "Desenquadramento por comunicação obrigatória do contribuinte - Natureza jurídica vedada.",
        "Desenquadramento por comunicação obrigatória do contribuinte – Excesso de receita bruta no ano-calendário de início de atividades - até 20% do limite",
        "Desenquadramento por comunicação obrigatória do contribuinte – Excesso de receita bruta no ano-calendário de início de atividades - acima de 20% do limite",
        "Desenquadramento por comunicação obrigatória do contribuinte – Excesso de receita bruta fora do ano-calendário de início de atividades - até 20% do limite",
        "Desenquadramento por comunicação obrigatória do contribuinte – Excesso de receita bruta fora do ano-calendário de início de atividades - acima de 20% do limite",
        "Desenquadramento por comunicação obrigatória do contribuinte – Empregado com salário acima do limite.",
        "Desenquadramento por comunicação obrigatória do contribuinte – Contratação de mais de um empregado.",
        "Desenquadramento por comunicação obrigatória do contribuinte - Atividade econômica vedada.",
        "Desenquadramento por comunicação obrigatória do contribuinte - Abertura de filial",
        "Desenquadramento por opção.",
      ],
    };
  },
  async mounted() {
    await this.loadCompany();
  },
  methods: {
    async handleStep(type) {
      if (type) {
        await this.handleSubmmitMei();
        this.actual_step += 25;
      } else {
        await this.handleSubmmitMei();
        this.actual_step -= 25;
      }
    },
    async getToken() {
      try {
        const { data } = await api.get(`/cnpj-token`);
        if (!data) {
          const { dt } = await api.post(`/cnpj-token`);
          this.token = dt;
        } else this.token = data;
        await this.getCNPJData();
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar ao recuperar o token para consulta do CNPJ. Tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async getCNPJData() {
      // step_2 = cnpj
      // step_3 = cnpj data
      try {
        const { data } = await api.post(`/cnpj-token/${this.step_2.cnpj_cpf}`, {
          token: this.token.access_token,
        });
        this.step_3.email = data.email;
        this.step_3.phone = data.phone;
        this.step_3.name = data.name;
        this.step_3.secondary_name = data.secondary_name;
        this.step_3.cnae = data.cnae;
        this.step_3.social_capital = data.social_capital;
        this.step_3.legal_nature = data.legal_nature;
        this.step_3.activity_branch = data.activity_branch;
        this.step_3.postal_code = data.postal_code;
        this.step_3.street = data.street;
        this.step_3.neighborhood = data.neighborhood;
        this.step_3.city = data.city;
        this.step_3.uf = data.uf;
        this.step_3.number = data.number;
        this.step_3.complement = data.complement;
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar ao recuperar os dados do cnpj. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async loadCompany(submmit) {
      try {
        const { data } = await api.get(`/company/company-pending`);
        if (data && data.company) this.company = data.company;
        if (data.company) {
          this.company = data.company;
          if (!submmit) {
            this.step_2 = {
              reason: this.step_2.reason || this.company.reason || "",
              request: this.step_2.request || this.company.request || "",
              cnpj_cpf: this.step_2.cnpj_cpf || this.company.cnpj_cpf || "",
              financing:
                this.step_2.financing || this.company.financing || false,
              health_plan:
                this.step_2.health_plan || this.company.health_plan || false,
              contracts:
                this.step_2.contracts || this.company.contracts || false,
            };
          }
        }
      } catch (e) {
        let message = "Ocorreu um erro ao carregar as informações";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async handleSubmmitMei() {
      await this.loadCompany();
      if (!this.company) {
        try {
          const formatedData = {
            ...this.step_1,
            ...this.step_2,
            ...this.step_3,
          };
          formatedData.reason = this.reasons[this.step_2.reason];
          const { data } = await api.post("/company/migrate", formatedData);
          this.company = data;
          toast.success("Informações salvas com sucesso!");
        } catch (e) {
          let message = "Ocorreu um erro ao salvar informações";
          if (e.response.data.message) message = e.response.data.message;
          toast.error(message);
        }
      } else {
        try {
          let formatedData = this[this.getUrlStep()];
          const monthly_bills = [
            "Até R$ 25.000,00/mês",
            "De R$ 25.000,00 a R$ 99.999,99/mês",
            "Acima de R$ 100.000,00/mês",
          ];
          formatedData = {
            monthly_billing: monthly_bills[this.step_4.monthly_billing],
            simple_national: "Sim",
            ...this.step_1,
            ...this.step_2,
            ...this.step_3,
          };
          formatedData.reason = this.reasons[this.step_2.reason];
          formatedData.social_capital = formatedData.social_capital
            ? formatedData.social_capital.toString()
            : "";
          const { data } = await api.put(
            `/company/migrate-mei/${this.company.id}`,
            formatedData
          );
          this.company = data;
          toast.success("Informações salvas com sucesso!");
        } catch (e) {
          let message = "Ocorreu um erro ao salvar informações";
          if (e.response.data.message) message = e.response.data.message;
          toast.error(message);
        }
      }
    },
    async handleSubmmitPlan(plan) {
      await this.loadCompany();
      if (this.company) {
        const dt = {
          company: this.company,
          plan: {
            ...plan,
            plan_total: 39.9,
            partners_count: 1,
            employees_count: 0,
            type: 3, // migrar mei
          },
        };
        try {
          await api.post(`/company/plan/`, dt);
          toast.success("Plano contratado com sucesso!");
          this.$router.push(`/cliente/dashboard`);
        } catch (e) {
          let message = "Ocorreu um erro ao salvar informações";
          if (e.response.data.message) message = e.response.data.message;
          toast.error(message);
        }
      } else {
        toast.error(
          "Termine de preencher seu cadastro para contratar um plano."
        );
      }
    },
    getUrlStep() {
      if (this.actual_step === 0) return "step_1";
      else if (this.actual_step === 25) return "step_2";
      else if (this.actual_step === 50) return "step_3";
      else if (this.actual_step === 75) return "step_4";
    },
  },
  watch: {
    actual_step: function (e) {
      console.log(e);
    },
  },
  "step_4.employees_count": function () {
    let extra_partners = this.step_4.partners_count - 3;
    if (extra_partners <= 0) extra_partners = 0;
    if (this.step_4.plan) {
      this.step_4.plan_total =
        this.step_4.plan.value +
        extra_partners * 30 +
        this.step_4.employees_count * 40;
    }
  },
  "step_4.monthly_billing": function () {
    this.handlePlans();
    let extra_partners = this.step_4.partners_count - 3;
    if (extra_partners <= 0) extra_partners = 0;
    if (this.step_4.plan) {
      this.step_4.plan_total =
        this.step_4.plan.value +
        extra_partners * 30 +
        this.step_4.employees_count * 40;
    }
  },
  actual_step: function () {
    let extra_partners = this.step_4.partners_count - 3;
    if (extra_partners <= 0) extra_partners = 0;
    if (this.step_4.plan) {
      this.step_4.plan_total =
        this.step_4.plan.value +
        extra_partners * 30 +
        this.step_4.employees_count * 40;
    }
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}
.card {
  border-radius: 20px;
  padding: 40px;
}
.btn {
  border-radius: 40px;
}
.form-check,
.text-gray {
  color: #545454;
}
.progress {
  border-radius: 20px;
}
.bold {
  font-weight: bold;
}

.card-select {
  transition: 0.3s;
}

.card-select:hover {
  transition: 0.3s;
}
.card-select:hover {
  transition: 0.3s;
  transform: translateY(-7px);
  cursor: pointer;
}
.card-select.selected {
  transition-property: border;
  transition-duration: 0.3s;
  border: 2px #342f86 solid;
}
.form-check-input {
  width: 4em;
  height: 2em;
}
.form-check-input[data-v-654d9cb2] {
  width: 4em;
  height: 2em;
}
.badge-plan-font-start {
  font-size: 20px;
  font-weight: 500;
  display: inline-block;
  vertical-align: top;
}
.badge-plan-font-end {
  font-size: 20px;
  font-weight: 500;
}
.plan-btn,
.card-btn,
.width-50 {
  width: 60%;
}
.badge-plan,
.badge-plan {
  font-size: 35px;
  border-radius: 20px;
  color: #342f86;
  width: 20%;
}
.sign-button {
  width: 35%;
}
.bold {
  font-weight: bold;
}
.rounded {
  border-radius: 20px;
}
.desc {
  font-size: 22px;
}
</style>
