<template>
  <div>
    <div class="px-4">
      <div class="body p-4">
        <div class="d-flex justify-content-center mt-4" v-if="loaderActive">
          <div class="spinner-border text-primary spinner-border" role="status">
            <span class="visually-hidden">Aguarde...</span>
          </div>
        </div>
        <span class="text-primary h2 d-flex align-items-center">
          <button
            @click="handleGoBack()"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
          >
            <i class="fa fa-arrow-left"></i>
          </button>
          <span class="text-gray"> Editar guia</span>
        </span>
        <!-- Guia de pagamento -->
        <div class="card p-4 mt-4">
          <div class="p-2">
            <!-- <h4 class="text-gray font-weight-light">Novo banco</h4> -->
            <div class="row text-start">
              <div class="col-lg-6 col-md-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-0">Nome*</label>
                <input
                  type="text"
                  id="input-cod"
                  class="form-control"
                  aria-describedby="input-cod"
                  placeholder="Informe aqui o nome"
                  v-model="tax.name"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-type" class="form-label mb-2"
                  >Data de vencimento*</label
                >
                <input
                  type="date"
                  id="input-type"
                  class="form-control"
                  aria-describedby="input-type"
                  v-model="tax.date_due"
                />
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 text-primary mb-4">
                <label for="input-agency" class="form-label mb-">Valor*</label>
                <input
                  type="text"
                  id="input-agency"
                  class="form-control"
                  aria-describedby="input-agency"
                  placeholder="Informe aqui o valor pago"
                  v-model="tax.price"
                />
              </div>

              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <div v-if="tax.document_taxe">
                  <span class="mx-2 mt-0" v-tooltip="'Ver arquivo'">
                    <label for="input-price_paid" class="form-label mb-2"
                      >Arquivo anexado</label
                    >
                    <br />
                    <button
                      class="btn btn-primary shadow-sm"
                      @click="handleViewFile(comment.id, 'calleds')"
                    >
                      <i class="fas fa-eye"></i> Arquivo em anexo
                    </button>
                  </span>
                  <div class="col">
                    <label for="input-price_paid" class="form-label mb-2"
                      >Novo arquivo</label
                    >
                    <br />
                    <base-file
                      :value="document"
                      title="Selecione o documento"
                      accept=""
                      :mbSize="50"
                      type="secondary text-white"
                      @input-upload="document = $event"
                    />
                  </div>
                </div>
                <div v-else>
                  <label for="input-price" class="form-label mb-2"
                    >Arquivo*</label
                  >
                  <br />
                  <base-file
                    :value="document"
                    title="Selecione o documento"
                    accept=""
                    :mbSize="50"
                    type="secondary text-white"
                    @input-upload="document = $event"
                  />
                </div>
              </div>
            </div>
            <span
              class="text-center mt-4"
              v-tooltip="
                isValidModel
                  ? 'Clique para salvar'
                  : 'Preencha todas as informações obrigatórias (marcadas com *) antes de continuar'
              "
            >
              <button
                @click="submmitForm()"
                :disabled="!isValidModel"
                class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
              >
                <span class="mx-4">Salvar</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseFile from "@/components/BaseFile.vue";
import api from "@/services/api";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "taxes-edit-form",
  components: {
    BaseFile,
  },
  data() {
    return {
      month: "",
      year: "",
      tax: {
        name: "",
        date_due: "",
        price: "",
        document_taxe: "",
      },
      document: "",
      doc_preview: "",
      requiredFields: ["name", "date_due", "price"],
      loaderActive: false,
    };
  },
  async mounted() {
    await this.loadTax();
  },
  methods: {
    handleGoBack() {
      const previousUrl = localStorage.getItem("previousUrl");
      if (previousUrl) {
        this.$router.push(previousUrl);
      } else {
        this.$router.push("/");
      }
    },
    showLoader() {
      this.loaderActive = !this.loaderActive;
    },
    async loadTax() {
      try {
        this.showLoader();
        const { data } = await api.get(
          `/user-accountant/taxes/${this.$route.params.idTax}/view`
        );
        this.tax.name = data.name;
        this.tax.price = data.price;
        this.tax.document_taxe = data.document_taxe;
        if (data.date_due) this.tax.date_due = this.formatDate(data.date_due);
        this.showLoader();
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações do sócio. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    formatDate(date) {
      const newDate = new Date(date);
      const year = newDate.getUTCFullYear();
      let day = newDate.getUTCDate();
      let month = newDate.getUTCMonth() + 1;
      if (month < "10") {
        month = "0" + month;
      }
      if (day < "10") {
        day = "0" + day;
      }
      //yyyy-MM-dd
      return `${year}-${month}-${day}`;
    },
    async submmitForm() {
      try {
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const formData = new FormData();
        for (const att of Object.getOwnPropertyNames(this.tax)) {
          formData.append(att, this.tax[att]);
        }
        formData.append("document", this.document);
        formData.append("document_proof", this.document_proof);
        formData.append("month", parseInt(this.tax.date_due.split("-")[1]));
        formData.append("year", parseInt(this.tax.date_due.split("-")[0]));
        const { data } = await api.put(
          `/user-accountant/taxes/${this.$route.params.idTax}`,
          formData,
          config
        );
        this.taxes = data;
        toast.success("Folha de pagamento editada com sucesso");
        this.handleGoBack();
      } catch (e) {
        let message =
          "Ocorreu um erro ao salvar a folha de pagamento. Verifique as informações e tente novamente";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
  },
  computed: {
    isValidModel() {
      const data = this.tax;
      for (const key of this.requiredFields) {
        if (!data[key]) return false;
      }
      if (!this.document && !this.tax.document_taxe) {
        return false;
      }
      return true;
    },
  },
};
</script>
<style scoped>
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
</style>
