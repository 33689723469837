<template>
  <div class="p-4">
    <div class="card p-4 m-4 text-start">
      <input
        type="text"
        class="form-controll"
        placeholder="Procure por nome da empresa"
        v-model="company_name"
      />
      <p class="m-0 mt-3">Listados: {{ companies.length }}</p>
    </div>
    <div class="text-end p-2 m-4">
      <div>
        <button
          class="mt-3 btn text-uppercase font-weight-light btn-secondary text-white btn-shadow"
          @click="sort = !sort"
        >
          <span v-if="sort" title="Crescente">
            <i class="fa-solid fa-arrow-down-a-z"></i>
          </span>
          <span v-else title="Decrescente">
            <i class="fa-solid fa-arrow-up-a-z"></i>
          </span>
        </button>
      </div>
    </div>
    <div class="card p-2 m-4">
      <div>
        <div class="collection text-start m-0">
          <div
            @click="$router.push(`/cadastro-completo/${company.id}`)"
            class="collection-item relative pointer"
            :class="company.show ? 'opened' : ''"
            v-for="(company, index) in companies"
            :key="index"
          >
            <p class="collection-title d-flex align-items-center">
              <span class="me-auto" v-if="company.name">
                <span v-if="!company.active" v-tooltip="'Acesso bloqueado'"
                  ><i class="fas fa-lock ml-2 text-danger me-1"></i
                ></span>
                <span v-else
                  ><i
                    class="fas fa-lock-open ml-2 text-success me-1"
                    v-tooltip="'Acesso liberado'"
                  ></i
                ></span>
                {{ company.name }}</span
              >
              <span class="me-auto" v-else>
                <span v-if="!company.active"
                  ><i
                    class="fas fa-lock ml-2 text-danger me-1"
                    v-tooltip="'Acesso bloqueado'"
                  ></i
                ></span>
                <span v-else
                  ><i
                    class="fas fa-lock-open ml-2 text-success me-1"
                    v-tooltip="'Acesso liberado'"
                  ></i
                ></span>
                <span class="text-warning">Nome da empresa não informado.</span>
                (<b>Usuário: {{ `${company.company_user.user.name}` }}</b
                >)</span
              ><span>
                <!-- Acesso liberado, active = true -->
                <!-- <span
                v-tooltip="'Clique para bloquear o acesso'"
                v-if="company.active"
              >
                <button
                  class="btn btn-light shadow-sm me-2"
                  @click="handleAccountStatus(company)"
                >
                  <i class="fas fa-lock ml-2 text-danger"></i>
                </button>
              </span>

              <span
                class="col-3"
                v-tooltip="'Clique para liberar o acesso'"
                v-else
              >
                <button
                  class="btn btn-light shadow-sm me-2"
                  @click="handleAccountStatus(company)"
                >
                  <i class="fas fa-lock-open ml-2 text-success"></i>
                </button>
              </span> -->
                <span
                  v-show="company.show"
                  class="pointer px-4"
                  title="Fechar"
                  @click="closeInfo(index)"
                  ><i class="fas fa-x"></i
                ></span>
                <span v-tooltip="'Ver empresa'" class="pointer" title="Empresa">
                  <button
                    class="btn btn-light shadow-sm me-2"
                    @click="goToNextPage(`/cadastro-completo/${company.id}`)"
                  >
                    <i class="fas fa-eye text-primary px-1"></i>
                  </button>
                </span>
                <!-- <span v-tooltip="'Ver opções'" class="pointer" title="Opções">
                  <button
                    class="btn btn-light shadow-sm me-2"
                    @click="handleMenu(index)"
                  >
                    <i class="fas fa-ellipsis-vertical px-1"></i>
                  </button>
                </span> -->
              </span>
            </p>
            <!-- <div class="collection-body" v-show="company.show">
              <hr class="m-1" />
              <div class="text-start mt-1 p-3 text-primary">
                <p>
                  <i class="fa-solid fa-building"></i> CNPJ:
                  {{ company.cnpj_cpf || "Não informado" }}
                </p>
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <span class="label text-muted small">
                      Contabilidade
                      <hr class="mt-1 mb-3"
                    /></span>
                    <p @click="$router.push(`/notas-fiscais/${company.id}`)">
                      <i class="fas fa-receipt me-2"></i>Notas Fiscais
                    </p>
                    <p
                      @click="
                        $router.push(`/pagamentos-e-despesas/${company.id}`)
                      "
                    >
                      <i class="fas fa-wallet me-2"></i>Pagamentos e Despesas
                    </p>
                    <p @click="$router.push(`/extratos-bancarios/${company.id}`)">
                      <i class="fas fa-file-lines me-2"></i>Extratos
                    </p>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <span class="label text-muted small">
                      Departamento Pessoal
                      <hr class="mt-1 mb-3"
                    /></span>
                    <p class="d-flex">
                      <span class="me-auto"  @click="$router.push(`/funcionarios/${company.id}`)"
                        ><i class="fas fa-id-badge me-2"></i>Funcionários</span
                      >
                      <span class="badge bg-secondary px-4">{{
                        company.employees
                      }}</span>
                    </p>
                    <p class="d-flex">
                      <span class="me-auto"  @click="$router.push(`/socios/${company.id}`)"
                        ><i class="fas fa-user-check me-2"></i>Sócios</span
                      >
                      <span class="badge bg-secondary px-4">{{
                        company.partners
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <hr class="text-muted" />
            </div> -->
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4" v-if="loaderActive">
        <div class="spinner-border text-primary spinner-border" role="status">
          <span class="visually-hidden">Aguarde...</span>
        </div>
      </div>
      <div class="p-2 text-center" v-if="company_name && !companies.length">
        <span class="badge rounded-pill bg-danger px-3 me-2">
          Não foram encontradas empresas com esse nome
        </span>
      </div>
      <div
        class="p-2 text-center pb-4"
        v-else-if="!companies.length && !loaderActive"
      >
        <span class="badge rounded-pill bg-danger px-3 me-2">
          Sem empresas cadastradas até o momento
        </span>
      </div>
    </div>
    <div class="col-lg-12" v-if="companies.length">
      <base-pagination
        :pageCount="control.lastPage"
        :perPage="control.limit"
        :value="control.page"
        size="md"
        align="center"
        @input="handlePageInput"
      />
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import BasePagination from "@/components/BasePagination.vue";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "companies-page",
  components: {
    BasePagination,
  },
  data() {
    return {
      loaderActive: false,
      companies: [],
      mounths: [
        {
          name: "Janeiro",
          value: 1,
        },
        {
          name: "Fevereiro",
          value: 2,
        },
        {
          name: "Março",
          value: 3,
        },
        {
          name: "Abril",
          value: 4,
        },
        {
          name: "Maio",
          value: 5,
        },
        {
          name: "Junho",
          value: 6,
        },
        {
          name: "Julho",
          value: 7,
        },
        {
          name: "Agosto",
          value: 8,
        },
        {
          name: "Setembro",
          value: 9,
        },
        {
          name: "Outubro",
          value: 10,
        },
        {
          name: "Novembro",
          value: 11,
        },
        {
          name: "Dezembro",
          value: 12,
        },
      ],
      selectedField: "",
      totalCompanies: 0,
      tab: "opened",
      fields: [],
      modals: {
        invoicesModal: {
          // Notas Fiscais
          show: false,
          data: {},
        },
        paymentsModal: {
          // Folhas de pagamento
          show: false,
          data: {},
        },
        prolaboreModal: {
          // Pró-labore
          show: false,
          data: {},
        },
        taxesModal: {
          // Guias e Impostos
          show: false,
          data: {},
        },
        honorariesModal: {
          // Honorários
          show: false,
          data: {},
        },
        reportsModal: {
          // Relatórios periódicos
          show: false,
          data: {},
        },
        calledsModal: {
          // Chamados
          show: false,
          data: {},
        },
        companyModal: {
          // Imnformações
          show: false,
          data: {},
        },
      },
      company_name: "",
      sort: true,
      control: {
        page: 1,
        limit: 10,
        lastPage: 0,
        total: 0,
        empty: "",
      },
    };
  },
  async mounted() {
    this.initlist();
    const company_name = localStorage.getItem("company_name");
    if (company_name) {
      this.sort = localStorage.getItem("sort");
      this.control.page = localStorage.getItem("page");
      this.company_name = company_name;
      await this.loadCompanies(company_name, this.control.page);
    } else {
      await this.loadCompanies();
    }
  },
  methods: {
    goToNextPage(route) {
      const currentUrl = this.$route.fullPath.split("#")[0];
      localStorage.setItem("previousUrl", currentUrl);
      localStorage.setItem("sort", this.sort);
      localStorage.setItem("page", this.control.page);
      localStorage.setItem("company_name", this.company_name);
      this.$router.push(route);
    },
    async handlePageInput(page) {
      await this.loadCompanies(null, page);
    },
    updateStatus(e, company) {
      company.show_menu = e;
    },
    updateField(e) {
      this.selectedField = e;
    },
    async handleOpenModal(modal, data) {
      this.modals[modal].data = data;
      this.modals[modal].data.company = data;
      this.modals[modal].show = true;
    },
    initlist() {
      const comp = [];
      this.companies.forEach((company) => {
        comp.push({
          ...company,
          show: false,
          show_menu: false,
        });
      });
      this.companies = comp;
    },
    showLoader() {
      this.loaderActive = !this.loaderActive;
    },
    async loadCompanies(name, page) {
      try {
        this.showLoader();
        let url = `/user-accountant/companies?sort=${
          this.sort ? "asc" : "desc"
        }&limit=${this.control.limit}`;
        if (page) url += `&page=${page}`;
        if (name) url += `&name=${name}`;
        const { data: companies } = await api.get(url);
        this.control.lastPage = companies.meta.last_page;
        this.control.total = companies.meta.total;
        this.control.page = companies.meta.current_page;
        this.companies = companies.data;
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações das empresas. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      } finally {
        this.showLoader();
      }
    }, ///user-status
    async handleAccountStatus(company) {
      try {
        await api.put(`/company-status/${company.id}`);
        await this.loadCompanies();
        toast.success("Status da empresa atualizado com sucesso");
      } catch (e) {
        let message =
          "Ocorreu um erro ao salvar o novo status. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
  },
  watch: {
    sort: async function () {
      await this.loadCompanies(this.company_name, this.control.page);
    },
    company_name: async function () {
      await this.loadCompanies(this.company_name, 0);
    },
  },
};
</script>
<style scoped>
.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.opened {
  border-left: 2px solid #342f86;
}
.pointer {
  cursor: pointer;
}
.collection-title {
  margin: 0;
}
.option {
  font-size: 16px;
  color: #fbb040;
  display: block;
  line-height: 22px;
  padding: 14px 16px;
}
</style>
