<template>
  <div>
    <div class="px-4">
      <div class="body p-4">
        <span class="text-primary h2 d-flex align-items-center">
          <button
            @click="handleGoBack()"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
          >
            <i class="fa fa-arrow-left"></i>
          </button>
          <span class="text-gray"> Cadastrar guia e imposto</span>
        </span>
        <!-- Guia e Iposto -->
        <div class="card p-4 mt-4">
          <div class="p-2">
            <!-- <h4 class="text-gray font-weight-light">Novo banco</h4> -->
            <div class="row text-start">
              <div class="col-lg-6 col-md-6 col-sm-12 text-primary mb-4">
                <label for="input-cod" class="form-label mb-0">Nome*</label>
                <input
                  type="text"
                  id="input-cod"
                  class="form-control"
                  aria-describedby="input-cod"
                  placeholder="Informe aqui o nome"
                  v-model="model.name"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-type" class="form-label mb-2"
                  >Data de vencimento*</label
                >
                <input
                  type="date"
                  id="input-type"
                  class="form-control"
                  aria-describedby="input-type"
                  v-model="model.date_due"
                />
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 text-primary mb-4">
                <label for="input-price" class="form-label mb-">Valor*</label>
                <input
                  type="text"
                  id="input-price"
                  class="form-control"
                  aria-describedby="input-price"
                  placeholder="Digite o valor"
                  v-model="formatedPrice"
                  @input="formatPrice"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-price" class="form-label mb-2"
                  >Arquivo*</label
                >
                <br />
                <base-file
                  :value="document"
                  title="Selecione o documento"
                  accept=""
                  :mbSize="50"
                  type="secondary text-white"
                  @input-upload="document = $event"
                />
              </div>
            </div>
            <span
              class="text-center mt-4"
              v-tooltip="
                isValidModel
                  ? 'Clique para salvar'
                  : 'Preencha todas as informações obrigatórias (marcadas com *) antes de continuar'
              "
            >
              <button
                @click="submmitForm()"
                :disabled="!isValidModel"
                class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
              >
                <span class="mx-4">Cadastrar</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseFile from "@/components/BaseFile.vue";
import api from "@/services/api";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "taxes-form",
  components: {
    BaseFile,
  },
  data() {
    return {
      month: "",
      year: "",
      partners: [],
      model: {
        name: "",
        date_due: "",
        price: "",
      },
      document: "",
      doc_preview: "",
      requiredFields: ["name", "date_due", "price"],
    };
  },
  methods: {
    formatPrice(event) {
      const value = event.target.value.replace(/[^\d]/g, "");
      this.model.price = parseInt(value || "0", 10);
      event.target.value = this.formatedPrice;
      setTimeout(() => {
        event.target.setSelectionRange(
          event.target.value.length,
          event.target.value.length
        );
      });
    },
    updatePrice() {
      var options = { style: "currency", currency: "BRL" };
      var formatter = new Intl.NumberFormat("pt-BR", options);
      var result = formatter.format(this.model.price);
      this.amount_preview = result;
      return result;
    },
    handleGoBack() {
      const previousUrl = localStorage.getItem("previousUrl");
      if (previousUrl) {
        this.$router.push(previousUrl);
      } else {
        this.$router.push("/");
      }
    },
    async submmitForm() {
      try {
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const formData = new FormData();
        for (const att of Object.getOwnPropertyNames(this.model)) {
          if (this.model[att]) {
            if (att === "price" || att === "price_paid") {
              formData.append(att, this.model[att] / 100);
            } else {
              formData.append(att, this.model[att]);
            }
          }
        }
        formData.append("document", this.document);
        formData.append("month", parseInt(this.model.date_due.split("-")[1]));
        formData.append("year", parseInt(this.model.date_due.split("-")[0]));
        const { data } = await api.post(
          `/user-accountant/taxes/${this.$route.params.id}`,
          formData,
          config
        );
        this.taxes = data;
        toast.success("Guia e Imposto cadastrado com sucesso");
        this.$router.push(`/cadastro-completo/${this.$route.params.id}`);
      } catch (e) {
        let message =
          "Ocorreu um erro ao cadastrar a guia e imposto. Verifique as informações e tente novamente";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
  },
  computed: {
    formatedPrice() {
      if (!this.model.price) return "";
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      })
        .format(this.model.price / 100)
        .replace("R$", "")
        .trim();
    },
    isValidModel() {
      const data = this.model;
      for (const key of this.requiredFields) {
        if (!data[key]) return false;
      }
      if (!this.document) {
        return false;
      }
      return true;
    },
  },
};
</script>
<style scoped>
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
</style>
