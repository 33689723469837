<template>
  <div>
    <div class="px-4">
      <div class="body p-4">
        <div class="d-flex justify-content-center mt-4" v-if="loaderActive">
          <div class="spinner-border text-primary spinner-border" role="status">
            <span class="visually-hidden">Aguarde...</span>
          </div>
        </div>
        <span class="text-primary h2 d-flex align-items-center">
          <button
            @click="$router.push('/cliente/dashboard')"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
          >
            <i class="fa fa-arrow-left"></i>
          </button>
          <span class="text-gray"> Bancos</span>
        </span>
        <div class="text-end">
          <button
            @click="$router.push('/cliente/bancos/novo')"
            class="px-3 btn text-uppercase font-weight-light btn-secondary text-white btn-shadow mb-2 btn-sm"
          >
            <i class="fas fa-plus me-2"></i>Cadastrar banco
          </button>
        </div>
        <div class="card p-3 mt-4 text-start" v-if="!banks.length">
          Sem bancos cadastrados
        </div>
        <div class="text-start p-2" v-else>
          <div class="row m-0">
            <div
              class="col-lg-4 col-md-6 col-sm-12 item-title"
              v-for="(item, d_index) in banks"
              :key="d_index"
            >
              <div class="card mt-4 cursor-auto">
                <div class="d-flex justify-content-center">
                  <span
                    class="mb-4 rounded-circle shadow mt-n5"
                    style="width: min-content; padding: 2rem"
                  >
                    <i class="fa fa-landmark fa-5x text-secondary"></i>
                  </span>
                </div>
                <div class="row text-center">
                  <div class="col-12 h4">
                    <b>{{ item.name }}</b>
                  </div>
                  <div class="col-12">
                    <b>Conta:</b> {{ item.account_number || "Não informado" }}
                  </div>
                  <div class="col-12">
                    <b>Tipo de conta:</b>
                    {{ item.account_type || "Não informado" }}
                  </div>
                  <div class="col-12">
                    <b>Agência:</b> {{ item.routing_number || "Não informado" }}
                  </div>
                  <div class="col-12">
                    <b>Código do banco:</b>
                    {{ item.bank_code || "Não informado" }}
                  </div>
                  <span class="d-flex justify-content-center">
                    <hr class="my-4" style="width: 90%" />
                  </span>
                  <div class="row justify-content-center">
                    <span class="col-3" v-tooltip="'Editar'">
                      <button
                        class="btn btn-light shadow-sm"
                        @click="
                          $router.push(`/cliente/bancos/editar/${item.id}`)
                        "
                      >
                        <i class="fas fa-edit ml-2 text-primary"></i>
                      </button>
                    </span>
                    <span class="col-3" v-tooltip="'Remover'">
                      <button
                        class="btn btn-light shadow-sm"
                        @click="deleteBank(item.id)"
                      >
                        <i class="fas fa-trash ml-2 text-danger"></i>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  name: "banks-list",
  data() {
    return {
      banks: [],
      loaderActive: false,
    };
  },
  async mounted() {
    await this.loadBanks();
  },
  methods: {
    showLoader() {
      this.loaderActive = !this.loaderActive;
    },
    async deleteBank(id) {
      this.showLoader();
      try {
        await api.delete(`/company/banks/${id}`);
        await this.loadBanks();
        toast.success("Banco removido com sucesso!");
      } catch (e) {
        let message =
          "Ocorreu um erro ao remover o banco. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
      this.showLoader();
    },
    async loadBanks() {
      try {
        this.showLoader();
        const { data } = await api.get(`/company/banks`);
        this.banks = data;
        this.showLoader();
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações dos bancos. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
  },
};
</script>
<style scoped>
.cursor-auto {
  cursor: auto;
}
.rounded-circle {
  background-color: rgb(2, 0, 125);
}
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
</style>
