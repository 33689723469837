<template>
  <div v-if="editor">
    <button
      @click="editor.chain().focus().toggleBold().run()"
      :disabled="!editor.can().chain().focus().toggleBold().run()"
      :class="{ 'is-active': editor.isActive('bold') }"
      class="btn btn-primary mb-1 me-1"
    >
      <i class="fa-solid fa-bold"></i>
    </button>
    <button
      @click="editor.chain().focus().toggleItalic().run()"
      :disabled="!editor.can().chain().focus().toggleItalic().run()"
      :class="{ 'is-active': editor.isActive('italic') }"
      class="btn btn-primary mb-1 me-1"
    >
      <i class="fa-solid fa-italic"></i>
    </button>
    <button
      @click="editor.chain().focus().toggleStrike().run()"
      :disabled="!editor.can().chain().focus().toggleStrike().run()"
      :class="{ 'is-active': editor.isActive('strike') }"
      class="btn btn-primary mb-1 me-1"
    >
      <i class="fa-solid fa-strikethrough"></i>
    </button>
    <button
      @click="editor.chain().focus().setParagraph().run()"
      :class="{ 'is-active': editor.isActive('paragraph') }"
      class="btn btn-primary mb-1 me-1"
    >
      <i class="fa-solid fa-paragraph"></i>
    </button>
    <button
      @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
      :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
      class="btn btn-primary mb-1 me-1"
    >
      h1
    </button>
    <button
      @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
      :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
      class="btn btn-primary mb-1 me-1"
    >
      h2
    </button>
    <button
      @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
      :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
      class="btn btn-primary mb-1 me-1"
    >
      h3
    </button>
    <button
      @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
      :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
      class="btn btn-primary mb-1 me-1"
    >
      h4
    </button>
    <button
      @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
      :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
      class="btn btn-primary mb-1 me-1"
    >
      h5
    </button>
    <button
      @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
      :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
      class="btn btn-primary mb-1 me-1"
    >
      h6
    </button>
    <button
      @click="editor.chain().focus().toggleBulletList().run()"
      :class="{ 'is-active': editor.isActive('bulletList') }"
      class="btn btn-primary mb-1 me-1"
    >
      <i class="fa-solid fa-list"></i>
    </button>
    <button
      @click="editor.chain().focus().toggleOrderedList().run()"
      :class="{ 'is-active': editor.isActive('orderedList') }"
      class="btn btn-primary mb-1 me-1"
    >
      <i class="fa-solid fa-list-ol"></i>
    </button>

    <button
      @click="editor.chain().focus().sinkListItem('listItem').run()"
      :disabled="!editor.can().sinkListItem('listItem')"
      class="btn btn-primary mb-1 me-1"
    >
      <i class="fa-solid fa-indent"></i>
    </button>
    <button
      @click="editor.chain().focus().liftListItem('listItem').run()"
      :disabled="!editor.can().liftListItem('listItem')"
      class="btn btn-primary mb-1 me-1"
    >
      <i class="fa-solid fa-outdent"></i>
    </button>
    <button
      @click="editor.chain().focus().toggleBlockquote().run()"
      :class="{ 'is-active': editor.isActive('blockquote') }"
      class="btn btn-primary mb-1 me-1"
    >
      <i class="fa-solid fa-quote-left"></i>
    </button>
    <button
      @click="editor.chain().focus().setHorizontalRule().run()"
      class="btn btn-primary mb-1 me-1"
    >
      <i class="fa-solid fa-minus"></i>
    </button>
    <button
      @click="editor.chain().focus().toggleHighlight().run()"
      :class="{ 'is-active': editor.isActive('highlight') }"
      class="btn btn-primary mb-1 me-1"
    >
      <i class="fa-solid fa-highlighter"></i>
    </button>
    <button
      @click="editor.chain().focus().setTextAlign('left').run()"
      :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
      class="btn btn-primary mb-1 me-1"
    >
      <i class="fa-solid fa-align-left"></i>
    </button>
    <button
      @click="editor.chain().focus().setTextAlign('center').run()"
      :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
      class="btn btn-primary mb-1 me-1"
    >
      <i class="fa-solid fa-align-center"></i>
    </button>
    <button
      @click="editor.chain().focus().setTextAlign('right').run()"
      :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
      class="btn btn-primary mb-1 me-1"
    >
      <i class="fa-solid fa-align-right"></i>
    </button>
    <button
      @click="editor.chain().focus().setTextAlign('justify').run()"
      :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }"
      class="btn btn-primary mb-1 me-1"
    >
      <i class="fa-solid fa-align-justify"></i>
    </button>

    <button
      @click="setLink"
      :class="{ 'is-active': editor.isActive('link') }"
      class="btn btn-primary mb-1 me-1"
    >
      <i class="fa-solid fa-link"></i>
    </button>
    <button
      @click="editor.chain().focus().unsetLink().run()"
      :disabled="!editor.isActive('link')"
      class="btn btn-primary mb-1 me-1"
    >
      <i class="fa-solid fa-link-slash"></i>
    </button>

    <button
      @click="editor.chain().focus().toggleSubscript().run()"
      :class="{ 'is-active': editor.isActive('subscript') }"
      class="btn btn-primary mb-1 me-1"
    >
      <i class="fa-solid fa-subscript"></i>
    </button>
    <button
      @click="editor.chain().focus().toggleSuperscript().run()"
      :class="{ 'is-active': editor.isActive('superscript') }"
      class="btn btn-primary mb-1 me-1"
    >
      <i class="fa-solid fa-superscript"></i>
    </button>
    <button
      @click="editor.chain().focus().toggleUnderline().run()"
      :class="{ 'is-active': editor.isActive('underline') }"
      class="btn btn-primary mb-1 me-1"
    >
      <i class="fa-solid fa-underline"></i>
    </button>

    <button
      @click="editor.chain().focus().undo().run()"
      class="btn btn-primary mb-1 me-1"
      :disabled="!editor.can().chain().focus().undo().run()"
    >
      <i class="fa-solid fa-rotate-left"></i>
    </button>
    <button
      @click="editor.chain().focus().redo().run()"
      class="btn btn-primary mb-1 me-1"
      :disabled="!editor.can().chain().focus().redo().run()"
    >
      <i class="fa-solid fa-rotate-right"></i>
    </button>
    <input
      type="color"
      @input="editor.chain().focus().setColor($event.target.value).run()"
      :value="editor.getAttributes('textStyle').color"
      class="btn btn-primary mb-1 me-1 color"
    />
  </div>
  <editor-content :editor="editor" />
</template>

<script>
import StarterKit from "@tiptap/starter-kit";
import { Editor, EditorContent } from "@tiptap/vue-3";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";

export default {
  name: "tip-tap",
  components: {
    EditorContent,
  },
  props: {
    modelValue: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      editor: null,
    };
  },
  emits: ["update:modelValue"],
  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Highlight.configure({ multicolor: true }),
        Link.configure({
          openOnClick: false,
        }),
        Subscript,
        Superscript,
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
        Underline,
        TextStyle,
        Color,
      ],
      content: this.modelValue,
      onUpdate: () => {
        // HTML
        this.$emit('update:modelValue', this.editor.getHTML())

        // JSON
        // this.$emit('update:modelValue', this.editor.getJSON())
      },
    });
  },
  methods: {
    setLink() {
      const previousUrl = this.editor.getAttributes("link").href;
      const url = window.prompt("URL", previousUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === "") {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();

        return;
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },
  },

  beforeUnmount() {
    this.editor.destroy();
  },
  watch: {
    modelValue(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
  },
};
</script>
<style>
.color {
  padding: 0.5rem;
}
/* Basic editor styles */
.tiptap.ProseMirror {
  display: block;
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 0.8;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 15px;
  min-height: 200px;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
